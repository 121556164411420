import React, { useReducer, useState, useEffect } from 'react'
import Input from '../../CommonComponent/Input'
import Button from '../../CommonComponent/Button'
import { apiService } from '../../Services/ApiServices';
import InlineError from "../../CommonComponent/inlineError";
import Spinner from 'react-bootstrap/Spinner';
import { FcNext, FcPrevious } from "react-icons/fc";
import BtnLoader from '../../CommonComponent/BtnLoader';

function BankAccountDetails(props) {
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  let prevPage = localStorage.getItem('signup_2');
  let currentPage = localStorage.getItem('signup_3');
  let ifsc_code = localStorage.getItem('ifsc_code') ? localStorage.getItem('ifsc_code') : '';
  let account_number = localStorage.getItem('account_number') ? localStorage.getItem('account_number') : '';
  let re_enter_account_number = localStorage.getItem('re_enter_account_number') ? localStorage.getItem('re_enter_account_number') : '';
  let account_type = localStorage.getItem('account_type') ? localStorage.getItem('account_type') : '';
  let account_name = localStorage.getItem('account_name') ? localStorage.getItem('account_name') : '';
  let bank_name = localStorage.getItem('bank_name') ? localStorage.getItem('bank_name') : '';

  let branch_address = localStorage.getItem('branch_address') ? localStorage.getItem('branch_address') : '';
  let bankDetailsSet = null;
  if (bank_name) {
    bankDetailsSet = {
      bank_name: bank_name, branch_address: branch_address
    }
  }
  let isVarifiedSet = ifsc_code ? true : false;
  const [ifscCode, setIFSCCode] = useState(ifsc_code);
  const [isVarified, setVarifiedValue] = useState(isVarifiedSet);
  const [bankDetails, setBankDetails] = useState(bankDetailsSet);
  const [accountNumber, setaccountNumber] = useState(account_number);
  const [accountName, setaccountName] = useState(account_name);

  const [reEnterAccountNumber, setreEnterAccountNumber] = useState(re_enter_account_number);
  const [apiResponse, setApiResponse] = useState(bankDetailsSet);
  const [inputLoader, setInputLoader] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(account_type);
  const [isAccountMatch, setAccountMatch] = useState(false);
  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      errorMsg: ''
    }
  );

  useEffect(() => {
    if (accountNumber && reEnterAccountNumber) {
      if ((accountNumber === reEnterAccountNumber) && (reEnterAccountNumber != '') && (accountNumber != '')) {
        setError({ reEnterAccountNumber: '' });
        setAccountMatch(true)
      } else {
        if (reEnterAccountNumber && accountNumber)
          setError({ reEnterAccountNumber: 'Account number does not match' });
        else
          setError({ reEnterAccountNumber: '' });
        setAccountMatch(false)
      }
    }

  }, [accountNumber, reEnterAccountNumber])


  useEffect(() => {
    if (!ifsc_code) {
      const fetchData = async () => {
        setInputLoader(false)
        try {
          if (ifscCode) {
            let req = { ifsc: ifscCode };
            const token = localStorage.getItem('token');
            const response = await apiService.checkIfscCode(req, token).then(async result => {
              if (result.data?.status_code === 1) {
                setVarifiedValue(true)
                setInputLoader(false)
                localStorage.setItem('bank_name', result.data.response_data.bank_name);
                localStorage.setItem('branch_address', result.data.response_data.branch_address);
                localStorage.setItem('isVarified', isVarified);
                setApiResponse(result.data.response_data);
                setError({ errorMsg: '' });

              } else {
                setError({ errorMsg: result?.data?.error.message.error });
                setInputLoader(false)

              }
            })
          } else {
            setInputLoader(false)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const timeoutId = setTimeout(() => {
        if (ifscCode.trim() !== '') {
          fetchData();
        }
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [ifscCode]);

  const handleInputChange = (e) => {
    localStorage.removeItem("ifsc_code");
    if (e.target.value)
      setInputLoader(true)
    else
      setInputLoader(false)

    const updatedIfscCode = e.target.value.toUpperCase();
    setIFSCCode(updatedIfscCode);
    setError({ errorMsg: '' })
  };

  const handleAccountNoChange = (e) => {
    setaccountNumber(e.target.value);

    let accountNumber = e.target.value;
    if ((accountNumber == undefined) || (accountNumber == '')) {
      setError({ accountNumber: 'Account number is required' });
    }
    else if ((accountNumber.length < 9) || (accountNumber.length > 18)) {
      setError({ accountNumber: 'Account number should be in between 9 to 18 digits' });
    } else {
      setError({ accountNumber: '' });
    }

  };

  const handleReAccountNoChange = (e) => {
    setreEnterAccountNumber(e.target.value);
  };

  const handleAccountName = (e) => {
    let textInput = e.target.value;
    if (/^[A-Za-z\s]*$/.test(textInput) || textInput === '') {
      if ((textInput.length > 0) && (textInput.length < 2)) {
        setaccountName(e.target.value);
        setError({ accountName: 'Account Name must contain 2 and 60 characters' });
      } else {
        setaccountName(e.target.value);
        if (textInput.length == 0)
          setError({ accountName: 'Account Name is required' });
        else {
          setaccountName(e.target.value);
          setError({ accountName: '' });
        }
      }

    }
  };

  const handleValidate = () => {
    let validate = true;
    setError({});
    if ((accountNumber == undefined) || (reEnterAccountNumber == '')) {
      setError({ accountNumber: 'Account Number is require' });
      validate = false
    }


    if (accountNumber) {
      if (accountNumber.length < 9) {
        setError({ accountNumber: 'Account Number must contain at least 9 characters' });
        validate = false
      } else if (accountNumber.length > 18) {
        setError({ accountNumber: 'Account Number must contain only 18 characters' });
        validate = false
      } else {

      }
    }

    if ((reEnterAccountNumber == undefined) || (reEnterAccountNumber == '')) {
      setError({ reEnterAccountNumber: 'Re enter Account Number is require' });
      validate = false
    }

    if (reEnterAccountNumber) {
      if (reEnterAccountNumber.length < 9) {
        setError({ reEnterAccountNumber: 'Re enter Account Number must contain at least 9 characters' });
        validate = false
      } else if (reEnterAccountNumber.length > 18) {
        setError({ reEnterAccountNumber: 'Re enter Account Number must contain only 18 characters' });
        validate = false
      } else {

      }
    }
    if (accountNumber !== reEnterAccountNumber) {
      {
        setError({ reEnterAccountNumber: 'Re Account Number does not match to Account number' });
        validate = false
      }
    }

    if ((ifscCode == undefined) || (ifscCode == '')) {
      setError({ ifscCode: 'ifscCode is require' });
      validate = false
    }

    if ((selectedAccount == undefined) || (selectedAccount == '')) {
      setError({ selectedAccount: 'selectedAccount is require' });
      validate = false
    }
    return validate;
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const addBankAccount = debounce(async () => {
    try {
      setIsContinueDisabled(true); // Disable the button immediately on click
      setBtnLoader(true);
      if (handleValidate()) {
        let req = {
          ifsc_code: ifscCode,
          account_number: accountNumber,
          re_enter_account_number: reEnterAccountNumber,
          account_type: selectedAccount,
          holder_name: accountName
        };
        const token = localStorage.getItem('token');
        const result = await apiService.addBankAccount(req, token);
        if (result.data?.status_code === 1) {
          localStorage.setItem('ifsc_code', ifscCode);
          localStorage.setItem('account_number', accountNumber);
          localStorage.setItem('re_enter_account_number', reEnterAccountNumber);
          localStorage.setItem('account_type', selectedAccount);
          localStorage.setItem('account_name', accountName);
          localStorage.setItem('signup_3', true);
          localStorage.setItem('signup_4', false);
          const jsonData = JSON.stringify(result?.data?.response_data.categories);
          localStorage.setItem('categories_list', jsonData);
          if (localStorage.getItem('token') !== '') {
            localStorage.setItem('isLoggedIn', 'true');
          }
          nextPage();
        } else {
          console.log("API response error");
        }
      }
    } catch (e) {
      console.error('Error adding bank account:', e);
    } finally {
      setBtnLoader(false);
      setIsContinueDisabled(false); // Re-enable the button after processing
    }
  }, 1000); // Debounce delay of 1 second

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  const prevpageUser = () => {
    props.prevpageCommon()

  }

  const nextPage = () => {
    props.nextCommonPage()
  }

  function checkSession() {
    localStorage.clear();
    window.location.href = "/login"; // Adjust the URL based on your login page route
  }
  
  // Listen for the page load to verify session status
  window.addEventListener("load", () => {
    checkSession();
  });
  
  // Listen for browser back button actions
  window.addEventListener("popstate", () => {
    checkSession();
  });
  
  // Handle page reload or navigation changes
  window.addEventListener("beforeunload", () => {
    checkSession();
  });

  return (

    <div className='signup_process'>
      <h1 className='complete_profile_txt avenir_heavy'>Complete Your Profile Journey</h1>
      <img src='assets/process3.svg' className='process_1' />

      <div className='signup_modal '>
        <div className='profile__step_header'>
        <span className='_icon mb-2' ></span>
      
          {/* {
            (prevPage == "true") ?
              <> <span className='_icon mb-2' onClick={prevpageUser}><FcPrevious /></span></>
              :
              <span className='_icon mb-2' ></span>
          } */}
          <span className='signup_stps_txt mb-2 avenir_black'>BANK ACCOUNT DETAILS</span>
          {/* {
            (currentPage == "true") ?
              <> <span className='_icon mb-2' onClick={nextPage}><FcNext /></span></>
              :
              <span className='_icon mb-2' ></span>
          } */}
            <span className='_icon mb-2' ></span>
        </div>
        <p className='acc_details avenir_medium'>Account Details Required For Seamless Transaction Credits</p>

        <div className={isVarified == true ? "" : "ifsc_margin_bottom"}>
          <Input
            placeholder={"Enter IFSC Code"}
            className="ifsc_txt"
            value={ifscCode}
            maxLength={11}
            minLength={11}
            onChange={(e) => handleInputChange(e)}
          />

          {inputLoader ? <Spinner animation="border" variant="secondary" className='bnk_inpt_loader' /> : ""}
          <InlineError errorMessage={error.errorMsg} />
        </div>


        {(isVarified === true) ?
          <div className='bank_detail'>
            <div className='bank_img'>
              <img src='assets/bank-img.jpg' alt='bankimg' />
            </div>
            <div>
              <p className='bank_name avenir_medium'>{apiResponse?.bank_name}</p>
              <p className='bank_branch avenir_medium'>{apiResponse?.branch_address}</p>

            </div>
          </div>
          : ""

        }
        {
          (isVarified == true) ?
            <>
              <div className='mb-4 mt-4 '>
                <Input
                  type="text"
                  placeholder={"Account Holder Name"}
                  className="ifsc_txt mb-3"
                  value={accountName}
                  maxLength={60}
                  minLength={2}
                  pattern="[A-Za-z\s]*"
                  onChange={(e) => handleAccountName(e)}
                />
                <InlineError errorMessage={error.accountName} />
              </div>

              <div className='mb-4 mt-4 '>
                <Input
                  type="password"
                  placeholder={"Account Number"}
                  className="ifsc_txt mb-3"
                  value={accountNumber}
                  maxLength={18}
                  minLength={9}
                  onChange={(e) => handleAccountNoChange(e)}
                  id="password-input"
                />
                <InlineError errorMessage={error.accountNumber} />
              </div>

              <div className={error.reEnterAccountNumber ? 'mb-0' : 'mb-4 '}>
                <Input
                  id="dummy"

                  placeholder={"Re enter Account Number"}
                  className={error.reEnterAccountNumber ? 'mb-0 ifsc_txt' : "ifsc_txt mb-4"}
                  value={reEnterAccountNumber}
                  maxLength={18}
                  minLength={9}
                  onPaste={handlePaste}
                  onCopy={handleCopy}
                  onChange={(e) => handleReAccountNoChange(e)}
                />
              </div>
              <div className='left_align_error mt-2 mb-4'>
                <InlineError errorMessage={error.reEnterAccountNumber} />
              </div>
              <span className='acc_type_txt'>Account Type</span>
              <div className='acc_checkbox'>
                <div className="form-group">
                  <input type="checkbox" id="savings" name="savings" value="saving"
                    checked={selectedAccount === 'saving'}
                    onChange={handleAccountChange}
                  />
                  <label htmlFor="savings">Savings</label>
                </div>
                <div className="form-group">
                  <input type="checkbox" id="current" name="current"
                    value="current"
                    checked={selectedAccount === 'current'}
                    onChange={handleAccountChange}
                  />
                  <label htmlFor="current">Current</label>
                </div>
              </div>
            </>
            :
            <></>
        }
        <div className='text-center'>
          <div className='text-center'>
            {((isVarified === true) && (accountName.length >= 2) && (accountNumber.length >= 9) && (reEnterAccountNumber.length >= 9) && (selectedAccount !== '') && (isAccountMatch === true)) ?
              <Button
                name={btnLoader ? <BtnLoader /> : "Continue"}
                className="mb-3 mt-2"
                onClick={addBankAccount}
                disabled={isContinueDisabled}
              />
              :
              <Button
                name={'Continue'}
                className="mb-3 mt-2"
                disabled
              />
            }
          </div>

        </div>
        <p className='usr_details'>Your details are safe with us. We encrypt all account information to keep it private and secure.</p>
      </div>
    </div>

  )
}

export default BankAccountDetails
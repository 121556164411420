import React from 'react';
import { GoSearch } from "react-icons/go";

function SearchBox(props) {
  return (
    <div className='search_box'> <span><GoSearch /></span>
      <input type="text"
        autoComplete='off'
        placeholder={props.placeholderText}
        maxLength={56}
        name='search'
        value={props?.value}
        onChange={(e) => props?.handleSearchChange(e)}
      /></div>
  )
}

export default SearchBox
import React from 'react'

function Button(props) {
    return (
        <>
            <button
                className={`btn  ${props.className} common_button avenir_heavy ${props.counter ? 'position-relative':''}` }
                onClick={(e) => props.onClick(e)}
                disabled={props.disabled}
            >
                {props.name}{props.counter > 0 ? <span className='_btn_counter'>{props?.counter}</span> :''}
            </button>
        </>
    )
}

export default Button
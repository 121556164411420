import React from 'react';

function Input(props) {
    return (
        <>
            <input
                className={`${props.className} common_input_field avenir_medium`}
                type={props.type}
                name={props.name}
                maxLength={props.maxLength}
                onChange={(e) => props.onChange(e)}
                placeholder={props.placeholder}
                value={props.value}
                readOnly={props.readOnly}
                id={props.id}
                autocomplete="new-password"
                onkeydown={props.onkeydown}
            />
        </>
    )
}

export default Input
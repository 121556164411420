/**
 * @About
 * This file contains Inline error.
 */

import React from "react";

// This components used for show inline errors
const InlineError = (props) => (
    <>
        <div className="text-danger mt-0 _error__msz">{props.errorMessage}</div>
    </>
)

export default InlineError



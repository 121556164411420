import React, { useReducer, useState, useEffect } from 'react';
import Button from '../../CommonComponent/Button';
import Modal from 'react-bootstrap/Modal';
import Input from "../../CommonComponent/Input";
import { apiService } from '../../Services/ApiServices';
import InlineError from '../../CommonComponent/inlineError';
import { IoCloseCircle } from "react-icons/io5";
import BtnLoader from '../../CommonComponent/BtnLoader';
import Spinner from 'react-bootstrap/Spinner';

function BankAccountDetails(props) {
  const [btnLoader, setBtnLoader] = useState(false);
  let ifsc_code = '';
  const [ifscCode, setIFSCCode] = useState('');
  const [isVarified, setVarifiedValue] = useState('');
  const [accountNumber, setaccountNumber] = useState('');
  const [accountName, setaccountName] = useState('');
  const [reEnterAccountNumber, setreEnterAccountNumber] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [inputLoader, setInputLoader] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState('');
  const [isAccountMatch, setAccountMatch] = useState(false);
  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      errorMsg: ''
    }
  );

  useEffect(() => {
    if (accountNumber && reEnterAccountNumber) {
      if ((accountNumber === reEnterAccountNumber) && (reEnterAccountNumber != '') && (accountNumber != '')) {
        setError({ reEnterAccountNumber: '' });
        setAccountMatch(true)
      } else {
        if (reEnterAccountNumber && accountNumber)
          setError({ reEnterAccountNumber: 'Account number does not match' });
        else
          setError({ reEnterAccountNumber: '' });
        setAccountMatch(false)
      }
    }

  }, [accountNumber, reEnterAccountNumber])

  useEffect(() => {
    if (!ifsc_code) {
      const fetchData = async () => {
        setInputLoader(false)
        try {
          if (ifscCode) {
            let req = { ifsc: ifscCode };
            const token = localStorage.getItem('token');
            const response = await apiService.checkIfscCode(req, token).then(async result => {
              if (result.data?.status_code === 1) {
                setVarifiedValue(true)
                setInputLoader(false)
                setApiResponse(result.data.response_data);
                setError({ errorMsg: '' });

              } else {
                setError({ errorMsg: result?.data?.error.message.error });
                setInputLoader(false)

              }
            })
          } else {
            setInputLoader(false)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const timeoutId = setTimeout(() => {
        if (ifscCode.trim() !== '') {
          fetchData();
        }
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [ifscCode]);

  const handleInputChange = (e) => {
    if (e.target.value)
      setInputLoader(true)
    else
      setInputLoader(false)

    const updatedIfscCode = e.target.value.toUpperCase();
    setIFSCCode(updatedIfscCode);
    setError({ errorMsg: '' })
  };

  const handleAccountNoChange = (e) => {
    setaccountNumber(e.target.value);

    let accountNumber = e.target.value;
    if ((accountNumber == undefined) || (accountNumber == '')) {
      setError({ accountNumber: 'Account number is required' });
    }
    else if ((accountNumber.length < 9) || (accountNumber.length > 18)) {
      setError({ accountNumber: 'Account number should be between 9 and 18 digits' });
    } else {
      setError({ accountNumber: '' });
    }
  };

  const handleReAccountNoChange = (e) => {
    setreEnterAccountNumber(e.target.value);
  };

  const handleAccountName = (e) => {
    let textInput = e.target.value;
    if (/^[A-Za-z\s]*$/.test(textInput) || textInput === '') {
      if ((textInput.length > 0) && (textInput.length < 2)) {
        setaccountName(e.target.value);
        setError({ accountName: 'Account Name must contain 2 and 60 characters' });
      } else {
        setaccountName(e.target.value);
        if (textInput.length == 0)
          setError({ accountName: 'Account Name is required' });
        else {
          setaccountName(e.target.value);
          setError({ accountName: '' });
        }
      }

    }
  };

  const handleValidate = () => {
    let validate = true;
    setError({});
    if ((accountNumber == undefined) || (reEnterAccountNumber == '')) {
      setError({ accountNumber: 'Account Number is require' });
      validate = false
    }
    if (accountNumber) {
      if (accountNumber.length < 9) {
        setError({ accountNumber: 'Account Number must contain at least 9 characters' });
        validate = false
      } else if (accountNumber.length > 18) {
        setError({ accountNumber: 'Account Number must contain only 18 characters' });
        validate = false
      } else {

      }
    }
    if ((reEnterAccountNumber == undefined) || (reEnterAccountNumber == '')) {
      setError({ reEnterAccountNumber: 'Re enter Account Number is require' });
      validate = false
    }

    if (reEnterAccountNumber) {
      if (reEnterAccountNumber.length < 9) {
        setError({ reEnterAccountNumber: 'Re enter Account Number must contain at least 9 characters' });
        validate = false
      } else if (reEnterAccountNumber.length > 18) {
        setError({ reEnterAccountNumber: 'Re enter Account Number must contain only 18 characters' });
        validate = false
      } else {

      }
    }
    if (accountNumber !== reEnterAccountNumber) {
      {
        setError({ reEnterAccountNumber: 'Re Account Number does not match to Account number' });
        validate = false
      }
    }
    if ((ifscCode == undefined) || (ifscCode == '')) {
      setError({ ifscCode: 'ifscCode is require' });
      validate = false
    }
    if ((selectedAccount == undefined) || (selectedAccount == '')) {
      setError({ selectedAccount: 'selectedAccount is require' });
      validate = false
    }
    return validate;
  }

  const addBankAccount = () => {
    try {
      if (handleValidate() == true) {
        let req = {
          ifsc_code: ifscCode,
          account_number: accountNumber,
          re_enter_account_number: reEnterAccountNumber,
          account_type: selectedAccount,
          holder_name: accountName
        }
        const token = localStorage.getItem('token');
        apiService.addBankAccount(req, token).then(result => {
          if (result.data?.status_code == 1) {
            props.onlyClose();
          } else {
          }
        })
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  const handlePaste = (event) => {
    event.preventDefault();
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handleAccountChange = (event) => {
    setSelectedAccount(event.target.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      className='cstm_modal_edit_product'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className='close_icon _add_product' onClick={props.onlyClose}><span><IoCloseCircle /></span></div>
        <span className='schedl_action avenir_black'>
          BANK ACCOUNT DETAILS
        </span>
        <p className='acc_details avenir_medium'>Account Details Required For Seamless Transaction Credits</p>

        <div className={isVarified == true ? "" : "ifsc_margin_bottom"}>
          <Input
            placeholder={"Enter IFSC Code"}
            className="ifsc_txt"
            value={ifscCode}
            maxLength={11}
            minLength={11}
            onChange={(e) => handleInputChange(e)}
          />

          {inputLoader ? <Spinner animation="border" variant="secondary" className='bnk_inpt_loader' /> : ""}
          <InlineError errorMessage={error.errorMsg} />
        </div>


        {(isVarified === true) ?
          <div className='bank_detail'>
            <div className='bank_img'>
              <img src='assets/bank-img.jpg' alt='bankimg' />
            </div>
            <div>
              <p className='bank_name avenir_medium'>{apiResponse?.bank_name}</p>
              <p className='bank_branch avenir_medium'>{apiResponse?.branch_address}</p>

            </div>
          </div>
          : ""

        }

        {
          (isVarified == true) ?
            <>

              <div className='mb-4 mt-4 '>
                <Input
                  type="text"
                  placeholder={"Account Holder Name"}
                  className="ifsc_txt mb-3"
                  value={accountName}
                  maxLength={60}
                  minLength={2}
                  pattern="[A-Za-z\s]*"
                  onChange={(e) => handleAccountName(e)}
                />
                <InlineError errorMessage={error.accountName} />
              </div>

              <div className='mb-4 mt-4 '>
                <Input
                  type="password"
                  placeholder={"Account Number"}
                  className="ifsc_txt mb-3"
                  value={accountNumber}
                  maxLength={18}
                  minLength={9}
                  onChange={(e) => handleAccountNoChange(e)}
                  id="password-input"
                />
                <InlineError errorMessage={error.accountNumber} />
              </div>

              <div className={error.reEnterAccountNumber ? 'mb-0' : 'mb-4 '}>
                <Input
                  id="reEnterAccountNumber"
                  placeholder={"Re enter Account Number"}
                  className={error.reEnterAccountNumber ? 'mb-0 ifsc_txt' : "ifsc_txt mb-4"}
                  value={reEnterAccountNumber}
                  maxLength={18}
                  minLength={9}
                  onPaste={handlePaste}
                  onCopy={handleCopy}
                  onChange={(e) => handleReAccountNoChange(e)}
                />
              </div>
              <div className='left_align_error mt-2 mb-4'>
                <InlineError errorMessage={error.reEnterAccountNumber} />
              </div>
              <span className='acc_type_txt'>Account Type</span>
              <div className='acc_checkbox'>
                <div className="form-group">
                  <input type="checkbox" id="savings" name="savings" value="saving"
                    checked={selectedAccount === 'saving'}
                    onChange={handleAccountChange}
                  />
                  <label htmlFor="savings">Savings</label>
                </div>
                <div className="form-group">
                  <input type="checkbox" id="current" name="current"
                    value="current"
                    checked={selectedAccount === 'current'}
                    onChange={handleAccountChange}
                  />
                  <label htmlFor="current">Current</label>
                </div>
              </div>
            </>
            :
            <></>
        }
        <p className='usr_details'>Your details are safe with us. We encrypt all account information to keep it private and secure.</p>
        <div className='text-center'>
          <Button
            className="mb-3 mt-2"
            name={btnLoader ? <BtnLoader /> : "Submit"}
            onClick={addBankAccount}
            disabled={!((isVarified == true) && (accountName.length >= 2) && (accountNumber.length >= 9) && (reEnterAccountNumber.length >= 9) && (selectedAccount !== '') && (isAccountMatch == true))}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BankAccountDetails;

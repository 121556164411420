
import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    visible: false,
  },
  reducers: {
    toggleNotification: (state) => {
      state.visible = !state.visible;
    },
    hideNotification: (state) => {
      state.visible = false;
    },
  },
});

export const { toggleNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;

import React from 'react';
import { ProductImage } from '../../CommonComponent/ImageConstants';
import Carousel from "react-multi-carousel";

const responsive = {
  bigDesktop: {
    breakpoint: {
      max: 2000,
      min: 1601
    },
    items: 7,
    slidesToSlide: 2,
    partialVisibilityGutter: 40
  },
  desktop: {
    breakpoint: {
      max: 1600,
      min: 1551
    },
    items: 5,
    slidesToSlide: 2,
    partialVisibilityGutter: 40
  },
  midDesktop: {
    breakpoint: {
      max: 1550,
      min: 1061
    },
    items: 4,
    slidesToSlide: 2,
    partialVisibilityGutter: 40
  },
  smallDesktop: {
    breakpoint: {
      max: 1060,
      min: 992
    },
    items: 3,
    slidesToSlide: 2,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: {
      max: 550,
      min: 270
    },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 30
  },
  smallMobile: {
    breakpoint: {
      max: 370,
      min: 0
    },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: {
      max: 830,
      min: 551
    },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: {
      max: 991,
      min: 831
    },
    items: 5,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  }
};

function TopProduct(props) {
  const repeat = (arr, n) => [].concat(...Array(n).fill(arr));

  const repeatProducts = (targetLength) => {
    let repeatedProducts = [];
    if (props?.productLists?.length < 6) {
      repeatedProducts = repeat(props?.productLists, targetLength).slice(0, targetLength)
    }
    else {
      repeatedProducts = props?.productLists
    }

    return repeatedProducts;
  };

  const hasProducts = Array.isArray(props.productLists) && props.productLists.length > 0;
  const targetLength = 6;
  const repeatedProductLists = repeatProducts(targetLength);

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      className='top_product_carousel product_container'
    >
      {hasProducts ? (
        repeatedProductLists?.map((item, index) => {
          return (
            <div className="product_box" key={index}>
              <div className="_image">
                <img
                  src={item?.image == null ? ProductImage : item?.image}
                  alt="product"
                />
              </div>
              <div className="_text">
                <h6 className="mb-1">{item?.name}</h6>
                <p className="_price">
                  <del className="me-3">₹{item?.original_mrp}</del>
                  <span>₹{item?.auction_start_price}</span>
                </p>
              </div>
            </div>
          )
        })
      ) : (
        <div>No products available</div>
      )}
    </Carousel>
  )
}

export default TopProduct

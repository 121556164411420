import React, { useReducer, useState, useEffect } from 'react';
import Button from '../../CommonComponent/Button';
import Input from '../../CommonComponent/Input';
import Lottie from 'react-lottie';
import Animation from '../../Utils/Animation.json';
import { apiService } from '../../Services/ApiServices';
import InlineError from "../../CommonComponent/inlineError";
import { validateEmail, validatePassword } from '../../CommonComponent/Validation';
import { connect } from "react-redux";
import VerifyMobileNoModal from './VerifyMobileNoModal';
import { useNavigate } from 'react-router';
import BtnLoader from '../../CommonComponent/BtnLoader';
import { Link } from 'react-router-dom';
import Logo from '../../CommonComponent/Logo';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { RiCloseCircleLine } from 'react-icons/ri';

function Signup(props) {
    const [countryCode, setCountryCode] = useState(91);
    const [mobile, setMobile] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [isAllAdded, setisAllAdded] = useState(false);
    const [isFirstName, setisFirstName] = useState(false);
    const [isLastName, setisLastName] = useState(false);
    const [isEmail, setisEmail] = useState(false);
    const [isPhone, setisPhone] = useState(false);
    const [isPassword, setisPassword] = useState(false);
    let checked = false;
    let isVarified = localStorage.getItem('isVarified') ? localStorage.getItem('isVarified') : false;
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation,
        renderer: 'svg'
    }
    const navigate = useNavigate()
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            firstName: '', lastName: '', email: '', phone: '', countryCode: '',
            password: '', loading: false, isResponse: false, isClearable: true, isLoading: true
        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            firstName: '', lastName: '', email: '', phone: '', countryCode: '', toc:'',
            password: '', errorMsg: ''
        }
    );
    const handleChangeEmail = e => {
        setisEmail(false);
        setBtnLoader(false);
        setUserInput({ ...userInput, [e.target.name]: e.target.value });

        let email = e.target.value;
        if ((email == undefined) || (email == '')) {
            setError({ email: 'Email is required' });
        }

        else if (!validateEmail(email).status) {
            setError({ email: 'Please enter valid email' });
        }
        else {
            setisEmail(true);
            setError({ email: '' })
        }
    };

    const handleChange = e => {
        setBtnLoader(false);
        setisPassword(false);
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
        let password = e.target.value;
        if ((password == undefined) || (password == '')) {
            setError({ password: 'Password is required' });
        }
        else if (!validatePassword(password).status) {
            setError({ password: 'Password must be at least 6 characters long and contain at least one uppercase letter, one special character, and one number' });
        } else {
            setisPassword(true);
            setError({ password: '' })
        }

    };

    const handleValidate = () => {
        setBtnLoader(false);
        let validate = true;
        let { firstName, lastName, email, phone, countryCode, password } = userInput;
        setError({});
        if ((firstName == undefined) || (firstName.length == 0)) {
            setError({ firstName: 'First Name is required' });
            validate = false
        }
        if (firstName) {
            if (firstName.length < 2) {
                setError({ firstName: 'First Name must contain at least 2 characters' });
                validate = false
            } else if (firstName.length > 30) {
                setError({ firstName: 'First Name must contain only 30 characters' });
                validate = false
            } else {

            }
        }

        if ((lastName == undefined) || (lastName == '')) {
            setError({ lastName: 'Last Name is required' });
            validate = false
        }

        if (lastName) {
            if (lastName.length < 2) {
                setError({ lastName: 'lastName must contain at least 2 characters' });
                validate = false
            } else if (lastName.length > 30) {
                setError({ lastName: 'lastName must contain only 30 characters' });
                validate = false
            } else {

            }
        }

        console.log("mobile__________",mobile)

        if ((mobile == undefined) || (mobile == '')) {
            setError({ phone: 'Phone number is required' });
            validate = false
        }
        else
         if ((mobile.length < 10) && (mobile.length > 0)) {
            setError({ phone: 'Please enter 10-digit phone number' });
        } else {
            if (mobile.length >= 10) {
                setError({ phone: '' });
                setisPhone(true);
            } else {
                setError({ phone: 'Phone number is required' });
            }
        }
        if ((password == undefined) || (password == '')) {
            setError({ password: 'Please enter password' });
            validate = false
        }
        if (!validatePassword(password).status) {
            setError({ password: 'Password must be at least 6 characters long and contain at least one uppercase letter, one special character, and one number' });
            validate = false
        }

        if ((!isAgreed)) {
            setError({ toc: 'Please agree to the Terms & Conditions and Privacy Policy to proceed' });
            validate = false
        }

        if (mobile && (isVarified != 'true')) {
            setError({ phone: 'Please verify phone number' });
            validate = false
        }
        return validate;
    }

    const handleFirstStep = () => {
        setIsButtonDisabled(!isButtonDisabled);
        setBtnLoader(true);
        try {
            if (handleValidate() == true) {
                setBtnLoader(true);
                let req = {
                    first_name: userInput.firstName,
                    last_name: userInput.lastName,
                    email_address: userInput.email,
                    password: userInput.password,
                    phone_number: mobile,
                    country_code: "+" + countryCode,
                    is_agree_condition: isAgreed
                }
                setUserInput({ isLoader: true })
                apiService.registerFirstStep(req).then(result => {
                    if (result.data?.status_code == 1) {
                        setBtnLoader(false);
                        let { firstName, lastName, email, password } = userInput;
                        localStorage.setItem('first_name', firstName);
                        localStorage.setItem('last_name', lastName);
                        // localStorage.setItem('email_address', email);
                        localStorage.setItem('token', result.data?.response_data.access_token);
                        localStorage.setItem('signup_1', false);
                        navigate("/signup-process")
                    } else {
                        setError({ email: result?.data?.error.message.error });
                        setBtnLoader(false);
                    }
                })
            }
        } catch (e) {
            console.error('e', e);
        }
    }

    const [isAgreed, setIsAgreed] = useState(false);

    const handleAgreeChange = (e) => {
        checked = e.target.checked;
        setError({ toc: '' });
        setIsAgreed(checked);
    };

    const handleValidatePhone = () => {
        let validate = true;
        setError({});
        if ((mobile == undefined) || (mobile == '')) {
            setError({ mobile: 'Phone is require' });
            validate = false
        }
        return validate;
    }

    const checkPhoneVerify = () => {
        try {
            if (handleValidatePhone() == true) {
                setError({ phone: '' });
                let req = {
                    phone_number: parseInt(mobile),
                    country_code: "+" + countryCode,
                    is_for_forgot_password: 0
                }
                setUserInput({ isLoader: true })
                apiService.sendOtp(req).then(result => {
                    if (result.data?.status_code == 0) {
                        setError({ errorMsg: result?.data?.error.message.error });
                        setModalShow(false)
                    }
                    else if (result.data?.response_data["number_verified"] === false) {
                        setError({ errorMsg: '' });
                        let { firstName, lastName, email, password } = userInput;
                        localStorage.setItem('first_name', firstName);
                        localStorage.setItem('last_name', lastName);
                        localStorage.setItem('email_address', email);
                        localStorage.setItem('phone_number', mobile);
                        localStorage.setItem('country_code', countryCode);
                        setModalShow(true);
                    } else if (result.data?.response_data["number_verified"] === true) {
                        setError({ errorMsg: '' });
                        let { firstName, lastName, email } = userInput;
                        localStorage.setItem('first_name', firstName);
                        localStorage.setItem('last_name', lastName);
                        localStorage.setItem('email_address', email);
                        localStorage.setItem('phone_number', mobile);
                        localStorage.setItem('country_code', countryCode);
                        localStorage.setItem('isVarified', true);
                    } else {
                        setError({ errorMsg: result?.data?.error.message.error });
                    }
                })
            }
        } catch (e) {
            console.error('e', e);
        }
    }

    const handleInput = (event) => {
        const sanitizedValue = event.target.value.replace(/\D/g, ''); // Remove non-digits
        let formattedMobileNumber = sanitizedValue;
        localStorage.removeItem("phone_number");
        // Check if the first digit is 0
        if (formattedMobileNumber.startsWith('0')) {
            // If the first digit is 0, ensure there are 11 digits
            formattedMobileNumber = formattedMobileNumber.slice(0, 11);
        } else {
            // If the first digit is not 0, ensure there are 10 digits
            formattedMobileNumber = formattedMobileNumber.slice(0, 10);
        }
        setisPhone(false);
        setMobile(formattedMobileNumber);
        localStorage.removeItem("isVarified");
      
        setError({ phone: '' })
        if ((formattedMobileNumber.length < 10) && (formattedMobileNumber.length > 0)) {
            setError({ phone: 'Please enter 10 digits phone number' });
        } else {
            if (formattedMobileNumber.length >= 10) {
                setError({ phone: '' });
                setisPhone(true);
            } else {
                setError({ phone: 'Phone number is required' });
            }
        }
    };

    const handleInputChange = e => {
        const textInput = e.target.value;
        setisFirstName(false);
        // Use a regular expression to check if the input contains only letters
        if (/^[a-zA-Z]+$/.test(textInput) || textInput === '') {
            if ((textInput.length > 0) && (textInput.length < 2)) {
                setUserInput({ ...userInput, [e.target.name]: e.target.value });
                setError({ firstName: 'First Name must be between 2 and 30 characters' });
                setisFirstName(false);
            } else {
                setUserInput({ ...userInput, [e.target.name]: e.target.value });
                if (textInput.length == 0) {
                    setisFirstName(false)
                    setError({ ...error, [e.target.name]: 'First Name is required' });
                }
                else {
                    setisFirstName(true);
                    setError({ ...error, [e.target.name]: '' });
                }
                setBtnLoader(false);
            }

        } else {
        }
    };

    const handleInputLastName = e => {
        const textInput = e.target.value;
        setisLastName(false);
        // Use a regular expression to check if the input contains only letters
        if (/^[a-zA-Z]+$/.test(textInput) || textInput === '') {
            //   setInputValue(textInput);
            if ((textInput.length > 0) && (textInput.length < 2)) {
                setUserInput({ ...userInput, [e.target.name]: e.target.value });
                setError({ lastName: 'Last Name must be between 2 and 30 characters' });
            } else {
                setUserInput({ ...userInput, [e.target.name]: e.target.value });
                if (textInput.length == 0)
                    setError({ ...error, [e.target.name]: 'Last Name is required' });
                else {
                    setisLastName(true);
                    setError({ ...error, [e.target.name]: '' });
                }
                setBtnLoader(false);
            }

        }
    };

    const checkFormCompletion = () => {
        if (isFirstName && isLastName && isPhone && isPassword) {
            setisAllAdded(true);
        } else {
            setisAllAdded(false);
        }
    };

    useEffect(() => {
        checkFormCompletion()
    }, [isFirstName, isLastName, isPhone, isPassword])

    // Clear localStorage on page reload
    useEffect(() => {
        window.addEventListener('beforeunload', clearLocalStorageOnReload);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('beforeunload', clearLocalStorageOnReload);
        }
    }, []);

    const clearLocalStorageOnReload = () => {
        localStorage.clear();
    };
    
    return (
        <>
            <div className="auth_main_section">
                <div className='left_side'>
                    <div className='logo_section'>
                        <Link to="/"> <Logo /></Link>
                    </div>
                    <div>
                        <div>
                            <Lottie
                                options={defaultOptions}
                                height={160}
                                width={160}
                            />
                        </div>
                        <img src='assets/sign-in-girl.svg' alt='women' className='sign_in_girl' />
                    </div>

                </div>
                <div className='right_side'>
                    <div className='right_section_width'>
                        <div className='mob_signup_logo'>
                            <img src='assets/sinupMobLogo.svg' />
                        </div>
                        <h1 className='sign_in_heading avenir_black'>Register</h1>
                        <p className='welcome_txt'> <b>Welcome,</b><span className='avenir_medium'> let’s get started</span></p>
                        <div className='wrap_inpt name_wrap'>
                            <Input
                                type="text"
                                placeholder="First Name"
                                name="firstName"
                                className="name_inpt"
                                maxLength={30}
                                minLength={2}
                                value={userInput.firstName}
                                onChange={(e) => handleInputChange(e)}
                            />
                            <Input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                className="name_inpt"
                                maxLength={30}
                                minLength={2}
                                value={userInput.lastName}
                                onChange={handleInputLastName} />

                        </div>
                        <div className='inline_error_wrp_name'>
                           <div className='name_width'>
                            {/* {error.firstName &&<span className={`_validtion_icon ${error.firstName ? 'text-danger' : ''}`}><RiCloseCircleLine/></span> } */}
                            {/* <span className='_validtion_icon text-success'><FaRegCircleCheck/></span> */}
                                <InlineError errorMessage={error.firstName} />
                            </div>
                            <div className="name_width">
                                <InlineError errorMessage={error.lastName} />
                            </div>
                        </div>
                        {/* <div className='wrap_inpt position_relative'>
                            <Input
                                type="text"
                                name="email"
                                placeholder="Email ID"
                                maxLength={50}
                                minLength={10}
                                value={userInput.email}
                                onChange={handleChangeEmail}
                            />

                        </div> */}
                        {/* <div className='left_align_error'>
                            <InlineError errorMessage={error.email} />
                        </div> */}
                        <div>
                            <div className="wrap_option wrap_inpt position_relative">
                                <span className='county_code'>🇮🇳 +91</span>
                                <Input
                                    type="text"
                                    // pattern="[0-9]*"
                                    className="number_inpt"
                                    name="phone"
                                    // maxLength={10}
                                    placeholder="Phone Number"
                                    value={mobile}
                                    // step="any"
                                    onChange={handleInput}
                                />
                                {
                                    (isVarified == 'true') ?
                                        <span className='phn_verify'><img src='assets/check.svg' /></span>
                                        :
                                        ((mobile.length == 10) || (mobile.length == 11)) ?
                                            <span className='verify_phn_no cursor_pointer' onClick={checkPhoneVerify}  >Verify</span>
                                            :
                                            <span className='verify_phn_no cursor_pointer' ></span>
                                }
                                <>
                                </>
                            </div>
                            <div className='left_align_error'>
                              <InlineError errorMessage={error.phone} />
                           </div>

                            {modalShow && <VerifyMobileNoModal show={modalShow} onHide={() => setModalShow(false)}
                                backdrop="static"
                                keyboard={false} />}
                        </div>
                        <div className='left_align_error'>
                            <InlineError errorMessage={error.errorMsg} />
                        </div>
                        <div className='wrap_inpt position_relative'>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                name="password"
                                minLength={6}
                                value={userInput.password}
                                onChange={handleChange} />

                            <img onClick={handleTogglePassword} className='eye_icon cursor_pointer' src={showPassword ? 'assets/eye.svg' : 'assets/close-eye.svg'} />

                        </div>
                        <div className='left_align_error'>
                            <InlineError errorMessage={error.password} />
                        </div>
                        <div className='wrp_chekbox mt-4'>
                            <Input
                                type="checkbox"
                                className="checkbox"
                                checked={isAgreed}
                                onChange={handleAgreeChange}

                            />
                            <span>I agree with BidsBoxx’s <Link to="/terms-conditions">Terms & Conditions</Link> and <Link to="/privacy-policy" >Privacy Policy</Link></span>
                        </div>
                        <div className='left_align_error'>
                            <InlineError errorMessage={error.toc} />
                        </div>
                        <div className='wrap_btn'>
                            {/* {
                               ((isVarified == 'true') && (isAgreed == true) && (isAllAdded == true)) ? */}
                                    <Button name={btnLoader ? <BtnLoader /> : "Continue"} onClick={handleFirstStep} />
                            {/* //         :
                            //         <Button className="_disabled" name={"Continuecd"} onClick={handleValidate}  /> 
                            
                            // } */}
                        </div>
                        <p className='dont_have_acc avenir_medium'>Already have an account? <a href='/login' className='register'> <span>Sign in</span></a> </p>
                    </div>
                </div>
            </div>


        </>
    )
}

// export default Signup

function mapStateToProps(state) {
    const { props } = state;
    return {
        props
    }
}

export default connect(mapStateToProps)(Signup);


import React from 'react';
import qrImage from '../../src/assets/landing/qrImage.png';
import qrImageBlack from '../../src/assets/landing/qrImage_black.png';
import arrowicon from '../../src/assets/landing/arrow_icon.svg';

function QrSection(props) {
  return (
    <div className='_qr_sec'>
      <h3>{props.heading}</h3>
      {props?.color == "black" ?
        <img src={qrImageBlack} alt="image" className='img-fluid' />
        :
        <img src={qrImage} alt="image" className='img-fluid' />
      }
      {props.pagename == "ExperienceLiveAuction" &&
        <img src={arrowicon} alt="image" className='img-fluid arrow_indicator' />
      }
    </div>
  )
}

export default QrSection
/**
 * @About
 * This file mange environment of the project
 */

//dev
export const devSetting = {
    api: {
        url: "https://devapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://devsocket.bidsboxx.com/",
        web: "https://dev.bidsboxx.com/"
    }
}

//stag
export const stagSetting = {
    api: {
        url: "https://stagapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://stagsocket.bidsboxx.com/",
        web: "https://stag.bidsboxx.com/"
    }
}

//prod
export const prodSetting = {
    api: {
        url: "https://api.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://devapi.bidsboxx.com/",
        web: "https://devsocket.bidsboxx.com/"
    }
}

//local
export const localhostSetting = {
    api: {
        url: "https://devapi.bidsboxx.com/",
        AUTH: "Basic cm9vdC1iaWRzYm94eDpyb290MTIz",
        SOCKET_PATH: "https://devsocket.bidsboxx.com/",
        web: "https://dev.bidsboxx.com/"
    },
}



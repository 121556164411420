import React, { useEffect, useRef, useState } from 'react';
import * as Chime from 'amazon-chime-sdk-js';
import { attendeeAdd } from '../Services/ApiServices';
import { useParams } from 'react-router-dom';

function VideoStream(props) {
  const params = useParams();
  const [getMeetingSession, setMeetingSession] = useState(null);
  const startMeetingSession = async () => {
    if (params?.id) {
      try {
        var meetingSession;
        const response = await attendeeAdd({ auction_id: params.id });
        if (response?.data?.status_code !== 1) {
          throw new Error('Failed to retrieve attendee data');
        }
        const attendee = response.data.response_data.attendee.Attendee;
        const meeting = response.data.response_data.meeting;
        const logger = new Chime.ConsoleLogger();
        const deviceController = new Chime.DefaultDeviceController(logger);
        const configuration = new Chime.MeetingSessionConfiguration(meeting, attendee);
        meetingSession = new Chime.DefaultMeetingSession(configuration, logger, deviceController);
        const video_element = document.getElementById("parallelVideoId_123");
        const audio_element = document.getElementById("meeting-audio");
        const observer = {
          audioVideoDidStart: () => {
            // meetingSession.audioVideo.bindAudioElement(audio_element);
            // meetingSession.audioVideo.startLocalVideoTile();
          },
          videoTileDidUpdate: tileState => {
            meetingSession.audioVideo.bindVideoElement(tileState?.tileId, video_element);
          },
          audioVideoDidStop: sessionStatus => {
            if (sessionStatus && sessionStatus.statusCode() !== Chime.SessionStoppedErrorCode.NetworkFailure) {
              meetingSession.audioVideo.stop();
              meetingSession.audioVideo.start();
            } else {
              console.error("Session stopped due to network failure.");
            }
          },
          audioVideoDidStartConnecting: reconnecting => {
            if (reconnecting) {
              console.log('Attempting to reconnect');
            }
          },
        }
        meetingSession.audioVideo.addObserver(observer);
        try {
          const audioOutputDevices = await meetingSession?.audioVideo?.listAudioOutputDevices();
          await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0]?.deviceId);
          await meetingSession.audioVideo.bindAudioElement(audio_element);
          meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400);
          meetingSession.audioVideo.realtimeUnmuteLocalAudio();
          try {
            setMeetingSession(meetingSession);
            meetingSession.audioVideo.start();
          } catch (error) {
            console.error('Error occurred while starting meeting session:', error);
          }
        } catch (e) {
          console.error('e___________________________', e);
        }

      } catch (error) {
        console.error('Error occurred while starting meeting session:', error);
      }
    }
  };

  useEffect(() => {
    startMeetingSession();
  }, [params?.id]);

  return (
    <div style={{ display: "flex", justifyContent: "center", height: '100vh' }}>
      <div className='_video_section' style={{ width: '100%', margin: '0 auto' }}>
        <div className='_video'
          style={{
            height: '100vh',
            backgroundColor: '#000',
            width: '100%',
            position: 'relative'
          }}>
          <video id="parallelVideoId_123" style={{ width: '100%', height: '100%', objectFit: 'cover' }}></video>
          <audio id="meeting-audio" style={{ "display": "none" }}></audio>
        </div>
      </div>

    </div>
  );
}

export default VideoStream;

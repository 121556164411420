import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    login: "",
};

export const authApiReducer = createSlice({
    name: "home",
    initialState,
    reducers: {
        authDataAction: (state, action) => ({
            ...state,
            login: action.payload.login,

        })
    },
});


export const { authDataAction } = authApiReducer.actions;

export default authApiReducer.reducer;
import React, { useState, useEffect } from 'react'
import Button from '../../CommonComponent/Button'
import { apiService } from '../../Services/ApiServices';
import { useNavigate } from 'react-router';
import BaseLoader from '../../CommonComponent/BaseLoader';
import { FcPrevious } from "react-icons/fc";
import BtnLoader from '../../CommonComponent/BtnLoader';

function CategoriesList(props) {
    let prevPage = localStorage.getItem('signup_3');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [btnLoader, setBtnLoader] = useState(false);
    const [baseLoader, setBaseLoader] = useState(false);
    let catgCount = localStorage.getItem('categories') ? (JSON.parse(localStorage.getItem('categories')))?.length : 0;
    const [categoriesCount, setCategoriesCount] = useState(catgCount)
    let setSelectCatg = localStorage.getItem('categories') ? JSON.parse(localStorage.getItem('categories')) : [];
    const [multiSelectCategories, setMultiSelectCategories] = useState(setSelectCatg);
    const [inputValue, setInputValue] = useState([]);

    const selectCategories = (id) => {
        const isSelected = multiSelectCategories.includes(id);
        if (isSelected) {
            // Deselect div
            setCategoriesCount(categoriesCount - 1)
            setMultiSelectCategories((prevSelected) =>
                prevSelected.filter((selected) => selected !== id)
            );
        } else {
            setCategoriesCount(categoriesCount + 1)
            setMultiSelectCategories((prevSelected) => [...prevSelected, id]);
        }
    };

    useEffect(() => {
        // Save selected categories to localStorage whenever it changes
        if (JSON.parse(localStorage.getItem('categories_list')))
            localStorage.setItem('categories', JSON.stringify(multiSelectCategories));
    }, [multiSelectCategories]);

    const categoryListData = () => {
        setBaseLoader(true)
        const storedData = localStorage.getItem('categories_list');
        // Parse the JSON string back into an array
        const parsedData = JSON.parse(storedData);
        setInputValue(parsedData);
        setBaseLoader(false)
    }

    useEffect(() => {
        categoryListData()
    }, [])

    const prevpageUser = () => {
        props.prevpageCommon()
    }

    const categorySelectSubmit = () => {
        setBtnLoader(true);
        let req = {
            // "categories": multiSelectCategories
        }
        apiService.addCategory(req, token).then(res => {
            console.log("res_______________________________________",res)
            if (res?.data?.status_code == 1) {
                setInputValue(res?.data?.response_data);
                localStorage.removeItem("last_name");
                localStorage.removeItem("phone_number");
                localStorage.removeItem("country_code");
                localStorage.removeItem("email_address");
                localStorage.removeItem("first_name");

                localStorage.removeItem("isVarified");
                localStorage.removeItem("is_username_varified");
                localStorage.removeItem("store_phone_number");
                localStorage.removeItem("signup_2");
                localStorage.removeItem("bank_name");
                localStorage.removeItem("ifsc_code");
                localStorage.removeItem("state");
                localStorage.removeItem("isAgreed");
                localStorage.removeItem("username");
                localStorage.removeItem("gst_number");
                localStorage.removeItem("categories");
                localStorage.removeItem("signup_1");
                localStorage.removeItem("account_name");
                localStorage.removeItem("account_type");
                localStorage.removeItem("store_image");
                localStorage.removeItem("signup_4");
                localStorage.removeItem("city");
                localStorage.removeItem("pincode");
                localStorage.removeItem("newUrl");
                localStorage.removeItem("account_number");

                localStorage.removeItem("address_line");
                localStorage.removeItem("re_enter_account_number");
                localStorage.removeItem("phone_number");
                localStorage.removeItem("have_gst");
                localStorage.removeItem("signup_3");
                localStorage.removeItem("branch_address");
                // localStorage.removeItem("store_name");
                localStorage.removeItem("is_gst_verified");
                localStorage.setItem('socketload', "false");
                localStorage.setItem('isLoggedIn', 'true');

                setBtnLoader(false);
                navigate("/dashboard")
            } else {
                setBtnLoader(false);
                navigate("/dashboard")
            }
        })
    }
    const prevpage = () => {
        props.openBankTab(true)
    }

    function checkSession() {
        localStorage.clear();
        window.location.href = "/login"; // Adjust the URL based on your login page route
      }
      
      // Listen for the page load to verify session status
      window.addEventListener("load", () => {
        checkSession();
      });
      
      // Listen for browser back button actions
      window.addEventListener("popstate", () => {
        checkSession();
      });
      
      // Handle page reload or navigation changes
      window.addEventListener("beforeunload", () => {
        checkSession();
      });
    

    return (

        <div>
            <div className='signup_process'>
                <h1 className='complete_profile_txt avenir_heavy'>Complete Your Profile Journey</h1>
                <img src='assets/process4.svg' className='process_1' />
                <div className='signup_modal text-center'>
                    <div className='profile__step_header'>
                    <span className='_icon mb-2' ></span>
                        {/* {
                            (prevPage == "true") ?
                                <> <span className='_icon mb-2' onClick={prevpageUser}><FcPrevious /></span></>
                                :
                                <span className='_icon mb-2' ></span>
                        } */}
                        <span className='signup_stps_txt mb-2 avenir_black'>CURRENTLY SUPPORTED CATEGORIES</span>
                        <span className='_icon mb-2'></span>
                    </div>
                    {baseLoader ? <BaseLoader /> : (
                        <>
                            <div className='categories_wrap'>
                                {inputValue.map((item) => (
                                    <div
                                        key={item.id}
                                        className='categories_img'
                                        id={item.id}
                                        onClick={() => selectCategories(item.id)}
                                    >
                                        <img id={item.id} src={item.image} alt={item.name} />
                                        <p className='categories_name avenir_heavy' id={item.id}>
                                            {item.name}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            <Button name={btnLoader ? <BtnLoader /> : "Submit"} onClick={categorySelectSubmit} />
                        </>
                    )}

                </div>
            </div >
        </div>

    )
}

export default CategoriesList




import React, { useState, useReducer, useEffect } from 'react';
import Button from '../../CommonComponent/Button'
import { apiService } from '../../Services/ApiServices';
import Modal from 'react-bootstrap/Modal';
import AddProductListModal from './AddProductListModal';
import { IoCloseCircle } from "react-icons/io5";
import { CiCircleChevDown, CiCircleChevUp } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ProductListSkeleton } from '../../CommonComponent/Skeleton';

function AddProductModal(props) {
    const token = localStorage.getItem('token');
    let productListOld = props.productList;
    const [inputLoader, setInputLoader] = useState(false);
    const [modalShow, setModalShow] = useState(false)
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            list: {},
            isResponse: '',
            search: '',
            searchType: '',
            inactive: '',
            bP: '',
            totalCount: '',
            activePage: 1,
            dir: '',
            sortValue: '',
            type: ''

        }
    );

    const getAuction = () => {
        setUserInput({ isLoader: true })
        try {
            if (props.auctionId) {
                let auction_id = props.auctionId;
                apiService.getAuction(auction_id, token).then(res => {
                    if (res?.data?.status_code == 1) {
                        let data = res?.data?.response_data.auction_details;
                        setUserInput({ list: data?.product_list, totalCount: res?.data.response_data.count, isLoader: false, is_product: data.is_product, is_resume: data?.is_resume });
                        setInputLoader(true)
                    }
                    else {
                        setUserInput({ loading: false });
                    }
                })
            }
        } catch (e) {
            console.error('e', e);
        }

    }

    const onDelete = (e) => {
        try {
            let req = { "auction_id": props.auctionId, "is_linked": false, "product_id": e.id };

            apiService.editProduct(req, token).then(res => {
                if (res?.data?.status_code == 1) {
                    getAuction();
                } else {
                    // props.onHide(false)
                }
            })
        } catch (e) {
            console.error('e', e);
        }
    }

    useEffect(() => {
        getAuction()
    }, [props?.auctionId])


    const reorderProduct = (productId, action) => {
        try {
            let req = { "auction_id": props.auctionId, "action": action, "product_id": productId };
            apiService.reorderProduct(req, token).then(res => {
                if (res?.data?.status_code == 1) {
                    setUserInput({ list: res?.data?.response_data?.product_list });
                } else {
                    // props.onHide(false);
                }
            })
        } catch (e) {
            console.error('e', e);
        }

    }

    const submit = (item) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure you want to delete this product?',
            buttons: [
                {
                    label: 'YES',
                    onClick: () => onDelete(item)
                },
                {
                    label: 'NO',
                }
            ]
        });
    };

    const propsAction = () => {
        props.onHide();
        props.auctionList();
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                className='cstm_modal_product'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='close_icon' onClick={propsAction}><span><IoCloseCircle /></span></div>
                    <div className='add_prduct_list avenir_black' >
                        {
                            (userInput?.is_product && props?.is_live == false) ?
                                <Button name={'+Product'} className="add_prdct_btn" onClick={() => setModalShow(true)} />
                                :
                                <Button name={'+Product'} className="add_prdct_btn" disabled="true" />
                        }

                        Product List
                    </div>
                    {modalShow && <AddProductListModal
                        show={modalShow} onHide={() => setModalShow(false)}
                        backdrop="static"
                        keyboard={false}
                        auctionId={props.auctionId}
                        addProducts={getAuction}
                    />}
                    {userInput.isLoader == true ?
                        <ProductListSkeleton />
                        :
                        <>
                            {userInput?.list.length > 0 ?
                                <div className='list_wrp avenir_heavy'>
                                    <span>S.No</span>
                                    <span>Product Name</span>
                                    <span></span>
                                    <span></span>
                                </div>
                                : <h5 className='my-5 mx-4 text-center' style={{ color: "#00000042" }}>You haven't added any product yet!</h5>
                            }
                            <div className='list_data'>
                                {
                                    userInput.isLoader == true ?
                                        <ProductListSkeleton />
                                        : <>
                                            {userInput.list.map((item, i) =>


                                                <div className='list_item avenir_medium'>
                                                    <ul >
                                                        <li className=' ' >{(i + 1)}</li>
                                                        <li className=' '>{item.name}</li>
                                                        <li>
                                                            <span className="nxt_btn me-2" onClick={() => reorderProduct(item.id, 1)}><CiCircleChevUp /></span>
                                                            <span className="nxt_btn" onClick={() => reorderProduct(item.id, 0)}><CiCircleChevDown /></span>
                                                        </li>
                                                        <li className={`${(props?.is_live == true) || (item.sold_out_status == 2) ? 'disabled' : ''}`}>
                                                            <img src="https://d2rqcufsarnep3.cloudfront.net/temp/images/bin.svg" onClick={() => `${(props?.is_live == true) || (item.sold_out_status == 2) ? "" : submit(item)}`} />

                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </>
                                }

                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddProductModal;
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function ReturnsPolicy() {
    const navigate = useNavigate();
    const goBack = () => { navigate(-1); };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='privacy_policy_parent'>
                <div className='header'>
                        <span onClick={goBack}><MdArrowBackIos /></span>
                        <Link to="/">  <img src='assets/header-logo.svg' style={{ height: "46px" }} /></Link>
                </div>
                <div className='privacy_policy'>
                <h4>
                                        Return Policy</h4>

                                <p>Customers can return products within 48 hours (2 days) of receiving them.
                                </p>
                                <p>For certain products, customers need to raise a return request. The seller will review the request to determine if the product can be replaced or needs to be returned. The product will only be replaced if the seller approves the return request.
                                </p>
                                <p>Customized products are not eligible for refunds or returns. Replacements are available only in specific cases.
                                </p>

                                <p><b>General Returns Policy</b></p>
                                <p>
                                        Applicable products are returnable within the specified return window if they are received in a condition that is physically damaged, has missing parts or accessories, is defective, or differs from their description on the product detail page on Bidsboxx.
                                        For issues with Smartphones, Tablets, Laptops, Televisions, Air Conditioners,
                                        Refrigerators, Washing Machines, and Microwaves, we may arrange a technician visit to your location. This visit is scheduled through Bidsboxx Home Services. A resolution will be provided based on the technician's evaluation, subject to Home Services Terms and
                                        Conditions.
                                </p>
                                <p><b>Returns will be processed only if:</b></p>


                                <p>▪ It is determined that the product was not damaged while in your possession.
                                </p>
                                <p>▪ The product matches what was shipped to you.
                                </p>
                                <p>▪ The product is returned in its original condition with the brand’s/manufacturer's box, MRP tag intact, user manual, warranty card, and all accessories included.
                                </p>
                                <p>
                                        If you are returning an electronic device that stores personal information, please ensure all personal information is removed before returning. Bidsboxx is not liable for any misuse of such information.
                                </p>
                                <p>
                                        Products may not be eligible for return in certain cases, such as buyer's remorse (e.g., incorrect model or color ordered). Products marked as "non-returnable" on the product detail page cannot be returned, except in the unlikely event of a damaged, defective, or incorrect item being delivered. In such cases, we will provide a full refund or replacement. We may contact you to assess the damage or defect before issuing a refund/replacement and reserve the right to pick up the product for this purpose.
                                </p>
                                <p>
                                        No additional information is required to return an eligible order unless otherwise noted in the category-specific policy. Products may be eligible for replacement only if the same seller has the exact same item in stock. If a replacement request is placed and the seller does not have the exact product in stock, a refund will be issued.
                                </p>
                                <p>
                                        <b>Customized Products:</b> Customized products are not eligible for refunds or returns. Replacements are available only in specific cases.
                                </p>
                                <p>
                                        <b>Return Requests:</b> For certain products, customers need to raise a return request. The seller will review the request to determine if the product can be replaced or needs to be returned. The product will only be replaced if the seller approves the return request.
                                </p>
                                <p>
                                        <b>Misuse of Return Policy:</b> If customers are found to misuse the return policy by excessively returning, canceling, or not accepting orders, Bidsboxx reserves the right to warn, suspend, block, or terminate such customer accounts as necessary.
                                </p>
                                <p>
                                        <b>International Customers:</b> Products purchased by international customers are not eligible for returns but are eligible for refunds. International customers must contact customer
                                        service within 5 business days from the delivery or estimated delivery date to claim refunds.
                                </p>
                                <p>
                                        <b>Note:</b> If you receive a non-returnable product in a damaged or defective condition, contact us.
                                </p>
                                <p>
                                        For more detailed information, please visit our specific category return policies.
                                </p>

                                <hr />
                                <h4>Refund</h4>
                                <p>You may return the product for a refund according to the return terms and conditions. The refund will be credited back to your wallet within 3 to 7 business days.
                                </p>
                </div>
        </div>
)

    }
export default ReturnsPolicy
import { apiService } from '../Services/ApiServices';
import { useNavigate } from 'react-router-dom';  // Corrected import path

export const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate("/");
        return;
      }
      const result = await apiService.logout(token);
      localStorage.clear();
      sessionStorage.clear();

      navigate("/");
    } catch (e) {
      console.error('Logout error', e);
      navigate("/");  // Fallback navigation in case of error
    }
  };

  return logout;
};

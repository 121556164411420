import React from 'react';

function StatsPage(props) {
  return (
    <>
    {props.statsData.map((item)=>{
        return(
        <div className='stats_card'>
            <div className='_center'>
              <div className='top'>
                  <div className='_icon'><img src={item.icon} alt="icon" /></div>
                  <div className='_count'>{item.count}</div>
              </div>
              <p>{item.text}<br/><span className='orange_text'>{item.text1}</span></p>
              {item.text1 &&<span>{item.text1}Jeand</span>}
            </div>
        </div>
        )
    })}
    </>
  )
}

export default StatsPage
import React, { useState, useEffect, useReducer } from 'react'
import Logo from '../../CommonComponent/Logo';
import "../../StyleComponent/AuthStyle.css"
import Button from '../../CommonComponent/Button'
import Lottie from 'react-lottie';
import Animation from '../../Utils/Animation.json'
import OtpInput from "react18-input-otp";
import { useNavigate } from 'react-router';
import { apiService } from '../../Services/ApiServices';
import moment from 'moment';
import InlineError from '../../CommonComponent/inlineError';


function VerifyOtp() {
  const [otp, setOtp] = useState('');
  const [seconds, setSeconds] = useState(60);
  const navigate = useNavigate()
  let phone = localStorage.getItem('phone_number');
  let country_code = "+" + localStorage.getItem('country_code')
  const [error, setError] = useState('');
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation,
    renderer: 'svg'
  }

  const [errors, setErrors] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            errorMsg: ''
        }
    );
    
  const handleChange = (enteredOtp) => {
    setErrors({ errorMsg: '' });
    setOtp(enteredOtp);
  };

  const resetPasswordScreen = () => {
    localStorage.removeItem("phone_number");
    localStorage.removeItem("country_code");
    localStorage.removeItem("token");
    navigate("/reset_password")

  }

  useEffect(() => {
    let timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setSeconds(0);
        setOtp('');
        setError('');
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);



  const verifyOtp = () => {
    let req = {
      otp_number: parseInt(otp),
      phone_number: parseInt(phone),
      country_code: country_code,
      validate_phone_number: true,

    }

    apiService.verifyOtp(req).then(result => {
      if (result.data?.status_code === 1) {
        localStorage.setItem('isVarified', true);
        navigate("/confirm_password")
      } else {
        setErrors({ errorMsg: result?.data?.error.message.error });
        localStorage.setItem('isVarified', false);
      }
    })
  }

  const checkPhoneVerify = () => {
    setSeconds(60);
    setOtp('');
    setErrors({ errorMsg: '' });
    try {
      let req = {
        phone_number: phone,
        country_code: country_code,
        is_for_forgot_password: 1
      }
      apiService.sendOtp(req).then(result => {
        if (result.data?.status_code == 0) {
          setErrors({ errorMsg: result?.data?.error.message.error });
        }
        else if (result.data?.response_data["number-verified"] === false) {
          // setModalShow(true);
          // window.location.href = "/verify_otp_signup"
        } else {
          if (result.data?.response_data["number-verified"] === true) {
            setErrors({ errorMsg: '' });
          }
          else if (result.data?.response_data["number_verified"] === false) {
              // setModalShow(true);
              // window.location.href = "/verify_otp_signup"
          } else {
              if (result.data?.response_data["number_verified"] === true) {
                  setErrors({ errorMsg: '' });
              }
              else {
                  console.log("-------------------------")
              }
          }
        }
      })
      // }
    } catch (e) {
      console.error('e', e);
    }
  }

  return (
    <div className="auth_main_section">
      <div className='left_side'>
        <div className='logo_section'>
          <Logo />
        </div>
        <div>
          <div>
            <Lottie
              options={defaultOptions}
              height={160}
              width={160}
            />
          </div>
          <img src='assets/sign-in-girl.svg' alt='women' className='sign_in_girl' />
        </div>

      </div>
      <div className='right_side'>
        <div className='verify_section'>
          <h1 className='reset_text avenir_black'>Verify with OTP</h1>
          <p className='welcome_txt mt-4 mb-1 avenir_heavy'> <span className='avenir_medium'>Enter 4-digit number sent on</span> </p>
          <p className='number_text'>{country_code} {phone} <span className='edit_txt cursor_pointer' onClick={resetPasswordScreen}>EDIT</span></p>

          <div className='otp_wrp'>

            <OtpInput
              className="opt_inp"
              value={otp}
              isInputNum={true}
              numInputs={4}
              onChange={(e) => handleChange(e)}
              separator={<span></span>}
            />
          </div>
          <p className='timer'> {moment.utc(seconds * 1000).format('mm:ss')}</p>

          <InlineError errorMessage={errors.errorMsg} />
          <div className='wrap_btn'>
            {
              (otp.length == 4) ?
                <Button name={"Verify"} onClick={() => verifyOtp()} />
                :
                <Button name={"Verify"} disabled="true" />
            }
          </div>

          <p className="dont_rec_code  avenir_medium">
            Didn't receive OTP? &nbsp;
            {
              (seconds < 1) ?
                <span className="register" onClick={checkPhoneVerify}>RESEND OTP</span>
                :
                <span className="register dont_rec_code">RESEND OTP</span>
            }
          </p>
        </div>
      </div>
    </div >
  )
}

export default VerifyOtp
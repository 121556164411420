import React, { useEffect, useRef, useState } from 'react';
import '../StyleComponent/videourlpage.scss';
import { apiService } from '../Services/ApiServices';
import { useParams } from 'react-router-dom';

function VideoUrlPage() {
  const params = useParams();
  const [getYoutubeUrl, setYoutubeUrl] = useState('');

  const getYoutubeLink = () => {
    let req = { id: params?.id }
    apiService.getYoutubeUrl(req).then(res => {
      if (res?.data?.status_code == 1) {
        let url = `https://www.youtube.com/embed/${res?.data?.response_data?.id}?si=TFiZaXxIxwzV51oP?controls=0&showinfo=0&rel=0&autoplay=1&loop=1`;
        setYoutubeUrl(url)
      }
      else {
      }
    })
  }

  useEffect(() => {
    getYoutubeLink();
  }, [params?.id])

  return (
    <div className="video-background">
      {getYoutubeUrl ?
        <div className="video-foreground">
          <iframe width="560" height="315"
            src={getYoutubeUrl}
            title="YouTube video player"
            frameborder="0"
            allowfullscreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" ></iframe>
        </div>
        :
        <div className="loader1"></div>
      }
    </div>
  )
}

export default VideoUrlPage

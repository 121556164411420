import React, { useEffect, useReducer, useState } from 'react'
import Logo from "../../CommonComponent/Logo"
import Input from "../../CommonComponent/Input"
import Button from "../../CommonComponent/Button"
import { apiService } from '../../Services/ApiServices';
import { useLocation } from 'react-router';
import InlineError from "../../CommonComponent/inlineError";
import Spinner from 'react-bootstrap/Spinner';
import BtnLoader from '../../CommonComponent/BtnLoader';
import { FaPhone, FaUser } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FcNext } from "react-icons/fc";
import StoreInformation from './StoreInformation';
import BankAccountDetails from './BankAccountDetails';
import CategoriesList from './CategoriesList';


function PersonalDetails() {
    let prevPage = localStorage.getItem('signup_1');
    const [inputLoader, setInputLoader] = useState(false)
    const [step, setStep] = useState(0);
    const { state } = useLocation();
    const [isSkipDisabled, setIsSkipDisabled] = useState(false);
    const [isContinueDisabled, setIsContinueDisabled] = useState(false);
    let first_name = localStorage.getItem('first_name');
    let last_name = localStorage.getItem('last_name');
    // let email_address = localStorage.getItem('email_address')
    let phone_number = "+" + localStorage.getItem('country_code') + " " + localStorage.getItem('phone_number');
    let usernameState = localStorage.getItem('username') ? localStorage.getItem('username') : '';
    let isVarifiedState = localStorage.getItem('is_username_varified') ? true : false;
    const [inputValue, setInputValue] = useState(usernameState);
    const [isVarified, setVarifiedValue] = useState(isVarifiedState);
    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            errorMsg: ''
        }
    );

    useEffect(() => {
        if (!usernameState) {
            setInputValue('')
        }
        if (state)
            setStep(state)
        else
            setStep(0)
    }, [])

    console.log("inputValue--------", inputValue, usernameState)
    useEffect(() => {
        setInputLoader(false)
        setVarifiedValue(false)
        if (!usernameState && (inputValue.length >= 5 && inputValue.length <= 30)) {
            setInputLoader(true)
            setVarifiedValue(false)
            setError({ errorMsg: '' });
            const fetchData = async () => {
                setInputLoader(true)
                try {
                    let userName = inputValue;
                    let req = { username: userName };
                    const token = localStorage.getItem('token');
                    const response = await apiService.checkUsername(req, token).then(async result => {
                        if (result.data?.status_code === 1) {
                            if (result.data?.response_data?.username_available) {
                                setVarifiedValue(true);
                                setInputLoader(false)
                                setError({ errorMsg: '' });
                            } else {
                                setInputLoader(false)
                                setError({ errorMsg: result.data?.response_data?.detail });

                            }
                        } else {
                            setError({ errorMsg: result?.data?.error.message.error });
                            setInputLoader(false)
                        }
                    })

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            const timeoutId = setTimeout(() => {
                if (inputValue.trim() !== '') {
                    fetchData();
                }
            }, 1000);
            return () => clearTimeout(timeoutId);
        } else {
            // if (!usernameState && (inputValue.length < 5 || inputValue.length > 30))
                if (!usernameState && (inputValue.length > 0))
                setError({ errorMsg: "Username must be at least 5 characters long" })
        }
    }, [inputValue]);

    const handleInputChange = (e) => {
        console.log("userrrrrrrrrrrrrrrrr", e.target.value)
        localStorage.removeItem("username");
        localStorage.removeItem("is_username_varified");
        var value = e.target.value;
        const isValid = /^[a-zA-Z]+$/.test(value);
        if (isValid && (inputValue.trim() == '')) {
            console.log("ifffffffffffffffffffffffff", inputValue)
            if (inputValue.length < 5 || inputValue.length > 30)
                setError({ errorMsg: "Username must be at least 5 characters long" })

            setInputLoader(true);
            const lowercaseValue = e.target.value;
            setInputValue(lowercaseValue);
        }
        else {
            console.log("elseeeeeeeeeeeeeeeeeeeeeeeee", inputValue)
            if ((inputValue == "@")) {
                setInputLoader(true);
                if (inputValue.length == 1)
                    setError({ errorMsg: "Username is required" })
                else if (inputValue.length < 5 || inputValue.length > 30)
                    setError({ errorMsg: "Username must be at least 5 characters long" })
                else
                    setError({ errorMsg: "" })
            }
            var lowercaseValue = e.target.value;
            let replaceValue = lowercaseValue;
            if (value.length > 1)
                replaceValue = value;
            const isValidS = /^[a-zA-Z]+$/.test(replaceValue);
            console.log("isValidSisValidSisValidSisValidS", isValidS)
            if (isValidS) {
                setInputValue(lowercaseValue.replace(/\s/g, ""));
            } else {
                if (value.length == 0) {
                    setError({ errorMsg: "" })
                    setInputValue("")
                }
            }
        }
    };

    const nextCommonPage = () => {
        if (isVarified || (inputValue.length == 0)) {
            if (isVarified)
                setUserName1()
            else if (inputValue.length == 0)
                setUserNameSkip1()
            else
                console.log("nothing")
        }
        setStep((previous) => previous + 1)
    }

    const prevpageCommon = () => {
        if (!usernameState && error) {
            setError({ errorMsg: "" });
            setInputValue("");
        }    
        setStep((previous) => previous - 1)
    }

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };


    const setUserName1 = debounce(async () => {
        try {
            setIsContinueDisabled(true); // Disable the button immediately on click
            let userName = inputValue ? inputValue : '';
            let req = { user_name: userName };
            const token = localStorage.getItem('token');
            const result = await apiService.setusername(req, token);
            if (result.data?.status_code === 1) {
                setError({ errorMsg: '' });
                localStorage.setItem('username', inputValue);
                localStorage.setItem('is_username_varified', true);
                localStorage.setItem('signup_1', true);
            } else {
                setError({ errorMsg: result?.data?.error.message.error });
                setInputLoader(false);
            }
        } catch (e) {
            console.error('Error setting username:', e);
        } finally {
            setIsContinueDisabled(false); // Re-enable the button after processing
        }
    }, 1000); // Debounce delay of 1 second



    const setUserNameSkip1 = debounce(() => {
        try {
            setIsSkipDisabled(true); // Disable the button immediately on click
            let userName = '';
            let req = { user_name: userName };
            const token = localStorage.getItem('token');
            apiService.setusername(req, token).then(result => {
                if (result.data?.status_code === 1) {
                    setError({ errorMsg: '' });
                    localStorage.setItem('signup_1', true);
                    localStorage.setItem('username', '');
                    localStorage.setItem('is_username_varified', false);
                    setInputValue('');
                } else {
                    setError({ errorMsg: result?.data?.error.message.error });
                    setInputLoader(false);
                }
                setIsSkipDisabled(false); // Re-enable the button after processing
            }).catch(() => {
                setIsSkipDisabled(false); // Re-enable the button even if there's an error
            });
        } catch (e) {
            setIsSkipDisabled(false); // Re-enable the button if there's an exception
        }
    }, 1000); // Debounce delay of 1 second


    const setUserName = debounce(async () => {
        try {
            setIsContinueDisabled(true); // Disable the button immediately on click
            let userName = inputValue ? inputValue : '';
            let req = { user_name: userName };
            const token = localStorage.getItem('token');
            const result = await apiService.setusername(req, token);
            if (result.data?.status_code === 1) {
                setError({ errorMsg: '' });
                localStorage.setItem('username', inputValue);
                localStorage.setItem('is_username_varified', true);
                localStorage.setItem('signup_1', true);
                nextCommonPage();
            } else {
                setError({ errorMsg: result?.data?.error.message.error });
                setInputLoader(false);
            }
        } catch (e) {
            console.error('Error setting username:', e);
        } finally {
            setIsContinueDisabled(false); // Re-enable the button after processing
        }
    }, 1000); // Debounce delay of 1 second



    const setUserNameSkip = debounce(() => {
        try {
            setIsSkipDisabled(true); // Disable the button immediately on click
            let userName = '';
            let req = { user_name: userName };
            const token = localStorage.getItem('token');
            apiService.setusername(req, token).then(result => {
                if (result.data?.status_code === 1) {
                    setError({ errorMsg: '' });
                    localStorage.setItem('signup_1', true);
                    localStorage.setItem('username', '');
                    localStorage.setItem('is_username_varified', false);
                    setInputValue('');
                    nextCommonPage();
                } else {
                    setError({ errorMsg: result?.data?.error.message.error });
                    setInputLoader(false);
                }
                setIsSkipDisabled(false); // Re-enable the button after processing
            }).catch(() => {
                setIsSkipDisabled(false); // Re-enable the button even if there's an error
            });
        } catch (e) {
            setIsSkipDisabled(false); // Re-enable the button if there's an exception
        }
    }, 1000); // Debounce delay of 1 second

    console.log("isVarifiedisVarifiedisVarifiedisVarified", isVarified)

    function checkSession() {
        localStorage.clear();
        window.location.href = "/login"; // Adjust the URL based on your login page route
      }
      
      // Listen for the page load to verify session status
      window.addEventListener("load", () => {
        checkSession();
      });
      
      // Listen for browser back button actions
      window.addEventListener("popstate", () => {
        checkSession();
      });
      
      // Handle page reload or navigation changes
      window.addEventListener("beforeunload", () => {
        checkSession();
      });
      {console.log("isVarified",isVarified)}
    return (
        <>
            <div >
                <div className='signup_bg_img'>
                    <img src=' assets/signup-bg-img.png' />

                </div>
                <div className='logo_wrp'>
                    <Logo />
                </div>
                {step == 0 ?
                    <div className='signup_process'>
                        <h1 className='complete_profile_txt avenir_heavy'>Complete Your Profile Journey</h1>
                        <img src='assets/process1.svg' className='process_1' />

                        <div className='signup_modal text-center _no_width'>
                            <div className='profile__step_header'>
                                <span className='_icon mb-2'></span>
                                <span className='signup_stps_txt avenir_black'>PERSONAL DETAILS</span>
                                <span className='_icon mb-2' ></span>
                                {/* {
                                    (prevPage == "true") ?
                                        <> <span className='_icon mb-2' onClick={nextCommonPage}><FcNext /></span></>
                                        :
                                        <span className='_icon mb-2' ></span>
                                } */}
                            </div>
                            <div className='wrp_personl_info'>
                                <div className='icon_input'><FaUser /><Input value={first_name + " " + last_name} className="personal_inpt" readonly /></div>
                                {/* <div className='icon_input'><IoMdMail /><Input value={email_address} className="personal_inpt" readonly /></div> */}
                                <div className='icon_input'><FaPhone /><Input value={phone_number} className="personal_inpt" readonly /></div>
                            </div>
                            {/* <div className='col-sm-12 position-relative'> */}
                            <Input
                                type="text"
                                name="username"
                                className="usrname_txt"
                                value={inputValue}
                                placeholder={'Username'}
                                maxLength={30}
                                minLength={5}
                                onChange={(e) => handleInputChange(e)}
                            />


                            {
                                (isVarified == true) ?
                                    <span className='phn_verify'><img src='assets/check.svg' /></span>
                                    :
                                    <>
                                        {inputLoader ? <Spinner animation="border" variant="secondary" className='inpt_loader' /> : ""}
                                    </>
                            }
                            <div className='usrnm_err ps-5'>
                                <InlineError errorMessage={error.errorMsg} />
                            </div>
                            {/* </div> */}
                            <p className='choose_usrnam'>You can choose a display name on <b>BidsBoxx</b>. If you do, others will be able to see you by this display name.</p>

                            {isVarified
                                ? <Button name={isContinueDisabled ? <BtnLoader /> : "Continue"} onClick={setUserName} disabled={isContinueDisabled} />
                                : <Button name="Continue" disabled />}
                            <p><a className='register' onClick={isSkipDisabled ? null : setUserNameSkip}><span>SKIP</span></a></p>

                        </div>
                    </div> : ""}
                {step == 1 ? <StoreInformation prevpageCommon={prevpageCommon} nextCommonPage={nextCommonPage} /> : ''}
                {step == 2 ? <BankAccountDetails nextCommonPage={nextCommonPage} prevpageCommon={prevpageCommon} /> : ''}
                {step == 3 ? <CategoriesList prevpageCommon={prevpageCommon} /> : ''}
            </div>


        </>
    )
}

export default PersonalDetails


import React, { useState, useEffect } from 'react';

function AudioBar({ volume }) {
  const [barHeight, setBarHeight] = useState(0);
  useEffect(() => {
    // Calculate the bar height based on the volume (assuming volume is a value between 0 and 1)
    const newHeight = volume * 100; // Assuming volume is between 0 and 1
    setBarHeight(newHeight);
  }, [volume]);

  return (
    <div style={{ width: '20px', height: '100px', backgroundColor: 'gray', position: 'relative' }}>
      <div style={{ width: '100%', height: `${barHeight}%`, backgroundColor: 'green', position: 'absolute', bottom: 0 }}></div>
    </div>
  );
}

export default AudioBar;

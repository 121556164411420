import React, { useEffect, useState, useReducer } from 'react'
import { RiDownload2Fill } from "react-icons/ri";
import { ProductImage, Status1, Status2, Status3, Status4, Status5, Status55 } from '../../CommonComponent/ImageConstants';
import { BsCheckLg } from "react-icons/bs";
import moment from 'moment';
import { OrderStatusSkeleton } from '../../CommonComponent/Skeleton';
import { IoCloseCircle } from 'react-icons/io5';
import { FaChevronLeft } from 'react-icons/fa6';
import { Modal } from 'react-bootstrap';
import Input from '../../CommonComponent/Input';
import Button from '../../CommonComponent/Button';
import { apiService } from '../../Services/ApiServices';
import { notify } from '../../CommonComponent/Toaster';
import TrackingStatus from './TrackingStatus';
import ReturnTrackingStatus from './ReturnTrackingStatus';
import InlineError from '../../CommonComponent/inlineError';


function OrderStatus(props) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelReasonError, setCancelReasonError] = useState("");
    const [inputLoader, setInputLoader] = useState(false);
    const token = localStorage.getItem('token');
    const [orderCanceled, setOrderCanceled] = useState(true)
    const [trackingStatusCode, setTrackingStatusCode] = useState([])
    const [returnTrackingStatusCode, setReturnTrackingStatusCode] = useState([])
    const [handleValidate, setHandleValidate] = useState(false)
    console.log("OrderStatus -->", props.list)
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            list: {},
        }
    );

    const {
        id,
        return_awb_no,
        return_discription,
        return_order_number,
        return_order_status,
        order_status,
        product_name,
        is_buyer_cancel_request,
        is_order_return,
        return_tracking_status_code,
        tracking_status_code,
        is_cancel_request,
        cancel_discription,
        order_date,
        order_number,
        awb_no,
        pincode,
        description,
        image,
        address_line,
        amount,
        bid_amount,
        buyer_name,
        buyer_phone,seller_address } = props?.list


    const [show, setShow] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        setOrderCanceled()
    }, []);
    console.log("screenWidthscreenWidth", screenWidth)


    useEffect(() => {
        create_OrderStatusArr()
    }, [])

    const create_OrderStatusArr = () => {
        // debugger;
        const min = 1;
        const max = tracking_status_code;
        const arr = [];
        for (let i = min; i <= max; i++) {
            arr.push(i);
        }
        setTrackingStatusCode(arr)
        if (is_order_return === true) {
            const min = 1;
            const max = return_tracking_status_code;
            const arr1 = [];
            for (let i = max; i >= min; i--) {
                arr1.push(i);
            }
            setReturnTrackingStatusCode(arr1)
        }

        console.log("create_OrderStatusArr", arr,returnTrackingStatusCode)
    }

    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
    }
    const cancelOrder = (order_id, reason) => {
        try {
            if (cancelReason == "" || cancelReason == undefined) {
                setCancelReasonError("Please fill the cancel reason")
            }
            else {
                let req = {
                    "order_no": order_id,
                    "cancel_discription": reason
                }
                setInputLoader(true)
                console.log("cancelOrderREQ", req)
                apiService.cancelOrder(req, token).then(res => {
                    console.log("scscscscscscscs", res.data.response_data)
                    if (res?.data?.status_code == 1) {
                        console.log(res.data?.status_code, "OrdersrderCard response", res?.data?.response_data);
                        setInputLoader(false);
                        setOrderCanceled(false)
                        notify("success", res.data.response_data);
                        setShow(false);
                        props.callOrderDetail(order_id)
                        create_OrderStatusArr()
                    }
                    else {
                        setInputLoader(false);
                    }

                })
            }


        } catch (e) {
            console.error('e', e);
        }

    }
    const approveCalcelRequest = (order_id) => {
        let req = {
            "order_no": order_id
        }
        apiService.approveCalcelRequest(req, token).then(res => {
            console.log("approveCalcelRequest", res.data.response_data)
            if (res?.data?.status_code == 1) {
                props.callOrderDetail(order_id)
                notify("success", "Buyer requested for cancellation of order approved");
            }
            else {
                notify("err", res?.data?.error?.message);
            }
        })
    }

    const rejectCalcelRequest = (order_id) => {
        let req = {
            "order_no": order_id
        }
        apiService.rejectCalcelRequest(req, token).then(res => {
            console.log("rejectCalcelRequest", res.data)
            if (res?.data?.status_code == 1) {
                notify("success", "Buyer requested for cancellation of order rejected");
                props.callOrderDetail(order_id)
            }
            else {
                notify("err", res?.data?.error?.message);
            }

        })
    }

    const handleOrderChanges = (e) => {
        setCancelReason(e.target.value)
        setCancelReasonError("")
    }

    const handleDownload = () => {
        let req = {
            id: id
        };
        setInputLoader(true)
        setUserInput({ isLoader: true })
        apiService.downloadOrderInvoice(req, token).then(res => {
            console.log("scscscscscscscs", res.data)
            if (res?.data?.status_code == 1) {
                console.log(res.data?.status_code, "downloadOrderInvoice response", res?.data?.response_data.url);
                setInputLoader(false);
                setUserInput({ list: res?.data?.response_data.url, isLoader: false })
                // Create a temporary link element to trigger the download
                const link = document.createElement('a');
                link.href = res?.data?.response_data.url;
                link.download = res?.data?.response_data.url; // Set the default download file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                setInputLoader(false);
            }
        })
    };
    return (
        <>

            <div className='_order_dlivered_modal '>
                {console.log("is_cancel_request_trackingStatusCode", props?.list)}
               {(is_order_return && tracking_status_code==5) &&
                    <><h4 className='mb-4 ps-4 ps-lg-0'>Return Order Details</h4>
                        <div className='p-4 _order_dlivered  mb-5'>
                            <div className='_mobile_order_details'>
                                <div className='product_banner'>
                                    <div className='_img'>
                                        <img src={image ? image : ProductImage} alt="product" />
                                    </div>
                                    <h5 className='mt-3 mb-0'>{return_order_status}</h5>
                                </div>
                                <div className='_orders_details'>
                                    <h5 className='mt-3'>Order Details</h5>
                                    <p><span>Order Date</span><span>{moment(order_date).format('DD MMM YYYY')}</span></p>
                                    <p><span>Product Name</span><span>{description}</span></p>
                                    <p><span>Price</span><span>{bid_amount}</span></p>
                                    <p><span>Order Number</span><span>{return_order_number}</span></p>
                                    <p><span>Tracking ID</span><span>{return_awb_no}</span></p>
                                    <p>{return_discription}</p>
                                </div>
                                <ReturnTrackingStatus trackingStatusCode={returnTrackingStatusCode} statusCode={return_tracking_status_code} />
                                <div className='_address'>
                                    <h6 className='mt-4'>Delivery Address</h6>
                                    <p>
                                    {seller_address?.address_line}
                                    {seller_address?.state}
                                    {seller_address?.city}
                                    {seller_address?.pincode}</p>
                                </div>

                            </div>

                            <div className='_order_details '>
                                <div className='_order'>
                                    <h5>Order Date : <span>{moment(order_date).format('DD MMM YYYY')}</span></h5>
                                    <h5>Product Name : <span>{product_name}</span></h5>
                                    <div className='_card'>
                                        <div className='_img'>
                                            <img src={ProductImage} alt="product" />
                                        </div>
                                        <div className='_details'>
                                            <h5>Order Amount : <span>{bid_amount}</span></h5>
                                            <h5>Order Number : <span>{return_order_number}</span></h5>
                                            <h5>Tracking ID : <span>{return_awb_no}</span></h5>
                                            <p>{return_discription}</p>
                                        </div>
                                    </div>
                                    <h6 className='mt-4'>Delivery Address</h6>
                                    <p className='_ligt mb-4'>  {seller_address?.address_line}
                                    {seller_address?.state}
                                    {seller_address?.city}
                                    {seller_address?.pincode}</p>

                                    <div className='_download px-4 show_desktop' onClick={handleDownload}>
                                        <p className=''>Download Invoice </p>
                                        <span><RiDownload2Fill /></span>
                                    </div>


                                </div>
                                <ReturnTrackingStatus trackingStatusCode={returnTrackingStatusCode} statusCode={return_tracking_status_code} />

                            </div>
                            <div className='d-block d-md-none d-lg-none d-xl-none mt-4'>
                                <div className='_download px-4 ' onClick={handleDownload}>
                                    <p className=''>Download Invoice</p>
                                    <span><RiDownload2Fill /></span>
                                </div>
                            </div>


                        </div>
                    </>
                } 











                <><h4 className={`mb-4  ${is_order_return ? '' : 'ps-4 ps-lg-0'}`}>Order Details</h4>
                    <div className='p-4 _order_dlivered'>
                        <div className='_mobile_order_details'>
                            <div className='product_banner'>
                                <div className='_img'>
                                    <img src={image ? image : ProductImage} alt="product" />
                                </div>
                                <h5 className='mt-3 mb-0'>{order_status}</h5>
                            </div>
                            <div className='_orders_details'>
                                <h5 className='mt-3'>Order Details</h5>
                                <h5 style={{ color: "#00077a" }}>{bid_amount <= 0 ? "Product Sold By Giveaway" : "Product Sold By Auction"}</h5>
                                <p><span>Order Date</span><span>{moment(order_date).format('DD MMM YYYY')}</span></p>
                                <p><span>Product Name</span><span>{description}</span></p>
                                <p><span>Price</span><span>{bid_amount}</span></p>
                                <p><span>Order Number</span><span>{order_number}</span></p>
                                <p><span>Tracking ID</span><span>{awb_no}</span></p>
                            </div>
                           
                            <TrackingStatus trackingStatusCode={trackingStatusCode} statusCode={tracking_status_code} orderStatusCanceled={is_cancel_request}/>
                            <div className='_address'>
                                <h6 className='mt-4'>Delivery Address</h6>
                                <p>{buyer_name}<span className='ms-4'>{buyer_phone}</span></p>
                                <p>{address_line} {pincode}</p>
                            </div>

                        </div>

                        <div className='_order_details '>
                            <div className='_order'>
                                <h5 style={{ color: "#f97732" }}>{bid_amount <= 0 ? "Product Sold By Giveaway" : "Product Sold By Auction"}</h5>
                                <h5>Order Date : <span>{moment(order_date).format('DD MMM YYYY')}</span></h5>
                                <h5>Product Name : <span>{product_name}</span></h5>
                                <div className='_card'>
                                    <div className='_img'>
                                        <img src={image ? image : ProductImage} alt="product" />
                                    </div>
                                    <div className='_details'>
                                        <h5>Order Amount : <span>{bid_amount}</span></h5>
                                        <h5>Order Number : <span>{order_number}</span></h5>
                                        <h5>Tracking ID : <span>{awb_no}</span></h5>
                                    </div>
                                </div>
                                <h6 className='mt-4'>Delivery Address</h6>
                                <p>{buyer_name} {buyer_phone}</p>
                                <p className='_ligt mb-4'>{address_line} {pincode}</p>

                                <div className='_download px-4 show_desktop' onClick={handleDownload}>
                                    <p className=''>Download Invoice</p>
                                    <span><RiDownload2Fill /></span>
                                </div>
                                {tracking_status_code < 3 &&
                                    <>
                                    <div className='cancel_order mt-3 mb-3'>
                                            {console.log("orderCanceled", orderCanceled, is_cancel_request, orderCanceled || is_cancel_request)}
                                            <span>{!is_cancel_request ? "Want to cancel the Order?" : <div><b>This Order is Cancelled</b> <p className='mb-0' style={{fontSize:'14px'}}><b>Reason :- </b>{cancel_discription}</p></div>}</span>
                                          
                                            {!is_cancel_request && <button className='btn btn-xs btn-primary ms-0 me-0' onClick={handleShow}>Cancel Order</button>}
                                        </div>
                                        <hr />
                                    </>
                                }
                                {is_buyer_cancel_request &&
                                    <><div className='d-flex justify-content-between mt-3'>
                                        <div> <p>Buyer requested for cancellation:</p>
                                            <p className='_ligt'> {cancel_discription}</p></div>
                                        <div className='small_btn'>
                                            <button className='btn btn-xs btn-primary' onClick={() => approveCalcelRequest(id)}>Approve</button>
                                            <button className='btn btn-xs btn-primary' onClick={() => rejectCalcelRequest(id)}>Reject</button>
                                        </div>
                                    </div>
                                        <hr />
                                    </>
                                }

                            </div>
                            {console.log("trackingStatusCode_order_status_page", trackingStatusCode)}

                            <TrackingStatus trackingStatusCode={trackingStatusCode} statusCode={tracking_status_code} orderStatusCanceled={is_cancel_request}/>
                        </div>
                        <div className='d-block  d-lg-none d-xl-none mt-4'>
                        {tracking_status_code < 3 &&
                                    <> <hr />
                                    <div className='cancel_order mt-3 mb-3'>
                                            {console.log("orderCanceled", orderCanceled, is_cancel_request, orderCanceled || is_cancel_request)}
                                            <span>{!is_cancel_request ? "Want to cancel the Order?" :<div><b>This Order is Cancelled</b> <p className='mb-0' style={{fontSize:'14px'}}><b>Reason :- </b>{cancel_discription}</p></div>}</span>
                                            {!is_cancel_request && <button className='btn btn-xs btn-primary ms-0 me-0' onClick={handleShow}>Cancel Order</button>}
                                        </div>
                                       
                                    </>
                                }
                                {is_buyer_cancel_request &&
                                    <>
                                    <hr />
                                    <div className='d-flex justify-content-between cancellation_mobile my-2'>
                                        <div> <p>Buyer requested for cancellation:</p>
                                            <p className='_ligt'> {cancel_discription}</p></div>
                                        <div className='small_btn'>
                                            <button className='btn btn-xs btn-primary' onClick={() => approveCalcelRequest(id)}>Approve</button>
                                            <button className='btn btn-xs btn-primary' onClick={() => rejectCalcelRequest(id)}>Reject</button>
                                        </div>
                                    </div>
                                    </>
                                }
                            <div className='_download px-4 ' onClick={handleDownload}>
                                <p className=''>Download Invoice</p>
                                <span><RiDownload2Fill /></span>
                            </div>
                        </div>


                    </div>
                </>
            </div>
            {/* <div  className='___hide_mobile_modals _order_delivered_popup'>           
            <div className='p-4 _order_dlivered'>
                <div className='close_icon' onClick={()=>handleClose('order-status')}><span><IoCloseCircle /></span></div>
                <h4 className='mb-4'><span className='back_icon show_mobile' onClick={()=>handleClose('order-status')}><FaChevronLeft/></span>Order Delivered</h4>
                {userInput.isLoader ? <OrderStatusSkeleton/> :
                <OrderStatus list={props.list}/>
                }
               {console.log("setUserInput.list",props.list)}
            </div>
        </div>         */}
            <Modal show={show} onHide={() => handleClose('cancel-order')} centered className='_cancel_order_modal' size="md" >
                <Modal.Body>
                    <div className='p-5 _cancel_order'>
                        <div className='close_icon' onClick={() => handleClose('cancel-order')}><span><IoCloseCircle /></span></div>
                        <h4 className='mb-5'>Reason for Cancel Order</h4>
                        <Input
                            name="cancelReason"
                            value={cancelReason}
                            onChange={(e) => handleOrderChanges(e)}
                            type="text" />
                        <div className='left_align_error mt-2'>
                            <InlineError errorMessage={cancelReasonError} />
                        </div>
                        <div className='text-center mt-5'>
                            <Button name="Cancel Order" onClick={() => cancelOrder(id, cancelReason)} className="btn  lower_case common_button " />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderStatus
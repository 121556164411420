import React, { useReducer, useState, useEffect, useRef, useCallback } from 'react';
import Button from '../../CommonComponent/Button';
import Modal from 'react-bootstrap/Modal';
import Input from "../../CommonComponent/Input";
import { apiService } from '../../Services/ApiServices';
import InlineError from '../../CommonComponent/inlineError';
import { IoCloseCircle } from "react-icons/io5";
import BtnLoader from '../../CommonComponent/BtnLoader';
import Spinner from 'react-bootstrap/Spinner';
import statesAndCities from "../../Utils/States"
import { getCroppedImg } from "../../CommonComponent/Helper";
import { checkFileSize } from '../../CommonComponent/Validation';
import moment from 'moment';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import { validateEmail, validatePassword } from '../../CommonComponent/Validation';
import { EditIcon } from '../../Svg';
import { FaPencil } from 'react-icons/fa6';

function EditStore(props) {
  const textareaRef = useRef(null);
  const logout = useLogout();
  const [btnLoader, setBtnLoader] = useState(false);
  const [inputLoader, setInputLoader] = useState(false);
  const token = localStorage.getItem('token');
  const [countryCode, setCountryCode] = useState(91);
  let store_phone_number = props?.storeDetails?.phone_number;
  const [mobile, setMobile] = useState(store_phone_number);
  const [states, setStates] = useState("")
  const [cityList, setCity] = useState([])
  let checked = false;
  let haveGst = (props.storeDetails.have_gst == true) ? true : false;
  const [isAgreed, setIsAgreed] = useState(haveGst);
  const [isAllAdded, setisAllAdded] = useState(false);
  let isGstVerified = (props.storeDetails.is_gst_verified == true) ? true : false;
  const [isGSTVerify, setGSTVerify] = useState(isGstVerified);
  let storeName = props.storeDetails?.store_name || '';
  let storeImage = props.storeDetails.store_image || '';
  const [getStoreImage, setStoreImage] = useState(storeImage);
  let addressLine = props.storeDetails.address_line || '';
  let state = props.storeDetails.state || '';
  let selectedCity = props.storeDetails.city || '';
  let pincode = props.storeDetails.pincode ? props.storeDetails.pincode : '';
  let gst_number = props.storeDetails.gst_number || '';
  let description = props.storeDetails.description || '';
  let registrationDate = props.storeDetails.gst_date || '';
  let fb = props.storeDetails.fbUrl || '';
  let insta = props.storeDetails.instagramUrl || '';

  let email_address = localStorage.getItem('email_address') ? localStorage.getItem('email_address') : props.storeDetails.email_address || '';
  // let email_address = props.storeDetails.email_address || '';
  const [newUrl, setNewUrl] = useState([]);
  const [cImage, setCImage] = useState('');
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const inputRef = useRef();
  const [isStoreName, setisStoreName] = useState(true);
  const [isPincode, setisPincode] = useState(true);
  const [isState, setisState] = useState(true);
  const [isCity, setisCity] = useState(true);
  const [isAddress, setisAddress] = useState(true);
  const [isPhone, setisPhone] = useState(true);
  const [isDescription, setDescription] = useState(true);
  const [fbUrl, setFbUrl] = useState(fb);
  const [instagramUrl, setInstagramUrl] = useState(insta);
  const [isEmail, setisEmail] = useState(true);
  const [isFBURL, setisFBURL] = useState(true);
  const [isINSTAURL, setisINSTAURL] = useState(true);
  const [isImg, setisImg] = useState(true);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      img: [], storeName: storeName, storeImage: '', countryCode: '', phoneNumber: store_phone_number, addressLine: addressLine,
      state: state, city: '', pincode: pincode, haveGst: '', isGstVerified: isGstVerified, email: email_address,
      gstNumber: gst_number, loading: false, isResponse: false, isClearable: true, isLoading: true,
      imgError: '',
      media: [],
      document: [],
      documentErr: '',
      registrationDate: registrationDate,
      storeDescription: description
    }
  );

  const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      img: [], storeName: '', storeImage: '', countryCode: '', phoneNumber: '', addressLine: '',
      state: '', city: '', pincode: '', haveGst: '', isGstVerified: '',
      gstNumber: '', registrationDate: '', storeDescription: '', email: ''
    }
  );

  let isVarifiedEmail = localStorage.getItem('isVarifiedEmail') ? localStorage.getItem('isVarifiedEmail') : false;

  const [modalShow, setModalShow] = useState(false);


  useEffect(() => {
    const storedCity = props.storeDetails.city;
    if (storedCity) {
      selectState(userInput.state)
      setUserInput({ city: storedCity });
    }
  }, []);

  const cropInitialState = {
    aspect: 1,
    width: 100,
  }
  const [crop, setCrop] = useState({
    aspect: 1,
    width: 100,
  });

  const onSelectFile = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const checkSize = await checkFileSize(e);
      if (!checkSize) {
        setStoreImage('');
        setCImage(e.target.files);
        const reader = new FileReader();
        reader.addEventListener("load", () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
        setUserInput({
          documentErr: '',
        });
      } else {
        setStoreImage('');
        setCImage('');
        setNewUrl('');
        setUserInput({
          documentErr: "Image size must be less than 2 MB",
        });
      }
    }
    setBtnLoader(false);
  };

  const completedCrop = crop => {
    getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
      setNewUrl(res.url);
    })
  }

  const handleFileUpload = () => {
    if (upImg) {
      setUpImg('')
      setCrop(cropInitialState)
      userInput.img.push({ "url": newUrl })
      getCroppedImg(imgRef.current, crop, cImage[0].name).then(res => {
        userInput.document.push(res);
        setisImg(true)
        addStoreImage()
      })

    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const selectStateHandler = (e) => {
    setisState(false)
    setisCity(false)
    setUserInput({city:''});
    const selectedStateName = e.target.value;
    const selectedState = statesAndCities.find((item) =>
      item.name === selectedStateName
    );
    setStates(selectedState);
    setUserInput({ state: selectedState.name });
    setCity(selectedState?.districts || []); // Check if selectedState is defined
    setError({ state: '' });
    if (selectedState.name.length > 0) {
      setisState(true)
      setisCity(false)
    }
  }

  const selectCityHandler = (e) => {
    const selectedCityName = e.target.value;
    setisCity(false)
    setUserInput({ city: e.target.value });
    setError({ city: '' });
    if (selectedCityName) {
      setisCity(true)
    }
  }

  const handleChange = e => {
    if (e.target.name == 'pincode') {
      setisPincode(false);
      let pincode = e.target.value;
      setUserInput({city:'', state:''});
      const sanitizedValue = e.target.value.replace(/\D/g, ''); // Remove non-digits
      setUserInput({ ...userInput, [e.target.name]: sanitizedValue });
      if ((pincode == undefined) || (pincode.length == 0)) {
        setError({ pincode: 'Pincode is required' });
      } else if ((pincode.length > 0) && (pincode.length <= 5)) {
        setError({ pincode: 'Pincode must have 6 digits' });
      } else {
        setisPincode(true);
        setError({ ...error, [e.target.name]: '', });
      }

    }
    else if (e.target.name == 'storeName') {
      setisStoreName(false);
      localStorage.setItem('is_gst_verified', false);
      setGSTVerify(false)
      let storeName = e.target.value;
      setUserInput({ ...userInput, [e.target.name]: storeName });
      if ((storeName == undefined) || (storeName.length == 0)) {
        setError({ storeName: 'Store name is required' });
      } else if ((storeName.length > 0) && (storeName.length <= 1)) {
        setError({ storeName: 'Store name must contain at least 2 characters' });
      } else {
        setisStoreName(true);
        setError({ ...error, [e.target.name]: '', });
      }
    }
    else if (e.target.name == 'addressLine') {
      console.log("e.target.name____________________", e.target.name, e.target.value)
      setisAddress(false);
      let storeAddress = e.target.value;
      setUserInput({ ...userInput, [e.target.name]: storeAddress });
      if ((storeAddress == undefined) || (storeAddress.length == 0)) {
        setisAddress(false);
        setError({ addressLine: 'Store Physical Location is required' });
      } else if ((storeAddress.length > 0) && (storeAddress.length <= 1)) {
        setisAddress(false);
        setError({ addressLine: 'Store Physical Location must contain at least 2 characters' });
      } else {
        setisAddress(true);
        setError({ ...error, [e.target.name]: '', });
      }
    }
    else if (e.target.name == 'storeDescription') {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setDescription(false);
      let desc = e.target.value;
      setUserInput({ ...userInput, [e.target.name]: desc });
      if ((desc == undefined) || (desc.length == 0)) {
        setError({ storeDescription: 'Store description is required' });
      } else if ((desc.length > 0) && (desc.length <= 1)) {
        setError({ storeDescription: 'Store description must contain at least 2 characters' });
      } else {
        setDescription(true);
        setError({ ...error, [e.target.name]: '', });
      }
    }
    else {
      setError({ ...error, [e.target.name]: '', });
      setUserInput({ ...userInput, [e.target.name]: e.target.value });
    }
    setBtnLoader(false)
  };

  const handleValidate = () => {
    let validate = true;
    let { storeName, addressLine, state, city, pincode, email, img , storeDescription} = userInput;
    console.log("city__________________", city, userInput)
    setError({});
    if ((storeName == undefined) || (storeName.length == 0)) {
      setError({ storeName: 'Store name is required' });
      validate = false
    }

    if (storeName) {
      if (storeName.length < 2) {
        setError({ storeName: 'Store name must contain at least 2 characters' });
        validate = false
      } else if (storeName.length > 100) {
        setError({ storeName: 'Store name must contain only 100 characters' });
        validate = false
      } else {

      }
    }

    if ((storeDescription == undefined) || (storeDescription.length == 0)) {
      setError({ storeDescription: 'Store description is required' });
      validate = false
    }

    if ((mobile == undefined) || (mobile == '')) {
      setError({ mobile: 'Phone number is required' });
      validate = false
    }

    if ((mobile.length < 10) || (mobile.length >= 11)) {
      setError({ mobile: 'Phone must contain only 10 characters' });
      validate = false
    }

    if ((addressLine == undefined) || (addressLine.length == 0)) {
      setError({ addressLine: 'Store Physical Location is required' });
      validate = false
    }
    if ((state == undefined) || (state.length == 0)) {
      setError({ state: 'State is required' });
      validate = false
    }

    if ((city == undefined) || (city.length == 0)) {
      setError({ city: 'City is required' });
      validate = false
    }
    if ((pincode == undefined) || (pincode.length == 0)) {
      setError({ pincode: 'Pincode is required' });
      validate = false
    }

    if ((email == undefined) || (email == '')) {
      setError({ email: 'Email is required' });
      validate = false
    }

    else if (!validateEmail(email).status) {
      setError({ email: 'Please enter valid email' });
      validate = false
    }
    console.log("isVarifiedEmail____________", isVarifiedEmail, email)

    if (isVarifiedEmail == false) {
      setError({ email: 'Please verify email address' });
      validate = false
    }


    // if(!(img.length >= 1)){
    //   setError({ img: 'Store logo is required' });
    //   validate = false
    // }

    if((isAgreed == true) && (isGSTVerify == false)){
      setError({ gstNumber: 'GST number not verified' });
      validate = false
    }

    if(fbUrl){
      if (fbUrl && !isFacebookUrl(fbUrl)) {
        setError({ fbUrl: 'Please enter a valid Facebook URL' });
        validate = false
      }
    }


    if(instagramUrl){
      if (instagramUrl && !isInstagramUrl(instagramUrl)) {
        setError({ instagramUrl: 'Please enter a valid Instagram URL' });
        validate = false
      }
    }



    return validate;
  }

  const handleValidateGST = () => {
    let validate = true;
    let { gstNumber, registrationDate } = userInput;
    setError({});
    if ((gstNumber == undefined) || (gstNumber.length == 0)) {
      setError({ gstNumber: 'GST number is require' });
      validate = false
    }

    if (gstNumber) {
      if (gstNumber.length < 15) {
        setError({ gstNumber: 'GST number must contain only 15 characters' });
        validate = false
      } else if (gstNumber.length > 16) {
        setError({ gstNumber: 'GST number must contain only 15 characters' });
        validate = false
      } else {

      }
    }

    if ((registrationDate == undefined) || (registrationDate.length == 0)) {
      setError({ registrationDate: 'GST registration date is require' });
      validate = false
    }
    return validate;
  }

  const addStoreInformation = () => {
    let haveGst = (isAgreed == true) ? 1 : 0;
    setBtnLoader(false)
    try {
      if (handleValidate() == true) {
        setBtnLoader(true)
        let { storeName, addressLine, state, city, pincode, gstNumber, storeDescription, registrationDate } = userInput
        let formData = new FormData();
        formData.append("store_name", storeName);
        formData.append("country_code", "+" + countryCode);
        formData.append("address_line", addressLine);
        formData.append("city", city);
        formData.append("pincode", pincode);
        formData.append("is_gst_verified", userInput.isGstVerified);
        formData.append("gst_number", gstNumber);
        formData.append("phone_number", mobile);
        formData.append("have_gst", haveGst);
        formData.append("state", state);
        formData.append("description", storeDescription);
        formData.append("fbUrl", fbUrl);
        formData.append("instagramUrl", instagramUrl);
        formData.append("email_address", userInput.email);
        if(gstNumber)
          formData.append("gst_date", registrationDate);

        setUserInput({ isLoader: true });
        apiService.addStoreInfo(formData, token).then(result => {
          console.log("result_____________________", result, result.data?.response_data.store.store_image)
          if (result.data?.status_code == 1) {
            sessionStorage.setItem('logo', result.data?.response_data.store.store_image);
            sessionStorage.setItem('name', result.data?.response_data.store.store_name);
            localStorage.setItem('logo', result.data?.response_data.store.store_image);
            localStorage.setItem('name', result.data?.response_data.store.store_name);
            setUserInput({ city: city });
            setBtnLoader(false)
            props.fetchData();
            props.onlyClose();
          } else {
            // if (result?.data?.status_code != 1) {
            //   notify("err", result?.data?.error?.message?.error);
            //   // logout();
            //   setError({ email: result?.data?.error.message.error });
            //   setBtnLoader(false);
            //   setUserInput({ isLoader: false });
            // }
            if (result?.data?.status_code == 2)
              logout();

            if (result?.data?.status_code == 5)
              logout();

            setError({ email: result?.data?.error.message.error });
            setisEmail(false)
            setBtnLoader(false);
            setUserInput({ isLoader: false });
          }
        })
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  const checkGSTVerification = () => {
    setInputLoader(false);
    setError({ gstNumber: '' });
    try {
      if (handleValidateGST() == true) {
        setInputLoader(true)
        let req = {
          gst_number: userInput.gstNumber,
          name: userInput.storeName,
          date:
            moment(userInput.registrationDate).format("DD/MM/YYYY")
        }
        setUserInput({ isLoader: true })
        apiService.checkGSTVerification(req, token).then(result => {
          if (result.data?.status_code == 1) {
            setUserInput({ isGstVerified: true });
            setGSTVerify(true);
            setInputLoader(false)
          } else {
            setError({ gstNumber: result?.data?.error.message.error });
            setBtnLoader(false)
            setUserInput({
              isLoader: false
            })
            setInputLoader(false)
          }
        })
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  const handleAgreeChange = (e) => {
    checked = e.target.checked;
    setIsAgreed(checked);
  };

  const getCurrentLocation1 = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Call a function to get the address using the obtained coordinates
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    setBtnLoader(false)

  };


  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // Call a function to get the address using the obtained coordinates
          console.log(latitude, longitude, 'latitude, longitude');
          const roundedLatitude = parseFloat(latitude.toFixed(5));
          const roundedLongitude = parseFloat(longitude.toFixed(5));
          console.log(roundedLatitude, roundedLongitude, 'rounded latitude, longitude');
          getAddressFromCoordinates(roundedLatitude, roundedLongitude);
          // getAddressFromCoordinates(latitude, longitude);
          setBtnLoader(false);
        },
        (error) => {
          console.error('Error getting location:', error.message);
          setBtnLoader(false);
        },
        {
          enableHighAccuracy: true,//true
          timeout: 10000,       // 10 seconds timeout
          maximumAge: 0         // no cache
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setBtnLoader(false);
    }
  };

  const selectState = (e) => {
    const selectedStateName = e;
    const selectedState = statesAndCities.find((item) =>
      item.name === selectedStateName
    );
    setStates(selectedState);
    setUserInput({ state: e });
    setCity(selectedState?.districts || []); // Check if selectedState is defined
  }

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const apiKey = 'AIzaSyAiedEeR1iMjnAkxNJGpiFk6tBG0ii4YhY';
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        const address = data.results[0].formatted_address;
        setisAddress(true);
        setisState(true);
        setisCity(true);
        setisPincode(true);
        setUserInput({ "addressLine": address });
        setError({ addressLine: '' });


        if (data.results[0]) {
          for (var i = 0; i < data.results[0].address_components.length; i++) {
            var types = data.results[0].address_components[i].types;
            var administrative_area_level_1;
            if (types.includes('administrative_area_level_1')) {
              administrative_area_level_1 = data.results[0].address_components[i].long_name;
              selectState(administrative_area_level_1)
              setStates({ "state": locality });
              setError({ state: '' });
            }
            var city
            if (types.includes('administrative_area_level_3')) {
              city = data.results[0].address_components[i].long_name;
              setUserInput({ city: city });
              setError({ city: '' });
            }


            const cityName = administrative_area_level_1; // Replace this with the actual city name you want to search for
            console.log("cityName_________________", administrative_area_level_1, address)
            var newAddress;

            // Find the index of the city name in the formatted_address
            const cityIndex = address.indexOf(administrative_area_level_1);

            if (cityIndex !== -1) {
              const commaBeforeCity = address[cityIndex - 2] === ',' ? cityIndex - 2 : cityIndex;

              // Get the part of the string before the city name and any extra comma
              const newAddress = address.substring(0, commaBeforeCity);
              setUserInput({ "addressLine": newAddress.trim() });
            } else {
              console.log("City name not found in the address.");
            }


            if (types.includes('locality')) {
              var locality = data.results[0].address_components[i].long_name;
            }

            if (types.includes('postal_code')) {
              var postalCode = data.results[0].address_components[i].long_name;
              setUserInput({ "pincode": postalCode });
              setError({ pincode: '' });
            }

            if (types.includes('street_number')) {
              var street_number = data.results[0].address_components[i].long_name;
            }

            if (types.includes('route')) {
              var route = data.results[0].address_components[i].long_name;
            }

            if (types.includes('premise')) {
              var premise = data.results[0].address_components[i].long_name;
            }

            if (types.includes('sublocality_level_3')) {
              var sublocality_level_3 = data.results[0].address_components[i].long_name;
            }

            if (types.includes('sublocality_level_1')) {
              var sublocality_level_1 = data.results[0].address_components[i].long_name;
            }

            if (types.includes('sublocality_level_2')) {
              var sublocality_level_2 = data.results[0].address_components[i].long_name;
            }

            addressLine = (premise ? premise : street_number) + " " + (sublocality_level_3 ? sublocality_level_3 : route) + " " + sublocality_level_1 + " " + sublocality_level_2;

            console.log("___________________", newAddress)

            setError({ addressLine: '' });
          }
        }
      } else {
        console.error('No address found for the given coordinates.');
      }
    } catch (error) {
      console.error('Error fetching address:', error.message);
    }
  };



  const handleInput = (event) => {
    setisPhone(false);
    const sanitizedValue = event.target.value.replace(/\D/g, ''); // Remove non-digits
    let formattedMobileNumber = sanitizedValue;
    // Check if the first digit is 0
    if (formattedMobileNumber.startsWith('0')) {
      // If the first digit is 0, ensure there are 11 digits
      formattedMobileNumber = formattedMobileNumber.slice(0, 11);
    } else {
      // If the first digit is not 0, ensure there are 10 digits
      formattedMobileNumber = formattedMobileNumber.slice(0, 10);
    }

    setMobile(formattedMobileNumber);
    if ((formattedMobileNumber.length < 10) && (formattedMobileNumber.length > 0)) {
      setError({ mobile: 'Please enter 10 digits phone number' });
    } else {
      if (formattedMobileNumber.length >= 10) {
        setisPhone(true);
        setError({ mobile: '' });
      } else {
        setError({ mobile: 'Phone number is required' });
      }
    }
  };


  const handleChangeGST = e => {
    const updatedValue = e.target.value.toUpperCase();
    setUserInput({ isGstVerified: false });
    setGSTVerify(false);
    setUserInput({ ...userInput, [e.target.name]: updatedValue });
    let gst = e.target.value;
    if ((gst == undefined) || (gst == '')) {
      setError({ gstNumber: 'GST number is required' });
    } else if ((gst.length > 0) && (gst.length < 15)) {
      setError({ gstNumber: 'GST number must contain 15 characters' });
    } else {
      setError({ gstNumber: '' });
    }
  };

  const handleRegistrationDate = e => {
    const updatedValue = e.target.value;
    console.log("updatedValue_________",updatedValue)
    setGSTVerify(false);
    setUserInput({ ...userInput, [e.target.name]: updatedValue });
    let gstRN = e.target.value;
    if ((gstRN == undefined) || (gstRN == '')) {
      setError({ registrationDate: 'GST registration date is required' });
    } else {
      setError({ registrationDate: '' })
    }
  };

  const addStoreImage = () => {
    try {
      let formData = new FormData()
      userInput.document.forEach(element => {
        formData.append("image", element);
      });
      apiService.addStoreImage(formData, token).then(result => {
        if (result.data?.status_code == 1) {
        } else {
        }
      })
    } catch (e) {
      console.error('e', e);
    }
  }

  const checkFormCompletion = () => {
    if ((isStoreName || storeName.length > 2) && isEmail && (isPincode || pincode.length > 2) && (isState || state.length > 2) && (isCity || selectedCity.length > 2) && (isAddress || addressLine.length > 2) && (isPhone || store_phone_number.length > 2)) {
      setisAllAdded(true);
    } else {
      setisAllAdded(false);
    }
  };

  useEffect(() => {
    checkFormCompletion()
  }, [isStoreName, isPincode, isState, isCity, isAddress, isPhone, storeName, pincode, state, selectedCity, addressLine, store_phone_number, isEmail])

  function isFacebookUrl(url) {
    // Improved regex to match Facebook URL patterns more accurately
    const facebookUrlRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9.\-_]+(\/)?(\?.*)?$/;
    // Test if the URL matches the Facebook URL pattern
    return facebookUrlRegex.test(url);
  }

  const checkFacebookUrl = (e) => {
    setBtnLoader(false);
    setisFBURL(false)
    const url = e.target.value;
    setFbUrl(url);
    if (url && !isFacebookUrl(url)) {
      setError({ fbUrl: 'Please enter a valid Facebook URL' });
    } else {
      setisFBURL(true)
      setError({ fbUrl: '' });
    }
  };
  function isInstagramUrl(url) {
    // Improved regex to match Instagram URL patterns accurately
    const instagramUrlRegex = /^(https?:\/\/)?(www\.)?(instagram\.com|instagr\.am)\/[A-Za-z0-9._-]+(\/)?(\?.*)?$/;
    // Test if the URL matches the Instagram URL pattern
    return instagramUrlRegex.test(url);
  }
  
  const checkInstagramUrl = (e) => {
    const url = e.target.value;
    setInstagramUrl(url);
    setisINSTAURL(false)
    if (url && !isInstagramUrl(url)) {
      setError({ instagramUrl: 'Please enter a valid Instagram URL' });
    } else {
      setisINSTAURL(true)
      setError({ instagramUrl: '' });
    }
  };

  let { documentErr } = userInput

  const handleChangeEmail = e => {
    setisEmail(false);
    localStorage.removeItem("isVarifiedEmail");
    setBtnLoader(false);
    setUserInput({ ...userInput, [e.target.name]: e.target.value });

    let email = e.target.value;
    if ((email == undefined) || (email == '')) {
      setError({ email: 'Email is required' });
    }

    else if (!validateEmail(email).status) {
      setError({ email: 'Please enter valid email' });
    }
    else {
      setisEmail(true);
      setError({ email: '' })
    }
  };

  const handleValidateEmail = () => {
    let { email } = userInput;
    let validate = true;
    setError({});

    if ((email == undefined) || (email == '')) {
      setError({ email: 'Email is required' });
      validate = false
    }

    else if (!validateEmail(email).status) {
      setError({ email: 'Please enter valid email' });
      validate = false
    }
    else {
      setisEmail(true);
      validate = true
      setError({ email: '' })
    }

    return validate;
  }


  const checkEmailVerify = () => {
    console.log("propsssssssssssssssssssss", props)
    try {
      if (handleValidateEmail() == true) {
        let req = {
          email_address: userInput.email,
          is_for_forgot_password: 0
        }
        setUserInput({ isLoader: true })
        apiService.sendOtpForEmail(req, token).then(result => {
          if (result.data?.status_code == 0) {
            setError({ email: result?.data?.error.message.error });
            setModalShow(false)
          }
          else if (result.data?.response_data["number_verified"] === false) {
            setError({ errorMsg: '' });
            let { firstName, lastName, email, password } = userInput;
            localStorage.setItem('first_name', firstName);
            localStorage.setItem('last_name', lastName);
            localStorage.setItem('email_address', email);
            localStorage.setItem('phone_number', mobile);
            localStorage.setItem('country_code', countryCode);
            // setModalShow(true);
            props.onlyClose()
            props.onlyOpenVerify()
          } else if (result.data?.response_data["number_verified"] === true) {
            setError({ errorMsg: '' });
            let { firstName, lastName, email } = userInput;
            localStorage.setItem('first_name', firstName);
            localStorage.setItem('last_name', lastName);
            localStorage.setItem('email_address', email);
            localStorage.setItem('phone_number', mobile);
            localStorage.setItem('country_code', countryCode);
            localStorage.setItem('isVarified', true);
          } else {
            setError({ errorMsg: result?.data?.error.message.error });
          }
        })
      }
    } catch (e) {
      console.error('e', e);
    }
  }

  

  function onlyClose(){
    props.onlyClose()
    localStorage.setItem('isVarifiedEmail',true);
  }
  
  return (
    <Modal
      {...props}
      size="lg"
      className='cstm_modal_edit_product ps-0 __store_body'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className='close_icon _add_product' onClick={onlyClose}><span><IoCloseCircle /></span></div>
        <span className='schedl_action avenir_black'>
          Edit Store Information
        </span>
        {upImg ?
          <>
            <div className='crop_img'>
              <div className="text-center mb-3 new_crop_btn">
                {upImg ?
                  <Button name={"Crop"} className="done_btn" onClick={handleFileUpload} />

                  : ''}
              </div>
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={completedCrop}
              />
              {/* <div className="text-center mb-3">
                  {upImg ?
                    <Button name={"Done"} className="done_btn" onClick={handleFileUpload} />

                    : ''}
                </div> */}
              {/* <span className="upload_store_logo avenir_heavy">Upload Store Logo</span> */}
            </div>

          </> :
          <>
            <div>
              <div className='upld_img'>
                <label>
                  {(getStoreImage || newUrl.length) ?
                    <img src={getStoreImage ? getStoreImage : newUrl} className='selected_img' />
                    :
                    <img src='assets/photo_upld.svg' />
                  }
                  <input type="file" size="60" name="document" id="file-input" ref={inputRef} onChange={onSelectFile} accept="image/x-png,image/jpeg" />

                  {/* {(getStoreImage || newUrl.length) ?
                 <span className='pls_icn'>+</span>
                :
                <EditIcon/>
              } */}
                  <span className='auction_pls_icn avenir_medium _edit' title="160*160"><FaPencil /></span>

                </label>
                {
                  (getStoreImage || (userInput.img.length >= 1)) ?
                    <></>
                    :
                    (
                      (isAgreed == true) ?
                        <span className="upload_store_logo_danger avenir_heavy">Upload Store Logo</span>
                        :

                        <span className="upload_store_logo avenir_heavy"></span>
                    )
                }
              </div>

              <span className="upload_store_logo_danger avenir_heavy">{documentErr}</span>
            </div>
            <div className='mt-4'>
              <Input
                name="storeName"
                placeholder="Store Name"
                className="stor_inpt_txt"
                maxLength={150}
                value={userInput.storeName}
                onChange={handleChange}
              />
              <div className='store_error'>
                <InlineError errorMessage={error.storeName} />
              </div>
            </div>
            <div className='mt-4'>
              <textarea rows={1}
                ref={textareaRef}
                name="storeDescription"
                placeholder="Store Description"
                className="stor_inpt_txt common_input_field avenir_medium"
                maxLength={450}
                value={userInput.storeDescription}
                onChange={handleChange}
                style={{ overflow: 'hidden', resize: 'none' }}
              ></textarea>
            </div>
            <div className='store_error'>
              <InlineError errorMessage={error.storeDescription} />
            </div>

            <div className="wrap_option wrap_inpt mt-4 position_relative">
              <span className='county_code'>🇮🇳 +91</span>
              <Input
                type="text"
                pattern="[0-9]*"
                name="phoneNumber"
                className="number_inpt_store number_inpt"
                maxLength={10}
                placeholder="Store Contact"
                value={mobile}
                step="any"
                onChange={handleInput}
              />
            </div>
            <div className='left_align_error'>
              <InlineError errorMessage={error.mobile} />
            </div>
            <div className='wrap_inpt position_relative'>
              <Input
                type="text"
                name="email"
                placeholder="Email ID"
                maxLength={50}
                minLength={10}
                value={userInput.email}
                onChange={handleChangeEmail}
              />
              {
                (isVarifiedEmail == 'true') ?
                  <span className='phn_verify'><img src='assets/check.svg' /></span>
                  :
                  (isEmail) ?
                    <span className='verify_phn_no cursor_pointer' onClick={checkEmailVerify}  >Verify</span>
                    :
                    <span className='verify_phn_no cursor_pointer' ></span>
              }
            </div>
            <div className='left_align_error'>
              <InlineError errorMessage={error.email} />
            </div>
            {/* <div className='store_error'>
          <InlineError errorMessage={error.mobile} />
        </div> */}
            <div className='mt-4'>
              <div className="social-input">
                <Input type="text" className="stor_inpt_txt" placeholder="Facebook URL (https://www.facebook.com/username)" value={fbUrl} onChange={checkFacebookUrl} />
                <FaFacebook className="social-icon" />
              </div>
            </div>
            <div className='store_error'>
              <InlineError errorMessage={error.fbUrl} />
            </div>
            <div className='mt-4'>
              <div className="social-input">
                <Input type="text" className="stor_inpt_txt" placeholder="Instagram URL (https://www.instagram.com/username)" value={instagramUrl} onChange={checkInstagramUrl} />
                <FaInstagram className="social-icon" />
              </div>
            </div>
            <div className='store_error'>
              <InlineError errorMessage={error.instagramUrl} />
            </div>
            <div className='text-center'>
              <Button
                className="use_location mb-4 mt-4"
                name={'Use Current Location'}
                onClick={getCurrentLocation}
              />
            </div>
            <div className='or_line mt-2'></div>
            <span className='or_txt'>Or</span>
            <div>
              <Input
                name="addressLine"
                value={userInput.addressLine}
                placeholder="Store Physical Location"
                className="stor_inpt_txt mt-4"
                maxLength={250}
                onChange={handleChange}
              />
            </div>
            <div className='store_error'>
              <InlineError className='store_error' errorMessage={error.addressLine} />
            </div>
            <div className='wrp_state'>
              <div>
                <select
                  name="state"
                  className='state_inpt'
                  onChange={(e) => selectStateHandler(e)}
                  value={userInput.state}
                  onFocus={(e) => e.target.style.color = "black"}  // Change to black on focus
                  style={{ color: userInput.state ? "black" : "#757575" }}
                >
                  <option hidden>State</option>
                  {statesAndCities.map((item, id) => (
                    <option
                      key={item.name}
                      value={item.name}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                <InlineError errorMessage={error.state} />
              </div>
              <div>
                <select
                  name="city"
                  className='state_inpt'
                  onChange={(e) => selectCityHandler(e)}
                  value={userInput.city}
                  onFocus={(e) => e.target.style.color = "black"}  // Change to black on focus
                  style={{ color: userInput.city ? "black" : "#757575" }}
                >
                  <option hidden value="">City</option>
                  {cityList.map((item, id) => (
                    <option
                      key={item.name}
                      value={item.name}

                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                <InlineError errorMessage={error.city} />
              </div>
            </div>
            <div className='inline_error_state'>
              {/* <div className='name_width'>
               
              </div> */}
              {/* <div className='name_width'>
                <InlineError errorMessage={error.city} />
              </div> */}
            </div>
            <div>
              <Input placeholder="Pincode" name="pincode" value={userInput.pincode} maxLength={6}
                className="stor_inpt_txt mt-4" onChange={handleChange} />
            </div>
            <div className='store_error'>
              <InlineError errorMessage={error.pincode} />
            </div>
            <div className='wrp_chekbox_store'>
              <input
                type="checkbox"
                className="checkbox_store"
                id="gst_number"
                checked={isAgreed}
                onChange={handleAgreeChange}
              />
              <label htmlFor="gst_number">Do you have GST Number?</label>
            </div>
            {
              ((isAgreed == true)) ?
                ((userInput.storeName) && (getStoreImage || (userInput.img.length >= 1)) ?
                  <>
                    <div className='mt-4'>
                      <p className="avenir_heavy mb-0">GST Registration Date</p>
                      <Input
                        className="stor_inpt_txt"
                        name="registrationDate"
                        maxLength={15}
                        minLength={15}
                        type="date"
                        value={userInput.registrationDate}
                        placeholder="GST Registration Date "
                        onChange={handleRegistrationDate}
                      />
                    </div>
                    <div className='store_error'>
                      <InlineError errorMessage={error.registrationDate} />
                    </div>
                    <div className='mt-4'>
                      <div>
                        <Input
                          className="stor_inpt_txt mt-4" type="text" onChange={handleChangeGST}
                          name="gstNumber"
                          maxLength={15}
                          minLength={15}
                          value={userInput.gstNumber}
                          placeholder="GST Number"
                        />
                      </div>
                      <div className='verify_gst_no'>
                        {
                          ((userInput.gstNumber.length == 15) && (isGSTVerify == false)) ?
                            <>{inputLoader ? <Spinner animation="border" variant="secondary" /> : <span onClick={checkGSTVerification} className='lower_case'>Verify</span>}</>
                            :
                            (isGSTVerify == true) ?
                              <span> {inputLoader ? <Spinner animation="border" variant="secondary" /> : <img src='assets/check.svg' />}</span>
                              :
                              <span>
                              </span>
                        }

                      </div>
                    </div>
                  </>
                  :
                  <>
                    {/* <p className="text-danger">Please ensure store logo, name, contact and description are filled</p> */}
                  </>
                )
                :
                <>
                </>
            }
            <div className='store_error'>
              <InlineError errorMessage={error.gstNumber} />
            </div>
            {console.log("eeeeeeeeeeeeeeeeeeeeeeeeeee", isStoreName, isAddress, isState, isCity, isAddress, (isCity || isPhone || isAddress || isPincode || isImg || isINSTAURL || isFBURL || isDescription || isStoreName || isEmail), isStoreName, isEmail, !((isAgreed === false || isGSTVerify === true) && isStoreName && isEmail && (getStoreImage || userInput.img.length >= 1)))}
            <div className="text-center">
            <Button
                    className="mt-4"
                    name={btnLoader ? <BtnLoader /> : "Continue"}
                    onClick={addStoreInformation}
                  // disabled={!isAddress &&  !isStoreName && !((isAgreed === false ||  isGSTVerify === true) && isCity && (isState  || isPhone  || isPincode || isImg || isINSTAURL || isFBURL || isDescription  ||  isEmail) && (getStoreImage || userInput.img.length >= 1))}
                  />
              {/* {
                (isAddress && isStoreName && isCity && isState && isPhone && isPincode && isImg && isDescription && isEmail && isINSTAURL && isFBURL && ((isAgreed === false || isGSTVerify === true)))
                  ?
                  <Button
                    className="mt-4"
                    name={btnLoader ? <BtnLoader /> : "Continue"}
                    onClick={addStoreInformation}
                  // disabled={!isAddress &&  !isStoreName && !((isAgreed === false ||  isGSTVerify === true) && isCity && (isState  || isPhone  || isPincode || isImg || isINSTAURL || isFBURL || isDescription  ||  isEmail) && (getStoreImage || userInput.img.length >= 1))}
                  />
                  :
                  <Button
                    className="mt-4"
                    name={btnLoader ? <BtnLoader /> : "Continue"}
                    // onClick={addStoreInformation}
                    disabled
                  />
              } */}

            </div>
          </>
        }
      </Modal.Body>
    </Modal>
  );
}

export default EditStore;

import React from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { CancelSvgIcon, Status1, Status11, Status2, Status22, Status3, Status33, Status4, Status44, Status5, Status55 } from '../../CommonComponent/ImageConstants';
import { RxCross2 } from "react-icons/rx";

function TrackingStatus({ trackingStatusCode, statusCode, orderStatusCanceled }) {
    return (
        <>
            <div className='_status show_desktop'>
                <div className="delivery-status">
                    <div className="step">
                        <div className={`circle  ${trackingStatusCode.includes(1) ? "success" : ''}`}>{trackingStatusCode.includes(1) && <BsCheckLg />}</div>
                        <div className="content">
                            Order Placed
                        </div>
                    </div>
                    <div className="step">
                        <div className={`circle ${trackingStatusCode.includes(2) ? "success" : ''}`}>{trackingStatusCode.includes(2) && <BsCheckLg />}</div>
                        <div className="content">
                            In Progress
                        </div>
                    </div>
                    <div className="step">
                        <div className={`circle ${trackingStatusCode.includes(3) ? "success" : ''}`}>{trackingStatusCode.includes(3) && <BsCheckLg />}</div>
                        <div className="content">
                            Shipment
                        </div>
                    </div>
                    <div className="step">
                        <div className={`circle ${trackingStatusCode.includes(4) ? "success" : ''}`}>{trackingStatusCode.includes(4) && <BsCheckLg />}</div>
                        <div className="content">
                            Out for delivery
                        </div>
                    </div>
                    <div className="step">
                        <div className={`circle ${trackingStatusCode.includes(5) ? "success" : ''}`}>{trackingStatusCode.includes(5) && <BsCheckLg />}</div>
                        <div className="content">
                            Delivered
                        </div>
                    </div>
                    {orderStatusCanceled &&
                        <div className="step">
                            <div className={`circle success danger`}><RxCross2 /></div>
                            <div className="content">
                                Cancelled
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='_order_status show_mobile'>
                <div className="_status">
                    <div className="delivery-status">
                        <div className="step">
                            <div className="circle success">
                                <img src={`${trackingStatusCode.includes(1) ? Status1 : Status11}`} alt="status" />
                            </div>
                            {statusCode == 1 && <div className="content">Order Placed</div>}
                        </div>
                        <div className="step">
                            <div className="circle success">
                                <img src={`${trackingStatusCode.includes(2) ? Status2 : Status22}`} alt="status" />
                            </div>
                            {statusCode == 2 && <div className="content">In Progress</div>}
                        </div>
                        <div className="step">
                            <div className="circle success">
                                <img src={`${trackingStatusCode.includes(3) ? Status3 : Status33}`} alt="status" />
                            </div>
                            {statusCode == 3 && <div className="content">Shipment</div>}
                        </div>
                        <div className="step">
                            <div className="circle success">
                                <img src={`${trackingStatusCode.includes(4) ? Status4 : Status44}`} alt="status" />
                            </div>
                            {statusCode == 4 && <div className="content">Out for delivery</div>}
                        </div>
                        <div className="step">
                            <div className="circle success">
                                <img src={`${trackingStatusCode.includes(5) ? Status5 : Status55}`} alt="status" />
                            </div>
                            {statusCode == 5 && <div className="content">Delivered</div>}
                        </div>
                        {orderStatusCanceled &&
                            <div className="step">
                                <div className="circle success">
                                    <img src={CancelSvgIcon} alt="status" />
                                </div>
                                <div className="content">Cancelled</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrackingStatus
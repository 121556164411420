import React, { useEffect, useReducer, useState } from 'react';
import { FaPhone, FaUser } from 'react-icons/fa';
import { FiEdit } from "react-icons/fi";
import { IoMdMail } from 'react-icons/io';
import { apiService } from '../../Services/ApiServices';
import BaseLoader from '../../CommonComponent/BaseLoader';
import EditPersonalDetails from './EditPersonalDetails';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import VerifyMobileNoModal from './VerifyMobileNoModal';

function PersonalDetails() {
  const logout = useLogout();
  const [addEditPersonalModal, setEditPersonalModal] = useState(false);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      user: {}
    }
  );
  const [verifyMobileNoModal, setVerifyMobileNoModal] = useState(false);
  const token = localStorage.getItem('token');

  let mobile = localStorage.getItem('mobile') ?  localStorage.getItem('mobile') : null;
  const [getmobile, setmobile] = useState(mobile);
  const [isFetched, setIsFetched] = useState(false); // Flag for tracking

  const [isVarified, setVarifiedValue] = useState(false); // Flag for tracking

  const UserDetails = () => {
    setUserInput({ isLoader: true });
    apiService.getUserDetails(token).then(res => {
      if (res?.data?.status_code === 1) {
        setIsFetched(true)
        if(res?.data?.response_data?.user?.user_name != null)
          setVarifiedValue(true)

        setUserInput({ user: res?.data?.response_data.user, isLoader: false });
      } else {
        if (res?.data?.status_code != 1) {
          notify("err", res?.data?.error?.message?.error);
          if(res?.data?.status_code == 2)
            logout();
          if (res?.data?.status_code == 5)
            logout();
          if (res?.data?.status_code == 4)
            logout();
        }
      }
    }).catch(() => {
      setUserInput({ isLoader: false });
    });
  };

  useEffect(() => {
    if(!isFetched)
    UserDetails();
  }, [isFetched]);

//   const onlyClose = () => {
//     setModalEditProduct(false)
// }

const [isMobileVarifiedState, setisMobileVarifiedState] = useState(true);

function isMobileVerfy(state){
  setisMobileVarifiedState(state)
}

function varifyUsername(state){
  setVarifiedValue(state)
}

  return (
    <div className='dashboard_inner'>
      <div className='text-end mt-5 mb-4 me-2'>
        <button className="btn btn_edit lower_case common_button avenir_heavy" onClick={() => setEditPersonalModal(true)}>
          Edit<span className='ms-2'><FiEdit /></span></button>
      </div>
      {userInput.isLoader ? <BaseLoader />
        : (
          <div className='_prsonal_detail_section'>
            <p><span className='_icon'><FaUser /></span><span className='orange'>{userInput.user.first_name + " " + userInput.user.last_name}</span></p>
            <p><span className='_icon'><FaUser /></span>{userInput.user.user_name ? userInput.user.user_name : "N/A"}</p>
            <p><span className='_icon phone_rotate'><FaPhone /> </span>{userInput.user.country_code + " " + userInput.user.phone_number}</p>
            {/* <p><span className='_icon'><IoMdMail /></span>{userInput.user.email_address}</p> */}
          </div>
        )
      }
      {addEditPersonalModal && (
        <EditPersonalDetails
          show={addEditPersonalModal}
          onlyClose={() => setEditPersonalModal(false)}
          onlyCloseVerify={() => setVerifyMobileNoModal(false)}
          onlyOpenVerify={() => setVerifyMobileNoModal(true)}
          user={userInput.user}
          updateUserDetails={UserDetails}
          getmobile={getmobile}
          isMobileVarifiedState ={isMobileVarifiedState}
          mobileVerfiedTrue ={() => setisMobileVarifiedState(true)}
          mobileVerfiedFalse ={() => setisMobileVarifiedState(false)}
          isVarified={isVarified}
          varifyUsername={varifyUsername}
        />
      )}

      {verifyMobileNoModal && (
        <VerifyMobileNoModal
          show={verifyMobileNoModal}
          onlyClose={() => setEditPersonalModal(false)}
          onlyOpen={() => setEditPersonalModal(true)}
          onlyCloseVerify={() => setVerifyMobileNoModal(false)}
          onlyOpenVerify={() => setVerifyMobileNoModal(true)}
          user={userInput.user}
          updateUserDetails={UserDetails}
          mobileVerfiedTrue ={() => setisMobileVarifiedState(true)}
          mobileVerfiedFalse ={() => setisMobileVarifiedState(false)}
        />
      )}
    </div>
  );
}

export default PersonalDetails;

import React from 'react';
import Lottie from 'react-lottie';
import Modal from 'react-bootstrap/Modal';
import OkAnimatiom from "../../Utils/OkAnimation.json"
import Button from '../../CommonComponent/Button';
import { useNavigate } from 'react-router';

function SignInModal(props) {
    const navigate = useNavigate()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: OkAnimatiom,
        renderer: 'svg'
    }
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                className='cstm_modal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div>
                        <Lottie
                            options={defaultOptions}
                            height={250}
                            width={250}
                        />
                    </div>
                    <p className='password_txt'>Your Password Changed Successfully</p>
                    <Button name={'Sign In'} onClick={() => navigate("/")} />
                </Modal.Body>
            </Modal>


        </div>
    )
}

export default SignInModal
import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { IoCloseCircle } from 'react-icons/io5';
import { BsCameraVideoFill } from "react-icons/bs";
import Button from '../../CommonComponent/Button';
import CameraIcon from '../../assets/camera.svg';
import UnMute from '../../assets/un-mute.svg';
import * as Chime from 'amazon-chime-sdk-js';
import { DefaultModality } from 'amazon-chime-sdk-js';
import { MuteIcon } from '../../Svg'
import { useNavigate } from 'react-router';
import { apiService } from '../../Services/ApiServices';
import AudioBar from './AudioBar';
import Loader from '../../CommonComponent/Loader'; // Assuming you have a Loader component


function SetUpPopup(props) {
    var socketload = localStorage.getItem('socketload') == "true" ? true : false;
    const navigate = useNavigate();
    const [mute, setMute] = useState(false);
    const { meeting, attendee } = props?.meetingSES;
    var meetingSession;
    let defaultVideo = props?.item?.is_camera_setup ? props?.item?.is_camera_setup.toString() : null;
    let defaultAudio = props?.item?.is_audio_setup ? props?.item?.is_audio_setup.toString() : null;
    const [getMeetingSession, setMeetingSession] = useState(null);
    const [getVideoDevices, setVideoDevices] = useState(null);
    const [audioInputDevices, setAudioInputDevices] = useState(null);
    let [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
    const [getTileId, setTileId] = useState(null);
    const [volume, setVolume] = useState(0); // State to store volume data
    const [streamKey, setStreamKey] = useState(null);
    const [streamUrl, setStreamUrl] = useState(null);
    let defaultStreamKey = props?.streamObj?.streamKey ? props?.streamObj?.streamKey : null;
    let defaultStreamUrl = props?.streamObj?.streamUrl ? props?.streamObj?.streamUrl : null;
    const [loading, setLoading] = useState(false); // New loading state

    const handleVideoDeviceChange = async (event) => {
        const selectedDevice = event?.target?.value;
        setSelectedVideoDevice(selectedDevice);
    
        if (selectedDevice === "Select a video device") {
            // Stop video input when no valid video device is selected
            try {
                // setLoading(true);
                getMeetingSession.audioVideo.stopLocalVideoTile();
                await getMeetingSession.audioVideo.stopVideoInput();
                console.log("Video input stopped successfully.");
            } catch (error) {
                setLoading(false);
                console.error('Error stopping video input:', error);
            }
            return; // Exit early
        }
    
        try {
            setLoading(true);
            // Restart video input with the selected device
            await getMeetingSession.audioVideo.startVideoInput(selectedDevice);
    
            // Bind video to an element
            const video_element = document.getElementById("videoId_123");
            setLoading(false);
            if (video_element) {
                // If video tile already exists, stop and restart it to bind correctly
                const tileId = getMeetingSession.audioVideo.hasStartedLocalVideoTile() ? getMeetingSession.audioVideo.getLocalVideoTile().state().tileId : null;
                
                // If there is an existing tile, unbind and rebind the element
                if (tileId) {
                    getMeetingSession.audioVideo.unbindVideoElement(tileId);
                    getMeetingSession.audioVideo.bindVideoElement(tileId, video_element);
                    setLoading(false);
                } else {
                    // Start a new local video tile if not already started
                    getMeetingSession.audioVideo.startLocalVideoTile();
                    setLoading(false);
                }
            }
            console.log("Video input started successfully.");
        } catch (error) {
            setLoading(false);
            console.error('Error changing camera:', error);
            if (error.name === 'OverconstrainedError') {
                // Relax constraints and try again
                console.log('Retrying with relaxed constraints...');
                try {
                    await getMeetingSession.audioVideo.startVideoInput();
                } catch (innerError) {
                    console.error('Failed to start video input:', innerError);
                }
            }
        }
    };
    
    
    const handleAudioDeviceChange = async (event) => {
        const selectedDevice = event?.target?.value;
        setSelectedAudioDevice(selectedDevice);
    
        if (selectedDevice === "Select an audio device") {
            // Stop audio input when no valid audio device is selected
            try {
                await getMeetingSession.audioVideo.stopAudioInput();
                console.log("Audio input stopped successfully.");
            } catch (error) {
                console.error('Error stopping audio input:', error);
            }
            return; // Exit early
        }
    
        try {
            // Restart audio input with the selected device
            await getMeetingSession.audioVideo.startAudioInput(selectedDevice);
    
            // Bind the audio element if necessary
            const audio_element = document.getElementById("meeting-audio");
            if (audio_element) {
                // Bind the audio element to the meeting session to start audio playback
                await getMeetingSession.audioVideo.bindAudioElement(audio_element);
            }
            console.log("Audio input started successfully.");
        } catch (error) {
            console.error('Error changing audio device:', error);
        }
    };
    
    useEffect(() => {
        if (!selectedVideoDevice) {
            setSelectedVideoDevice(defaultVideo);
        } else {

        }
    }, [defaultVideo, selectedVideoDevice]);


    useEffect(() => {
        if (!selectedAudioDevice) {
            setSelectedAudioDevice(defaultAudio);
        } else {

        }
    }, [defaultAudio, selectedAudioDevice]);

    useEffect(() => {
        if (!streamKey) {
            setStreamKey(defaultStreamKey)
        } else {

        }
    }, [defaultStreamKey, streamKey]);

    useEffect(() => {
        if (!streamUrl) {
            setStreamUrl(defaultStreamUrl)
        } else {

        }
    }, [defaultStreamUrl, streamUrl]);

    const joinVideoCall = async () => {
        if (meeting) {
            setLoading(true);
            const logger = new Chime.ConsoleLogger();
            const deviceController = new Chime.DefaultDeviceController(logger);
            const configuration = new Chime.MeetingSessionConfiguration(meeting, attendee);
            meetingSession = new Chime.DefaultMeetingSession(configuration, logger, deviceController);
            // Get list of available video input devices
            const videoInputDevices = await meetingSession.audioVideo.listVideoInputDevices();
            setVideoDevices(videoInputDevices)
            const firstVideoDeviceId = selectedVideoDevice ? selectedVideoDevice : videoInputDevices[0].deviceId;
            if(!selectedVideoDevice)
            setSelectedVideoDevice(firstVideoDeviceId);
            // Choose the first available video input device
            meetingSession.audioVideo.startVideoInput(firstVideoDeviceId);
            // selectedVideoDevice(event?.target?.value);
            meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 15, 1400);
            const audioOutputDevices = await meetingSession?.audioVideo?.listAudioOutputDevices();
            await meetingSession.audioVideo.chooseAudioOutput(audioOutputDevices[0]?.deviceId);
     
            const video_element = document.getElementById("videoId_123");
            const audio_element = document.getElementById("meeting-audio");
            const audioInputDevices = await meetingSession.audioVideo.listAudioInputDevices();
            setAudioInputDevices(audioInputDevices);
            const firstAudioDeviceId = selectedAudioDevice ? selectedAudioDevice : audioInputDevices[0].deviceId;
            if(!selectedAudioDevice)
                setSelectedAudioDevice(firstAudioDeviceId);
            await meetingSession.audioVideo.startAudioInput(firstAudioDeviceId);
            await meetingSession.audioVideo.bindAudioElement(audio_element);
            const observer = {
                audioVideoDidStart: () => {
                    setLoading(false);
                    meetingSession.audioVideo.startLocalVideoTile();
                },
                videoTileDidUpdate: tileState => {
                    if (tileState.localTile) {
                        setTileId(tileState?.tileId);
                        meetingSession.audioVideo.bindVideoElement(tileState?.tileId, video_element);
                    }
                },
                audioVideoDidStop: sessionStatus => {
                    if ((sessionStatus && sessionStatus?.statusCode() !== Chime.SessionStoppedErrorCode?.NetworkFailure) && (Chime.SessionStoppedErrorCode?.NetworkFailure != undefined)) {
                        meetingSession.audioVideo.stop();
                        meetingSession.audioVideo.start();
                    } else {
                        console.error("Session stopped due to network failure.");
                    }
                },
                audioVideoDidStartConnecting: reconnecting => {
                    if (reconnecting) {
                        meetingSession.audioVideo.start();
                    }
                }
            }
            meetingSession.audioVideo.addObserver(observer);
            // Check if any devices are available
            if (videoInputDevices.length > 0) {
                try {
                    meetingSession.audioVideo.startLocalVideoTile();
                    // Start the meeting session
                    setMeetingSession(meetingSession);
                    meetingSession.audioVideo.start();
                } catch (e) {
                    console.error('e___________________________', e);
                }
            } else {
                console.error("No video input devices found.");
                // Handle error scenario where no video input devices are available
            }
        }
    }
    useEffect(() => {
        if (meeting)
            joinVideoCall()
    }, [meeting]);


    useEffect(() => {
        if (getMeetingSession) {
            const presentAttendeeId = getMeetingSession.configuration.credentials.attendeeId;
            const volumeCallback = (attendeeId, volume, muted, signalStrength) => {
                const baseAttendeeId = new DefaultModality(attendeeId).base();
                setVolume(volume);
                console.log(`The volume of ${baseAttendeeId}'s content changes`);
                console.log(`${attendeeId}'s volume data: `, {
                    volume,
                    muted,
                    signalStrength,
                });
            };
            getMeetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, volumeCallback);
            // Return cleanup function to unsubscribe from volume indicator when the component unmounts or meeting ends
            return () => {
                getMeetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId, volumeCallback);
            };
        }
    }, [getMeetingSession]);

    const muteUnmute = (action) => {
        setMute(action);
        if (getMeetingSession) {
            if (action == true) {
                getMeetingSession.audioVideo.realtimeMuteLocalAudio();
            } else {
                getMeetingSession.audioVideo.realtimeUnmuteLocalAudio();
            }
        }
    }

    const setupMeetingSessionAwsChime = async () => {
        if (getMeetingSession) {
            try {
                let updateSetup = {};
                updateSetup.auction_id = props.id;
                if (selectedVideoDevice && selectedVideoDevice != "Select a video device")
                    updateSetup.is_camera_setup = selectedVideoDevice;
                else
                    updateSetup.is_camera_setup = defaultVideo;

                if (selectedAudioDevice && selectedAudioDevice != "Select an audio device")
                    updateSetup.is_audio_setup = selectedAudioDevice;
                else
                    updateSetup.is_audio_setup = defaultAudio;

                if (streamKey)
                    updateSetup.streamKey = streamKey;
                else
                    updateSetup.streamKey = props?.item?.streamKey;

                if (streamUrl)
                    updateSetup.streamUrl = streamUrl;
                else
                    updateSetup.streamUrl = props?.item?.streamUrl;

                const token = localStorage.getItem('token');
                let response = await apiService.auctionSetup(updateSetup, token);
                if (response?.data?.status_code == 1) {
                    setAudioMettingStop();
                    socketReload()
                }
            } catch (error) {
                console.error('Error stopping meeting session:', error);
            }
        }
    }

    function setAudioMettingStop() {
        getMeetingSession.audioVideo.stopLocalVideoTile();
        getMeetingSession.audioVideo.stopVideoInput();
        getMeetingSession.audioVideo.stopAudioInput()
        getMeetingSession.audioVideo.chooseAudioOutput(null);
        getMeetingSession.audioVideo.startVideoInput(null);
        getMeetingSession.audioVideo.startVideoInput(null);
        getMeetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(null);
        getMeetingSession.audioVideo.stop();
        setMeetingSession(null);
        setVideoDevices(null);
        setSelectedVideoDevice(null);
        setSelectedAudioDevice(null);
        setTileId(null);
        localStorage.removeItem("isMeeting");
        navigate("/auction")
    }

    const cancelMeetingSessionAwsChime = () => {
        if (getMeetingSession) {
            try {
                setAudioMettingStop();
                socketReload();
            } catch (error) {
                console.error('Error stopping meeting session:', error);
            }
        }
    }

    function socketReload() {
        if ((socketload == "false") || (socketload == false)) {
            localStorage.setItem("socketload", true);
            window.location.reload();
        }
    }

    useEffect(() => {
    const setDefaultVideoDevice = async () => {
        // If no video device is selected but a default video is available
        if (!selectedVideoDevice && defaultVideo) {
            try {
                // Set the default video device as selected
                setSelectedVideoDevice(defaultVideo);

                // Start the video input with the default device
                await getMeetingSession.audioVideo.startVideoInput(defaultVideo);

                // Bind video to an element
                const video_element = document.getElementById("videoId_123");
                if (video_element) {
                    // Start the local video tile if not started
                    const tileId = getMeetingSession.audioVideo.hasStartedLocalVideoTile() ? getMeetingSession.audioVideo.getLocalVideoTile().state().tileId : null;

                    if (tileId) {
                        getMeetingSession.audioVideo.unbindVideoElement(tileId);
                        getMeetingSession.audioVideo.bindVideoElement(tileId, video_element);
                    } else {
                        getMeetingSession.audioVideo.startLocalVideoTile();
                    }
                }
                console.log("Default video device set and video input started successfully.");
            } catch (error) {
                if (error.name === 'OverconstrainedError') {
                    console.warn("OverconstrainedError detected. Retrying with default constraints...");
                    try {
                        // Retry with no constraints
                        await getMeetingSession.audioVideo.startVideoInput();
                    } catch (retryError) {
                        console.error("Failed to start video input after relaxing constraints:", retryError);
                    }
                } else {
                    console.error("Error setting default video device:", error);
                }
            }
        }
    };

    if (getMeetingSession) {
        setDefaultVideoDevice();
    }
}, [defaultVideo, getMeetingSession]);


    return (
        <div>
             {/* Show loader while loading is true */}
            <Modal
                {...props}
                size="xl"
                className='cstm_modal_product'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className='p-5 setup_popup_parent'>
                    <div className='close_icon' onClick={cancelMeetingSessionAwsChime}><span><IoCloseCircle /></span></div>

                
                    <div className='text-center'>
                        <h4> Welcome to the webinar room!</h4>
                        <p>Check your audio & video devices</p>
                    </div>
                    <div className='setup_popup'>
                        <div className='_left'>
                            <video id="videoId_123"></video>
                            <audio id="meeting-audio" style={{ "display": "none" }}></audio>
                            <div className="_bottom">
                                <span>
                                    <img src={CameraIcon} alt="icon" className="me-2" />
                                </span>
                                <span onClick={() => muteUnmute(!mute)}>
                                    {mute ? <img src={UnMute} alt="Unmute icon" /> : <MuteIcon />}
                                </span>
                            </div>
                            {loading && <Spinner className='lg _setup_leader'/>} 
                        </div>
                        <div className='_right'>
                            <div className="form-control">
                                <label><span className='_icon'><BsCameraVideoFill /></span>Video devices</label>
                                <select className="form-select" onChange={handleVideoDeviceChange} value={selectedVideoDevice}>
                                    <option >Select a video device</option>
                                    {getVideoDevices && getVideoDevices.map((device) => (
                                        <option key={device.deviceId} value={device.deviceId}>
                                            {device.label || `Device ${device.deviceId}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='audio_text_bar d-flex ' >
                                <div className="form-control">
                                    <label><span className='_icon'><BsCameraVideoFill /></span>Audio devices</label>
                                    <select className="form-select" onChange={handleAudioDeviceChange} value={selectedAudioDevice}>
                                        <option >Select an audio device</option>
                                        {audioInputDevices && audioInputDevices.map((item) => (
                                            <option key={item.deviceId} value={item.deviceId}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className='_audio_bar pt-2'>
                                    <AudioBar volume={volume} />
                                </div>
                            </div>
                            <Button name={'Setup Complete'} style={{ width: "auto" }} className="btn p-3 product_btn lower_case go_live_btn common_button avenir_heavy" onClick={(e) => setupMeetingSessionAwsChime(e)} />
                            <Button name={"Cancel"} className='p-3 product_btn lower_case ms-3' onClick={(e) => cancelMeetingSessionAwsChime(e)} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SetUpPopup
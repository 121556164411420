import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export function notify(text, msg) {
    if (text === "success") {
        toast.success(msg, {
            position: "top-right",
            allowHtml: true
        });
    }
    else if (text === "warning") {
        toast.warning(msg, {
            position: "top-right",
        });
    }
    else {
        toast.error(msg, {
            position: "top-right",
        });
    }
}
import React from 'react';

function Logo() {
    return (
        <div className=''>
            <a href='/'>
                <img src='assets/header-logo_white.svg' style={{ height: "56px" }} />
            </a>
        </div>
    )
}

export default Logo
import React from 'react'
import Spinner from "react-bootstrap/Spinner";

function BtnLoader() {
    return (
        <>
            <Spinner animation="border" variant="light" />
        </>
    )
}

export default BtnLoader
import React, { useState, useEffect, useReducer } from 'react';
import { FiEdit } from 'react-icons/fi';
import { apiService } from '../../Services/ApiServices';
import EditStore from './EditStore';
import BaseLoader from '../../CommonComponent/BaseLoader';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import Header from '../../CommonComponent/Header';
import VerifyEmailNoModal from './VerifyEmailNoModal';

function Store() {
  const logout = useLogout();
  const [addEditStoreModal, setEditStoreModal] = useState(false);
  const [storeDetails, setStoreDetails] = useState({});
  const token = localStorage.getItem('token');
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      user: {}
    }
  );
  const [verifyMobileNoModal, setVerifyMobileNoModal] = useState(false);
  const fetchData = async () => {
    try {
      setUserInput({ isLoader: true });
      // Fetch store details from API
      const response = await apiService.getStoreDetails(token); // Adjust this according to your API implementation
      // Assuming the API response contains store details in the following format
      // const response = { name: 'AS Store', gstNumber: 'NGGssssssPL19160', address: 'B-4/5, 2nd Floor, Mobcoder, Sector 63, Noida, Uttar Pradesh', pincode: '201301', mobile: '8130147994' };
      if (response?.data?.status_code != 1) {
        notify("err", response?.data?.error?.message?.error);
        if(response?.data?.status_code == 2)
          logout();

        if (response?.data?.status_code == 5)
          logout();
      }
      setStoreDetails(response.data?.response_data?.store_list);

      setUserInput({ isLoader: false });
    } catch (error) {
      console.error('Error fetching store details:', error);
    }
  };

  // Fetch store details when component mounts
  useEffect(() => {
    fetchData();
  }, []); // Run only once on component mount

  return (
    <div className='dashboard_inner'>
        {/* <Header onlylogo={false} /> */}
      <div className='text-end mt-5 mb-4 me-5 show_desktop'>
        <button className="btn btn_edit lower_case common_button avenir_heavy " onClick={() => setEditStoreModal(true)}>Edit<span className='ms-2'><FiEdit /></span></button>
      </div>
      {userInput.isLoader ? <BaseLoader />
        : (
          <div className='_store_section'>
            <span className='_edit show_mobile' onClick={() => setEditStoreModal(true)}><FiEdit /></span>
            <h3>{storeDetails.store_name} <span className='_light ms-4'>GST Number:</span> <span>{storeDetails.gst_number ? storeDetails.gst_number : "N/A"}</span></h3>
            <p className='_address'>{storeDetails.address_line ? storeDetails.address_line : "N/A"}</p>
            <p>{storeDetails.city ? storeDetails.city : "N/A"}{", " + storeDetails.state}</p>
            <p className='_mobile'>Mobile : {storeDetails.country_code + " " + storeDetails.phone_number}</p>
            <p className='_mobile'>Email : {storeDetails.email_address}</p>
          </div>
        )
      }

      {addEditStoreModal && (
        <EditStore
          show={addEditStoreModal}
          onlyClose={() => setEditStoreModal(false)}
          onlyOpen={() => setEditStoreModal(true)}
          storeDetails={storeDetails}
          fetchData={fetchData}
          onlyCloseVerify={() => setVerifyMobileNoModal(false)}
          onlyOpenVerify={() => setVerifyMobileNoModal(true)}
        />
      )}

      {verifyMobileNoModal && (
        <VerifyEmailNoModal
          show={verifyMobileNoModal}
          onlyClose={() => setEditStoreModal(false)}
          onlyOpen={() => setEditStoreModal(true)}
          onlyCloseVerify={() => setVerifyMobileNoModal(false)}
          onlyOpenVerify={() => setVerifyMobileNoModal(true)}
          // user={userInput.user}
          updateUserDetails={storeDetails}
        />
      )}
    </div>
  )
}

export default Store
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import Login from './Auth/Login/Login';
import Signup from './Auth/Signup/Signup';
import UserDetails from './Auth/Signup/UserDetails';
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import VerifyOtp from './Auth/VerifyOtp/VerifyOtp';
import ConfirmPassword from './Auth/ConfirmPassword/ConfirmPassword';
import Dashboard from './Dashboard/Dashboard/Dashboard';
import Auction from './Dashboard/Auction/Auction';
import Settings from "./Dashboard/Settings/Settings";
import Product from "./Dashboard/Product/Product";
import Orders from "./Dashboard/Orders/Orders";
import Live from "./Live/Live";
import VideoUrlPage from "./Live/VideoUrlPage";
import AccountDetails from "./Dashboard/AccountDetails/AccountDetails"
import Store from './Dashboard/Store/Store';
import PersonalDetails from './Dashboard/PersonalDetails.js/PersonalDetails';
import Categories from "./Dashboard/Categories/Categories"
import { useEffect, useState } from 'react';
import Sidebar from './CommonComponent/Sidebar';
import Header from './CommonComponent/Header';
import Footer from './CommonComponent/Footer';
import SidebarList from './CommonComponent/SidebarList';
import SinupProcess from './Auth/Signup/SinupProcess';
import VideoStream from "./Live/VideoStream";
import LandingPage from './home/LandingPage';
import OrdersDetails from './Dashboard/Orders/OrdersDetails';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy';
import AboutUs from './privacy-policy/AboutUs';
import TermsConditions from './privacy-policy/TermsConditions';
import CancellationPolicy from './privacy-policy/CancellationPolicy';
import ShippingDelivery from './privacy-policy/ShippingDelivery';
import ReturnsPolicy from './privacy-policy/ReturnsPolicy';
import { apiService } from './Services/ApiServices';
import { hideNotification } from './Component/Redux/Sliceses/notificationSlice';
import { useDispatch } from 'react-redux';

function App() {
  const pathName = useLocation();
  const dispatch = useDispatch();
  const [noticationList, setNoticationList] = useState([])
  const [getIsViewSeller, setIsViewSeller] = useState(false)
  const token = localStorage.getItem('token');
  const PrivateRoute = () => {
    const auth = localStorage.getItem("isLoggedIn");
    return auth != null ? <Outlet /> : <Navigate to="/" />;
  }

  const NotificationDetails = () => {
    try {
      apiService.notificationDetails(token).then(res => {
        if (res?.data?.status_code === 1) {
          setNoticationList(res?.data?.response_data?.notification_list);
          setIsViewSeller(res?.data?.response_data?.is_view_seller)
        } else {

        }
      })
    }
    catch (e) {
      console.error('e', e);
    }
  };
  
  useEffect(() => {
    NotificationDetails()
  }, [])

  const CloseNotification = () => {
    dispatch(hideNotification());
  }
  useEffect(() => {
    CloseNotification()
  }, [])

  return (
    <div >
      {
        // pathName.pathname === "/" ||
        pathName.pathname === "/dashboard" ||
          pathName.pathname === "/personal_details" ||
          pathName.pathname === "/auction" ||
          pathName.pathname === "/product" ||
          pathName.pathname.includes('/order-detail/') ||
          pathName.pathname === "/orders" ||
          pathName.pathname === "/store" ||
          pathName.pathname === "/account_details" ||
          pathName.pathname === "/categories" ||
          pathName.pathname === "/settings" ||
          pathName.pathname === "/more" ||
          pathName.pathname === "*" ? (

          <>
            <div className='dashboard'>
              <div className=''>
                <Header noticationList={noticationList} isViewSeller={getIsViewSeller} />
              </div>
              <div className='app_dashboard'>
                <div className="dashboard_sidebar">
                  <Sidebar noticationList={NotificationDetails} />
                </div>
                <div className='dashboard_container' onClick={CloseNotification} >
                  <Routes >
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/dashboard' element={<Dashboard />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/personal_details' element={<PersonalDetails />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/auction' element={<Auction />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/product' element={<Product />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/orders' element={<Orders />} />
                    </Route>
                    {/* <Route exact path='/' element={<PrivateRoute/>}> */}
                    <Route exact path='/order-detail/:id' element={<OrdersDetails />} />
                    {/* </Route> */}
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/store' element={<Store />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/account_details' element={<AccountDetails />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/categories' element={<Categories />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/settings' element={<Settings />} />
                    </Route>
                    <Route exact path='/' element={<PrivateRoute />}>
                      <Route exact path='/more' element={<SidebarList />} />
                    </Route>
                  </Routes>
                  <div className='footer'>
                    <Footer />

                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )
      }
      <Routes>
        <Route exact path='/' element={<LandingPage />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/signup' element={<Signup />} />
        <Route exact path='/reset_password' element={<ResetPassword />} />
        <Route exact path='/verify_otp' element={<VerifyOtp />} />
        <Route exact path='/confirm_password' element={<ConfirmPassword />} />
        <Route exact path='/signup-process' element={<UserDetails />} />
        <Route exact path='/live' element={<Live />} />
        <Route exact path='/live/:id' element={<Live />} />
        <Route path='/videostream/:id' element={<VideoStream />} />
        <Route path='/videourl/:id' element={<VideoUrlPage />} />
        <Route path="/signup_process" element={<SinupProcess />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/shipping-delivery" element={<ShippingDelivery />} />
        <Route path="/returns-policy" element={<ReturnsPolicy />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useRef, useState, useEffect } from 'react';
import userDummy from '../assets/user-profile.svg';
import { MdSend } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import socket from '../Socket/io';
import moment from 'moment';

function LiveComment(props) {
  const [prevMessagesList, setPrevMessagesList] = useState([]);
  const [allMessagesList, setAllMessagesList] = useState([]);
  const [roomId, setRoomID] = useState()
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const messagesRef = useRef(null);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let sendData = { "room_id": localStorage.getItem("room_id"), "message": inputValue };
    if (inputValue.trim() !== '') {
      socket.emit('send_message', sendData);
      setInputValue('');
    }
    setTimeout(() => { scrollToBottom() }, 1000)
  };

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
    else if (allMessagesList) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  const showDots = (productListValue) => {
    props.showProductList(true)
  }

  useEffect(() => {
    try {
      const handleUserJoined = async () => {
        socket.on('user_joined', (data) => {
          setRoomID(data?.room_id);
          localStorage.setItem("room_id", data?.room_id);
          setPrevMessagesList(data);
        })
      };
      handleUserJoined()
      return () => {
        socket.off('user_joined', handleUserJoined);
      };
    } catch (error) {
      console.log("error________________", error)
    }
  }, []);

  useEffect(() => {
    try {
      const handleMessageReceived = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
        if (message) { setTimeout(() => { scrollToBottom() }, 500) }
      };
      socket.on('message_received', handleMessageReceived);
      return () => {
        socket.off('message_received', handleMessageReceived);
      };
    } catch (error) {

    }
  }, []);


  useEffect(() => {
    try {
      const handlePreviousMessages = (data) => {
        setAllMessagesList(data);
        if (data) {
          setTimeout(() => { scrollToBottom() }, 500)
        }
      };
      socket.on('previous_messages', handlePreviousMessages);
      return () => {
        socket.off('previous_messages', handlePreviousMessages);
      };
    } catch (error) {

    }
  });

  useEffect(() => {
    const data = { "room_id": localStorage.getItem("room_id") }
    try {
      socket.emit('previous_messages', data);
    } catch (error) {

    }
  }, []);

  function getTimeFromDate(gmtTime) {
    const parsedTime = moment.utc(gmtTime);
    const localTime = parsedTime.local();
    let formattedLocalTime = localTime.format('HH:mm');
    if (formattedLocalTime == localTime) {
      return formattedLocalTime = 'Just Now'
    }
    else {
      return formattedLocalTime = formattedLocalTime
    }
  }

  return (
    <div className='_live_comment_section'>
      <div className='_header'>
        <h4 className='mb-0'>Live Comments</h4>
      </div>
      <div className='comments_section' ref={messagesRef}>

        {allMessagesList?.messages?.map((item, index) =>
        (
          <div className='_comment' key={index}>
            <div className='_user show_desktop'>
              <img src={item.image ? item.image : userDummy} />
            </div>
            <div className='_msz'>
              <h5 className='mb-0'>{item.name} <span className='_time'> {getTimeFromDate(item.time)} </span></h5>
              <p className='mb-0'>{item.message}</p>
            </div>
          </div>

        )
        )}
        {messages.map((item, index) =>
        (
          <div className='_comment' key={index}>
            <div className='_user show_desktop'>
              <img src={item.image ? item.image : userDummy} />
            </div>
            <div className='_msz'>
              <h5 className='mb-0'>{item.name} <span className='_time'> {getTimeFromDate(item.time)} </span></h5>
              <p className='mb-0'>{item.message}</p>
            </div>
          </div>

        )
        )}
      </div>
      <div className='d-flex align-items-center typing_prent'>
        <form onSubmit={handleSubmit}>
          <div className='_typing_box'>
            <input type="text" value={inputValue} onChange={handleInputChange} />
            <button type='submit' className='_send_btn'><MdSend /></button>
          </div>
        </form>
        <span className='show_mobile _three_dot' onClick={showDots}><BsThreeDotsVertical /></span>
      </div>

    </div>
  )
}

export default LiveComment

import React from 'react'
import { BlankImage } from './ImageConstants';

function BlankPage({ text1, text2, link, btnName, ClickFunction, hideButton }) {
  return (
    <div className='_blank_page'>
      <img src={BlankImage} alt='blank_page' className='img-fluid' />
      <div className='text my-5'>
        <p>{text1}</p>
        <p>{text2}</p>
      </div>
      {(hideButton == false || hideButton == undefined) &&
        <div className='d-grid text-center'>
          <button className='common_button m-auto lower_case' onClick={() => ClickFunction(true)}>{btnName}</button>
        </div>
      }
    </div>
  )
}

export default BlankPage
import React, { useEffect, useReducer, useState } from 'react';
import AccountCard from './AccountCard'
import BankAccountDetails from './BankAccountDetails';

function AccountDetails() {
  const [addBankAccountModal, setBankAccountModal] = useState(false);

  return (
    <div className='dashboard_inner'>
       <div className='text-end mt-5 mb-4 me-2'>
          <button className="btn lower_case common_button btn-auto" onClick={() => setBankAccountModal(true)}>Add New Bank Account</button>
        </div>
        <div className='_account_detail_section'>
           <AccountCard  className="active"/>
           <AccountCard default="no"/>
        </div>
        {addBankAccountModal && (
        <BankAccountDetails
          show={addBankAccountModal}
          onlyClose={() => setBankAccountModal(false)}
          // user={userInput.user}
          // updateUserDetails={UserDetails}
        />
      )}
    </div>
  )
}

export default AccountDetails
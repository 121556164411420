import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function TermsConditions() {
    const navigate = useNavigate();
    const goBack = () => { navigate(-1); };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <div className='privacy_policy_parent'>
        <div className='header'>
                <span onClick={goBack}><MdArrowBackIos /></span>
                <Link to="/">  <img src='assets/header-logo.svg' style={{ height: "46px" }} /></Link>
        </div>
        <div className='privacy_policy'>
        <h4>Seller terms and conditions: </h4> 
        <p><b>Content Disclaimer</b></p>
        <p>While bidsboxx makes reasonable efforts to ensure the accuracy and completeness of the information on our website, we do not guarantee its accuracy or usefulness. Information provided on this site is given in good faith and may include third-party content on an "as-is" basis. bidsboxx disclaims all liability for any issues arising from the use of this information. Content may change or be updated without prior notice. You use this information at your own risk and agree to waive any claims against bidsboxx related to its use. Copying, redistributing, or republishing the information without bidsboxx’s prior written consent is prohibited. The website may link to other sites that we do not control. bidsboxx is not responsible for the content or availability of these external sites.
        </p>
        
        <p><b>Disclaimer of Warranties and Limitation of Liabilities</b></p>
        <p>bidsboxx provides this website on an "as is" and "as available" basis. We make no warranties, express or implied, about the operation of this website or the information, content, materials, or products included. Your use of the website is at your own risk. bidsboxx reserves the right to remove or change any information at our discretion.
        To the fullest extent permitted by law, bidsboxx disclaims all warranties, including implied warranties of merchantability and fitness for a particular purpose. We do not guarantee that the website, its servers, or any communications from bidsboxx are free of viruses or harmful components. bidsboxx will not be liable for any damages arising from the use of this website, including direct, indirect, incidental, punitive, and consequential damages.
        </p>
        
        <p><b>Indemnity and Release</b></p>
        <p>You agree to indemnify and hold harmless bidsboxx, its affiliates, and their officers, directors, agents, and employees from any claims or demands, including reasonable legal fees, arising from your breach of these Terms or any applicable laws or regulations.
        </p>
        
        <p><b>Release of Liability</b></p>
        <p>You agree to release bidsboxx, its affiliates, and their officers and representatives from any costs, damages, liabilities, or other consequences related to your use of this website. You waive any claims you may have under any statute or contract.
        </p>
        
        <p><b>Sanctions and Export Policy</b></p>
        <p>You may not use this website if you are subject to U.S. sanctions or sanctions imposed by your country that are consistent with U.S. law. You must comply with all relevant export and re-export regulations concerning goods, software (including bidsboxx software), technology, and services.
        </p>
        
        <p><b>Governing Law and Jurisdiction</b></p>
        <p>These Terms are governed by the laws of India. You agree to submit to the exclusive jurisdiction of the courts in New Delhi.
        </p>
        
        <p><b>Site Policies, Modification, and Severability</b></p>
        <p>bidsboxx reserves the right to change the website, policies, and these Terms at any time. You will be subject to the Terms in effect when you use the website. If any part of these Terms is found to be invalid or unenforceable, that part will be severed, and the rest of the Terms will remain in effect.
        </p>
        
        <p><b>Seller Registration</b></p>
        <p>If you register as a seller on www.bidsboxx.in ("Marketplace"), your activities will be governed by the Marketplace Conditions of Use and any relevant policies.
        </p>
        
        <p><b>Events Beyond Our Control</b></p>
        <p>bidsboxx is not responsible for any delays or failures to meet our obligations under these Terms due to events beyond our reasonable control. This does not affect your statutory rights.
        </p>
        
        <p><b>Waiver</b></p>
        <p>If bidsboxx does not immediately enforce any part of these Terms, it does not waive our right to enforce those Terms in the future.
        </p>

       
        </div>
   </div>
  )
}

export default TermsConditions
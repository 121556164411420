import React from 'react';
import '../StyleComponent/landing.scss';
import BannerSection from './BannerSection';
import WhatProductSection from './WhatProductSection';
import ExperienceLiveAuction from './ExperienceLiveAuction';
import BidsBoxxAdvantage from './BidsBoxxAdvantage';
import LandingFooter from './LandingFooter';

function LandingPage() {
  return (
    <div className='landing_page'>
      <BannerSection />
      <WhatProductSection />
      <ExperienceLiveAuction />
      <BidsBoxxAdvantage />
      <LandingFooter />
    </div>
  )
}

export default LandingPage

import BlankIcon from '../assets/blank_icon.svg';
import Product from '../assets/product-img.png'
import Linked from '../assets/linked.svg';
import BankLogo from '../assets/bank-logo.png';
import OrderStatus1 from '../assets/order/status1.svg';
import OrderStatus2 from '../assets/order/status2.svg';
import OrderStatus3 from '../assets/order/status3.svg';
import OrderStatus4 from '../assets/order/status4.svg';
import OrderStatus5 from '../assets/order/status5.svg';
import OrderStatus11 from '../assets/order/status11.svg';
import OrderStatus22 from '../assets/order/status22.svg';
import OrderStatus33 from '../assets/order/status33.svg';
import OrderStatus44 from '../assets/order/status44.svg';
import OrderStatus55 from '../assets/order/status55.svg';
import CancelSvg from '../assets/order/Cancel.svg'
// Define constants for each image
export const BlankImage = BlankIcon;
export const ProductImage = Product
export const LinkedImage = Linked
export const BankLogoImage = BankLogo
export const Status1 = OrderStatus1
export const Status2 = OrderStatus2
export const Status3 = OrderStatus3
export const Status4 = OrderStatus4
export const Status5 = OrderStatus5
export const Status11 = OrderStatus11
export const Status22 = OrderStatus22
export const Status33 = OrderStatus33
export const Status44 = OrderStatus44
export const Status55 = OrderStatus55
export const CancelSvgIcon = CancelSvg





import React, { useEffect, useReducer, useState } from 'react';
import { FaPhone, FaUser } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";
import { FaAngleRight } from "react-icons/fa6";
import { useLogout } from '../CommonComponent/commonFunction';
import { apiService } from '../Services/ApiServices';
import { notify } from '../CommonComponent/Toaster';
import BaseLoader from '../CommonComponent/BaseLoader';
import { SignOut } from '../Svg';

function SidebarList(props) {
    const [active, setActive] = useState("");
    const handleActive = (event) => {
        setActive(event.target.id);

    };
    const logout = useLogout();
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            user: {}
        }
    );

    const token = localStorage.getItem('token');
    const UserDetails = () => {
        setUserInput({ isLoader: true });
        apiService.getUserDetails(token).then(res => {
            if (res?.data?.status_code === 1) {
                setUserInput({ user: res?.data?.response_data.user, isLoader: false });
            } else {
                if (res?.data?.status_code != 1) {
                    notify("err", res?.data?.error?.message?.error);
                    logout();
                }
            }
        }).catch(() => {
            setUserInput({ isLoader: false });
        });
    };

    useEffect(() => {
        UserDetails();
    }, []);

    return (
        <div className='mobile_sidebar'>

            {userInput.isLoader ? <BaseLoader />
                : (
                    <div className='person_info'>
                        <div className='_user'>
                            <h5 className='mb-0'><span className='mb-1'><FaUser /></span><span>{userInput.user.first_name + " " + userInput.user.last_name}</span></h5>
                            <Link to="/personal_details" style={{ color: "#fff" }}><span className='_icon'><FiEdit /></span></Link>
                        </div>
                        <p><span className='me-2'><FaUser /></span>@{userInput.user.user_name ? userInput.user.user_name : "N/A"}</p>
                        <p><span className='me-2'><FaPhone /></span>{userInput.user.country_code + " " + userInput.user.phone_number}</p>
                        <p><span className='me-2'><IoMdMail /></span>{userInput.user.email_address}</p>
                    </div>
                )
            }
            <div className='_sidebar'>
                <Link to="/store" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>Store Information<span><FaAngleRight /></span></div></Link>
                <Link to="/account_details" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>Account Details<span><FaAngleRight /></span></div></Link>
                <Link to="/categories" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>Categories<span><FaAngleRight /></span></div></Link>
                <Link to="/settings" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>Settings<span><FaAngleRight /></span></div></Link>
                <Link to="/about-us" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>About Us<span><FaAngleRight /></span></div></Link>
                <Link to="/terms-conditions" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>Terms of use<span><FaAngleRight /></span></div></Link>
                <Link to="/privacy-policy" className='_list'><div className={`_iner ${active ? '_active' : ''}`} onClick={(e) => { handleActive(e); }}>Privacy policy<span><FaAngleRight /></span></div></Link>
                <div className='d-grid pt-5 px-5'>
                    <button className='btn product_btn lower_case go_live_btn common_button avenir_heavy' onClick={logout}>
                        <span className='me-2'><SignOut /></span> SIGN OUT
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SidebarList
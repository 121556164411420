import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import OrderStatus from './OrderStatus';
import { OrderStatusSkeleton } from '../../CommonComponent/Skeleton';
import { FaChevronLeft } from 'react-icons/fa';
import { apiService } from '../../Services/ApiServices';

function OrdersDetails({ props }) {
  const params = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [inputLoader, setInputLoader] = useState(false);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: {},
    }
  );

  const orderDetail = (id) => {
    let req = {
      id: id
    };
    setInputLoader(true)
    setUserInput({ isLoader: true })
    apiService.orderDetail(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setInputLoader(false);
        setUserInput({ list: res?.data?.response_data.order_detail, isLoader: false });
      }
      else {
        setInputLoader(false);
      }
    })
  }

  const callOrderDetail = (id) => {
    orderDetail(id);
  }

  useEffect(() => {
    orderDetail(params?.id)
  }, [params?.id])

  const goBack = () => { navigate(-1); };

  return (
    <div className='dashboard_inner'>
      <div className='p-4 _order_dlivered'>
        <span className='back_icon' onClick={goBack} style={{ cursor: 'pointer' }}><FaChevronLeft /></span>
        {userInput.isLoader ? <OrderStatusSkeleton /> :
          <OrderStatus list={userInput.list} callOrderDetail={callOrderDetail} />}
      </div>
    </div>
  )
}

export default OrdersDetails
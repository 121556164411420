import React from 'react';
import leftImg from '../../src/assets/landing/rightbanner.png';
import QrSection from './QrSection';
import Lottie from 'react-lottie';
import upArrow from '../../src/assets/landing/up-arrow.json';
import bottomshape from '../../src/assets/landing/bottomshape.png';
import video2 from '../../src/assets/landing/video2.mp4';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: upArrow,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function ExperienceLiveAuction() {
  return (
    <div className='_experience_live_auction'>
      <div className='_banner'>
        <div className='_left'>
          <img src={leftImg} alt="image" className='img-fluid' />
          <div className='frame-video1'>
            <div className="video-container">
              <video className="html-video" autoPlay loop muted>
                <source src={video2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className='_right'>
          <div className='_text'>
            <h1>Experience <span>LIVE AUCTIONS</span></h1>
            <p>Immerse yourself in the thrill of real-time bidding and watch the action unfold live!</p>
            <QrSection color="white" heading="DOWNLOAD BIDSBOXX" pagename="ExperienceLiveAuction" />
          </div>
        </div>
        <div className='bottom-banner'>
          <div className='arrow_loty'>
            <Lottie options={defaultOptions}
              height={80}
              width={80}
            />
          </div>
          <img src={bottomshape} alt="image" />
        </div>
      </div>
    </div>
  )
}

export default ExperienceLiveAuction
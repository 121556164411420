import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function ScrollComponent({ children, containerRef, authLength, elementWidth }) {
  const scrollwidth = containerRef.current?.clientWidth
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [scrollCount, setScrollCount] = useState(0);
  let maxScrollCount = authLength - parseInt(scrollwidth / elementWidth)
  const handlePrevClick = () => {
    const container = containerRef.current;
    setScrollCount(scrollCount - 1)
    container.scrollBy({
      left: -elementWidth,
      behavior: 'smooth'
    });
  };

  const handleNextClick = () => {
    const container = containerRef.current;
    setScrollCount(scrollCount + 1)
    container.scrollBy({
      left: elementWidth,
      behavior: 'smooth'
    })
  };

  useEffect(() => {
    if (maxScrollCount < 0) {
      setShowNextButton(false)
      setShowPrevButton(false)
    }
    else {
      if (scrollCount == maxScrollCount) {
        setShowNextButton(false)
      }
      else {
        setShowNextButton(true)
      }

      if (scrollCount == 0) {
        setShowPrevButton(false)
      }
      else {
        setShowPrevButton(true)
      }
    }
  }, [scrollCount, maxScrollCount])

  return (
    <>
      {showPrevButton && <button className="btn_scroll_arrow btn_prev" onClick={handlePrevClick}><FaChevronLeft /></button>}
      {children}
      {showNextButton && <button className="btn_scroll_arrow btn_next" onClick={handleNextClick}><FaChevronRight /></button>}
    </>
  )
}

export default ScrollComponent
/**
 * @About
 * This file contains all type of validation.
 */

/* To handle first name validation*/
export function validateName(name) {
    var nameRegex = /^[a-zA-Z ]{2,30}$/;
    // /^[a-zA-Z]+$/;
    name = name.trim();

    if (name === "" || name === undefined || name === null) {
        return { status: false, error: "*Please enter your name" };
    }
    else if (!nameRegex.test(name)) {
        return { status: false, error: "*Please provide valid name" };
    }
    else if (name.length < 2) {
        return { status: false, error: "*Please provide valid name" }
    }
    else {
        return { status: true, error: '' };
    }
}

/* To handle email validation */
export function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    email = email.trim();
    if (email === "" || email === undefined || email === null) {
        return { status: false, error: "Please enter email address" };
    }
    else if (!emailRegex.test(email)) {
        return { status: false, error: "*Please enter valid email address" };
    }
    else {
        return { status: true, error: '' };
    }
}

/* To validate password */
export function validatePassword(password) {
    let passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/;
    password = password.trim();
    if (password === "" || password === undefined || password === null) {
        return { status: false, error: "Please enter valid password." }
    }
    else if (password.length < 6) {
        return { status: false, error: "Password must have at least 6 characters with at least one Capital letter, at least one lower case letter and at least one number." };
    }
    else if (!passwordRegex.test(password)) {
        return { status: false, error: "Password must have at least 6 characters with at least one Capital letter, at least one lower case letter and at least one number." }
    }
    else {
        return { status: true, error: '' }
    }
}

/* To validate Mobile No. */
export function validateMobileNo(mobileNo) {
    var numberRegex = /^[+][1-9][0-9]{7,18}$/;
    mobileNo = mobileNo.trim()
    if (mobileNo === "") {
        return { status: true, error: "" }
    }
    if (mobileNo === "" || mobileNo === undefined || mobileNo === null) {
        return { status: false, error: "*Please enter phone number." }
    } else if (!numberRegex.test(mobileNo)) {
        return { status: false, error: "*Please enter valid phone number (+1XXXXXXXXXX)." }
    } else {
        return { status: true, error: '' }
    }
}

export function checkFileSize(event) {
    const file = event.target.files[0]; // Get the first file selected by the user
    if (file) {
        const fileSize = file.size; // Size of the file in bytes
        // Convert file size to kilobytes or megabytes for better readability
        const fileSizeKB = fileSize / 1024; // Size in kilobytes
        const fileSizeMB = fileSizeKB / 1024; // Size in megabytes
        if (fileSizeMB > 2) {;
            return true
        } else {
            return false;
        }
        // Now you can perform any logic based on the file size
        // For example, you can compare it against a maximum size or display a message to the user.
    }
}



import React, { useEffect, useRef, useState, useReducer, memo } from 'react';
import moment from "moment";
import siteSetting from "../../Component/Config/env/Index";
import Button from "../../CommonComponent/Button";
import "../../StyleComponent/Auction.css";
import AddProductModal from './AddProductModal';
import EditAuction from './EditAuction';
import AddAuction from './AddAuction';
import BlankPage from '../../CommonComponent/BlankPage';
import { apiService, meetingSessionApi } from '../../Services/ApiServices';
import BaseLoader from '../../CommonComponent/BaseLoader';
import { useNavigate } from 'react-router';
import ScrollComponent from '../../CommonComponent/ScrollComponent';
import SetUpPopup from './SetUpPopup';
import { confirmAlert } from 'react-confirm-alert';
import { EditIcon } from '../../Svg';
import { AuctionSkeleton } from '../../CommonComponent/Skeleton';
import FilterBoxAuction from '../../CommonComponent/FilterBoxAuction';
import { RxCross2 } from "react-icons/rx";
import SearchBox from '../../CommonComponent/SearchBox';
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import { Tooltip } from 'react-tooltip';
import { IoIosInformationCircle, IoMdInformationCircleOutline } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';

// const Auction = memo(() => {


function Auction(props) {


  // function Auction() {
  const logout = useLogout();
  const token = localStorage.getItem('token');
  const [PassId, setPassId] = useState('')
  const [productList, setProductList] = useState({});
  const [meetingSES, setmeetingSES] = useState('');
  const [isAuctionDone, setIsAuctionDone] = useState("")
  const [getitem, setItem] = useState({});
  // const [showTooltip,setShowTooltip] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const navigate = useNavigate();
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: {},
      isResponse: '',
      search: '',
      searchType: '',
      inactive: '',
      bP: '',
      totalCount: '',
      activePage: 1,
      dir: '',
      sortValue: '',
      type: '',
      categoryListData: {}

    }
  );

  const [getCategory, setCategory] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [setupModalShow, setSetupModalShow] = useState(false)
  const [editAuctionModal, setEditAuctionModal] = useState()
  const containerRef = useRef(null);
  const [addAuctionModal, setAddAuctionModal] = useState(false);
  const [searchProduct, setSearchProduct] = useState('')

  const [isFetched, setIsFetched] = useState(false); // Flag for tracking


  const hasFetched = useRef(false); // Ref to track if API was called


  const hideProductModalPopup = (popupType) => {
    confirmAlert({
      title: 'Confirm ',
      message: 'Are you sure you want to discard your changes?',
      buttons: [
        {
          label: 'YES',
          onClick: () => { popupType == "add" ? setAddAuctionModal(false) : setEditAuctionModal(false) }
        },
        {
          label: 'NO',
        }
      ]
    });
  }

  const auctionList = (isAuctionCompleted) => {
    // if (!hasFetched.current) {
    let req = { "categorie_id": isAuctionCompleted == 2 ? 5 : isAuctionCompleted == 1 ? 5 : 0, "isAuctionCompleted": isAuctionCompleted || 2 };
    if (isAuctionCompleted) {
      setCategory(isAuctionCompleted)
    }
    setUserInput({ isLoader: true })
    apiService.auctionList(req, token).then(res => {
      console.log("res___________", res)
      if (res?.data?.status_code == 1) {
        // setIsFetched(true)
        hasFetched.current = true;
        setUserInput({ list: res?.data?.response_data.auction_list, totalCount: res?.data.response_data.count, isLoader: false, streamObj: res?.data?.response_data.stream_obj, currentTime: res?.data.response_data.current_time });
      }
      else {
        notify("err", res?.data?.error?.message?.error);
        if (res?.data?.status_code == 2)
          logout();
        if (res?.data?.status_code == 5)
          logout();
        if (res?.data?.status_code == 4)
          logout();
      }
    })
    // }

  }

  const editAuc = (id) => {
    setPassId(id);
    setEditAuctionModal(true);
  }

  const openProductListPopup = (product) => {
    setPassId(product.id);
    setProductList(product.product_list);
    setModalShow(true);
    setIsAuctionDone(product.is_live);
  }

  const openAuctionModal = (item) => { setAddAuctionModal(item); }

  const categoryList = () => {
    apiService.categoryList().then(res => {
      if (res.data?.status_code == 1) {
        setUserInput({ categoryListData: res?.data?.response_data.categories });
      }
      else {
        setUserInput({ categoryListData: [] });
      }
    })
  }

  useEffect(() => {
    // if (!isFetched) {
    categoryList();
    auctionList();
    localStorage.removeItem("isMeeting");
    localStorage.setItem("selectedProduct_id", '');
    localStorage.setItem("auctionId", '');
    // }
  }, [])

  const nextToLive = async (id, item) => {
    setUserInput({ isLoader: true, totalCount: 0 });
    let urlsetup = await AddYoutubeUrl(item);
    let response = await meetingSessionApi(id, token);
    localStorage.setItem('isMeeting', true);
    localStorage.setItem('auctionId', id);
    if (response?.data?.status_code == 1) {
      localStorage.setItem('_expiredTime', item?.end_time * 1000);
      setmeetingSES(response?.data.response_data);
      setUserInput({ isLoader: false });
      navigate("/live", { state: { id, meeting: { ...response?.data?.response_data }, item: item } })
    } else {
      localStorage.setItem('isMeeting', false);
    }
  }

  const showSetupPopup = async (data) => {
    setItem(data);
    setPassId(data?.id)
    let response = await meetingSessionApi(data?.id, token);
    if (response?.data?.status_code == 1) {
      localStorage.setItem('isMeeting', true);
      setmeetingSES(response?.data.response_data);
      setSetupModalShow(true);
    }
  }

  const AddYoutubeUrl = async (data) => {
    let params = {
      auction_id: data.id,
      url: siteSetting.api.web + "videostream/" + data.id,
      endpoint: data.streamUrl + "/" + data.streamKey
    }
    let response = await apiService.addYoutubeUrl(params, token);
    if (response?.data?.status_code == 1) {
    }
  }

  const unselectCateg = e => { auctionList(getCategory.filter(item => item !== e)); }

  const spanElements = [
    <span key={1}>Completed<i onClick={() => unselectCateg(1)}><RxCross2 /></i></span>,
    <span key={2}>Scheduled<i onClick={() => unselectCateg(2)}><RxCross2 /></i></span>,
    <span key={3}>Archived<i onClick={() => unselectCateg(3)}><RxCross2 /></i></span>
  ];

  const selectedSpans = spanElements.filter((span, index) => getCategory.includes(index + 1));
  console.log("selectedSpans________", selectedSpans)

  useEffect(() => {
    if (((searchProduct.length > 2) || (searchProduct.length == 0))) {
      console.log("dddddddddddddddddddddddddddddddddddddddddddsearcc///////////////////////////////////////")
      const fetchSearchData = async () => {
        setUserInput({
          loading: true
        })
        try {
          let req = { search: searchProduct };
          if (searchProduct.length == 0)
            req = { search: '' };
          const token = localStorage.getItem('token');
          const response = await apiService.auctionList(req, token).then(async res => {
            if (res?.data?.status_code == 1) {
              setUserInput({
                list: res?.data?.response_data.auction_list,
                totalCount: res?.data.response_data.count,
                isLoader: false,
                streamObj: res?.data?.response_data.stream_obj,
                currentTime: res?.data.response_data.current_time
              })

            }
            else {
              setUserInput({
                loading: false
              })
              setSearchProduct('');
            }

          })

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      const timeoutId = setTimeout(() => {
        if (searchProduct.trim() !== '') {
          fetchSearchData();
        }
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchProduct]);

  const handleSearchChange = e => {
    setSearchProduct(e?.target?.value);
    if (!e?.target?.value) {
      auctionList();
      setSearchProduct('');
    }
  }

  return (
    <>
      {(userInput.isLoader == true && userInput.totalCount > 0) ? <AuctionSkeleton />
        : (userInput.isLoader == true && userInput.totalCount == 0) ? <BaseLoader />
          :
          <div className='dashboard_inner position_relative __auction_parent'>
            <>
              <div className="_top_bar mt-3 mb-0 justify-content-between">
                <SearchBox placeholderText={"Search Auctions"}
                  value={searchProduct}
                  name="search"
                  handleSearchChange={handleSearchChange}
                />
                <FilterBoxAuction getCategory={getCategory} auctionList={auctionList} />
              </div>
              <div className='filtered_container mt-4'>{selectedSpans} </div>
              {userInput.totalCount == 0 ?
                <>
                  {/* <BlankPage
                    text1={"You haven't added any live auctions yet"}
                    text2={"Please schedule an auction"}
                    btnName={"SCHEDULE AUCTION"}
                    link="/"
                    ClickFunction={openAuctionModal} /> */}


                  {
                    ((selectedSpans.length > 0) || (searchProduct.length > 0)) ?
                      // <div style={{ 'marginTop': '-120px' }}>
                      <BlankPage
                        text1={"Oops, there are no auctions found!"}
                        // text2={"There is no product available from selected category"}
                        btnName={"ADD PRODUCT"}
                        ClickFunction={openAuctionModal}
                        link="/" />
                      // </div>
                      :
                      // <div style={{ 'marginTop': '-95px' }}>
                      <BlankPage
                        text1={"You haven't added any live auctions yet"}
                        text2={"Please schedule an auction"}
                        btnName={"SCHEDULE AUCTION"}
                        link="/"
                        ClickFunction={openAuctionModal} />
                    // </div>
                  }
                </>
                :
                <>

                  <div className='box_section' ref={containerRef}>
                    <ScrollComponent containerRef={containerRef} authLength={userInput.totalCount} elementWidth={506}>
                      <div className='auction_grid ' >
                        {
                          userInput.list.map((item, index) =>
                          (
                            <div className='auction_box' key={item.id}>
                              <div className='user_img'>
                                <img src={item.image} />
                                <div className='mt-3'>
                                  {
                                    (item.is_archieved == false) &&
                                    <>
                                      <p className={`_checked_detail ${item?.product_count > 0 ? '' : 'text-danger'}`}>Add Product
                                        {item?.product_count > 0 ? <span class="tickCheck"></span> : <span className='tickCross'><IoClose /></span>}
                                      </p>
                                      <p className={`_checked_detail ${(!item?.is_camera_setup == "" && !item?.is_audio_setup == "") ? '' : 'text-danger'}`}>Setup Config
                                        {(!item?.is_camera_setup == "" && !item?.is_audio_setup == "") ? <span class="tickCheck"></span> : <span className='tickCross'> <IoClose /></span>}
                                      </p>
                                    </>
                                  }

                                </div>
                              </div>
                              <div className='auction_width'>
                                <div className='auction_width display_text mb-2'>
                                  <span className='size_text avenir_heavy'>{item.description}</span>
                                  {
                                    (item.is_archieved == true) ?
                                      <span className={`${item?.is_live == true ? 'disabled' : 'disabled'}`}> <img className='cursor_pointer edit_icn' src={"/assets/edit.svg"} /></span>
                                      :
                                      <span className={`${item?.is_live == true ? 'disabled' : ''}`}> <img className='cursor_pointer edit_icn' src={"/assets/edit.svg"} onClick={() => item?.is_live == true ? '' : editAuc(item.id)} /></span>
                                  }

                                </div>
                                <div className='display_text'>
                                  <div >
                                    <div className='item_txt '>
                                      <span className='auction_icn'> <img src={"/assets/calendar.svg"} /></span>
                                      <span>
                                  {moment(item.date * 1000).format("DD") === moment(item.end_time * 1000).format("DD") 
                                    ? moment(item.date * 1000).format("DD MMMM YYYY") 
                                    : `${moment(item.date * 1000).format("DD")} - ${moment(item.end_time * 1000).format("DD MMMM YYYY")}`}
                                </span>
                                    </div>
                                    <div className='item_txt'>
                                      <span className='auction_icn'> <img src={"/assets/clock.svg"} /></span>
                                      <span>  {moment(item.start_time * 1000).format("hh:mm A")}</span>
                                    </div>
                                    <span className='apparel_txt avenir_heavy' >{item.category?.name}</span>
                                  </div>
                                  <div>
                                    <div className='item_txt'>
                                      <span className='auction_icn'> <img src={"/assets/bell.svg"} /></span>
                                      <span>  Go Live on</span>
                                    </div>
                                    <div className='item_txt'>
                                      <span className='auction_icn'> <img src={"/assets/clock.svg"} /></span>
                                      <span>  {moment(item.end_time * 1000).format("hh:mm A")}</span>
                                    </div>
                                    <div className={`item_txt d-flex align-items-center ${item?.is_live == true ? 'disabled' : ''}`}>
                                      {
                                        ((userInput.currentTime * 1000 <= (moment(item.start_time * 1000).subtract(30, 'minutes').valueOf())) ||
                                          (userInput.currentTime * 1000 >= (moment(item.end_time * 1000).valueOf()))
                                        ) ?
                                          <>
                                            <span
                                              className={`setup_txt avenir_medium ${(userInput.currentTime * 1000 >= (moment(item.start_time * 1000).subtract(30, 'minutes').valueOf())) ? 'disabled' : 'disabled'} `}
                                            ><EditIcon /></span>
                                            <span className={`setup_txt avenir_medium ${(userInput.currentTime * 1000 >= (moment(item.start_time * 1000).subtract(30, 'minutes').valueOf())) ? 'disabled' : 'disabled'}`}
                                            > Setup</span>

                                          </>
                                          :
                                          <>
                                            <span
                                              className={`setup_txt avenir_medium ${(userInput.currentTime * 1000 >= (moment(item.start_time * 1000).subtract(30, 'minutes').valueOf())) ? '' : 'disabled'} `}
                                              onClick={(e) => item?.is_live == true ? '' : showSetupPopup(item)}><EditIcon /></span>
                                            <span className={`setup_txt avenir_medium ${(userInput.currentTime * 1000 >= (moment(item.start_time * 1000).subtract(30, 'minutes').valueOf())) ? '' : 'disabled'}`}
                                              onClick={(e) => item?.is_live == true ? '' : showSetupPopup(item)}> Setup</span>
                                          </>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className='display_text btn_line'>
                                  {
                                    (item.is_archieved == true) ? <>
                                      <Button name={'+PRODUCT'} className="product_btn lower_case" disabled="true">+PRODUCT</Button>
                                      <Button
                                        name={"GO LIVE"} className='btn  product_btn lower_case go_live_btn common_button avenir_heavy'
                                        disabled="true"
                                      />
                                    </>
                                      :
                                      <>
                                        <Button name={'+PRODUCT'} className="product_btn lower_case" onClick={() => openProductListPopup(item)} counter={item?.product_count}>+PRODUCT</Button>
                                        {
                                          (
                                            (!item.is_block) && item.is_audio_setup && item.is_camera_setup && (item.go_live_button == true) &&
                                            ((userInput.currentTime * 1000) >= (moment(item.start_time * 1000).subtract(30, 'minutes').valueOf()))
                                            && ((userInput.currentTime * 1000) <= (item.end_time * 1000)) && (item?.is_live == false) || (item?.is_resume == true)
                                          ) ?
                                            <Button
                                              name={item?.is_resume ? "RESUME" : "GO LIVE"} className='btn  product_btn lower_case go_live_btn common_button avenir_heavy'
                                              onClick={(e) => nextToLive(item.id, item)}
                                            />
                                            :
                                            <Button
                                              name={"GO LIVE"} className='btn  product_btn lower_case go_live_btn common_button avenir_heavy'
                                              disabled="true"
                                            />
                                        }
                                      </>
                                  }

                                </div>

                              </div>
                              {/* <div className="eye_icon_tooltip" 
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                              ><IoMdInformationCircleOutline />
                              </div>
                                <div className='_tooltip'style={{display:hoveredIndex === index ? "block" : "none"}} visible={hoveredIndex === index}>
                                  Please configure the setup and add a product to enable the "Go Live"
                                </div> */}
                            </div>
                          )
                          )
                        }
                        {editAuctionModal && <EditAuction
                          show={editAuctionModal}
                          onHide={() => hideProductModalPopup("edit")}
                          onlyClose={() => setAddAuctionModal(false)}
                          backdrop="static"
                          keyboard={false}
                          id={PassId}
                          categories={userInput.categoryListData}
                        />}
                      </div>
                    </ScrollComponent>

                    <AddProductModal show={modalShow}
                      onHide={() => setModalShow(false)}
                      backdrop="static"
                      keyboard={false}
                      productList={productList}
                      auctionId={PassId}
                      is_live={isAuctionDone}
                      auctionList={auctionList}
                    />

                    <SetUpPopup show={setupModalShow} onHide={() => setSetupModalShow(false)}
                      backdrop="static"
                      keyboard={false}
                      productList={productList}
                      meetingSES={meetingSES}
                      id={PassId}
                      item={getitem}
                      streamObj={userInput?.streamObj}
                    />
                  </div>
                  <div className='text-center mt-5'>
                    <Button name='SCHEDULE AUCTION' className="lower_case" onClick={() => setAddAuctionModal(true)} />
                  </div>
                </>
              }
            </>
            {addAuctionModal && <AddAuction
              show={addAuctionModal}
              onHide={() => hideProductModalPopup("add")}
              onlyClose={() => setAddAuctionModal(false)}
              auctionId={PassId}
              categories={userInput.categoryListData}
            />}
          </div>
      }
    </>
  )
}

export default Auction
import React, { useState, useReducer } from 'react'
import Logo from '../../CommonComponent/Logo';
import Input from '../../CommonComponent/Input'
import "../../StyleComponent/AuthStyle.css"
import Button from '../../CommonComponent/Button'
import Lottie from 'react-lottie';
import Animation from '../../Utils/Animation.json'
import SignInModal from '../SignInModal/SignInModal';
import { apiService } from '../../Services/ApiServices';
import InlineError from "../../CommonComponent/inlineError";
import { validatePassword } from '../../CommonComponent/Validation';

function ConfirmPassword() {
    const [modalShow, setModalShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const phone_number = localStorage.getItem('phone_number');
    const country_code = "+" + localStorage.getItem('country_code');
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation,
        renderer: 'svg'
    }


    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            password: '', confirmPassword: '', loading: false, isResponse: false, isClearable: true, isLoading: true
        }
    );

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            password: '', confirmPassword: '', errorMsg: ''
        }
    );

    const handleChange = e => {
        setUserInput({ ...userInput, [e.target.name]: e.target.value });
        setError({ ...error, [e.target.name]: '', });
    };

    const handleValidate = () => {
        let { password, confirmPassword } = userInput;
        let validate = true;
        setError({});
        if ((password == undefined) || (password == '')) {
            setError({ password: '*Please enter password' });
            validate = false
        }
        if (!validatePassword(password).status) {
            setError({ password: 'Password must be at least 6 characters long and contain at least one uppercase letter, one special character, and one number' });
            validate = false
        }


        if ((confirmPassword == undefined) || (confirmPassword == '')) {
            setError({ confirmPassword: '*Please enter password' });
            validate = false
        }
        if (!validatePassword(confirmPassword).status) {
            setError({ confirmPassword: 'Password must be at least 6 characters long and contain at least one uppercase letter, one special character, and one number' });
            validate = false
        }

        if (password !== confirmPassword) {
            {
                setError({ confirmPassword: 'Confirm Password does not match to password' });
                validate = false
            }
        }
        return validate;
    }
    const handleCreatePassword = () => {

        try {
            if (handleValidate() == true) {
                let req = {
                    phone_number: phone_number,
                    country_code: country_code,
                    password: userInput.password,
                    password_confirm: userInput.confirmPassword
                }
                apiService.setNewPassword(req).then(result => {
                    if (result.data?.status_code == 0) {
                        setError({ errorMsg: result?.data?.error.message.error });
                        setModalShow(false)
                    } else
                        setModalShow(true)

                })
            }
        } catch (e) {
            console.error('e', e);
        }
    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)

    }
    return (
        <div className="auth_main_section">
            <div className='left_side'>
                <div className='logo_section'>
                    <Logo />
                </div>
                <div>
                    <div>
                        <Lottie
                            options={defaultOptions}
                            height={160}
                            width={160}
                        />
                    </div>
                    <img src='assets/sign-in-girl.svg' alt='women' className='sign_in_girl' />
                </div>

            </div>
            <div className='right_side'>
                <div className='reset_pass_section'>
                    <div className='mob_signup_logo'>
                        <img src='assets/sinupMobLogo.svg' />
                    </div>
                    <h1 className='reset_text avenir_black'>Reset Password</h1>
                    <p className='welcome_txt'><span className='avenir_medium'> Create your new password in BidsBoxx</span></p>
                    <div className='wrap_inpt'>

                        <div className='position_relative'>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                className="pb-2"
                                maxLength={50}
                                minLength={10}
                                name="password"
                                value={userInput.password}
                                onChange={handleChange}

                            />
                            <img onClick={handleTogglePassword} className='eye_icn_new_password cursor_pointer' src={showPassword ? 'assets/eye.svg' : 'assets/close-eye.svg'} />
                        </div>

                        <InlineError errorMessage={error.password} />
                    </div>
                    <div className='wrap_inpt position_relative'>
                        <Input
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            className="pb-2"
                            maxLength={50}
                            minLength={10}
                            name="confirmPassword"
                            value={userInput.confirmPassword}
                            onChange={handleChange}
                        />

                        <img onClick={handleShowConfirmPassword} className='eye_icn_new_password cursor_pointer' src={showConfirmPassword ? 'assets/eye.svg' : 'assets/close-eye.svg'} />

                        <InlineError errorMessage={error.confirmPassword} />
                    </div>

                    <InlineError errorMessage={error.errorMsg} />
                    <div className='wrap_btn d-grid '>
                        <Button name={"Create"} onClick={() => handleCreatePassword()} />
                    </div>
                    <SignInModal show={modalShow} onHide={() => setModalShow(false)}
                        backdrop="static"
                        keyboard={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default ConfirmPassword
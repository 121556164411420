import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserDetails from "./UserDetails";
import StoreInformation from './StoreInformation';
import CategoriesList from "./CategoriesList";
import BankAccountDetails from './BankAccountDetails';

function SinupProcess() {
  return (
    <div>
          <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="home" title="1">
      <UserDetails />
      </Tab>
      <Tab eventKey="profile" title="2">
        <StoreInformation />
      </Tab>
      <Tab eventKey="longer-tab" title="3">
      <BankAccountDetails />
      </Tab>
      <Tab eventKey="contact" title="4" disabled>
       <CategoriesList />
      </Tab>
    </Tabs>
    </div>
  )
}

export default SinupProcess 
import React, { useRef, useState, useReducer, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { apiService } from '../../Services/ApiServices';
import Button from '../../CommonComponent/Button'
import { IoCloseCircle } from "react-icons/io5";
import { AddProductListSkeleton } from '../../CommonComponent/Skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { ProductImage } from '../../CommonComponent/ImageConstants';

function AddProductListModal(props) {
    const token = localStorage.getItem('token');
    const [inputLoader, setInputLoader] = useState(false);
    const containerReff = useRef(null);
    const [categoriesCount, setCategoriesCount] = useState(0)
    const [multiSelectCategories, setMultiSelectCategories] = useState([]);
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            list: {},
            isResponse: '',
            search: '',
            searchType: '',
            inactive: '',
            bP: '',
            totalCount: '',
            activePage: 1,
            dir: '',
            sortValue: '',
            type: ''

        }
    );

    const productList = () => {
        let req = { auctionId: props.auctionId };
        setInputLoader(true)
        setUserInput({ isLoader: true })
        apiService.auctionProductList(req, token).then(res => {
            if (res?.data?.status_code == 1) {
                setInputLoader(false);
                setUserInput({ list: res?.data?.response_data.product_list, totalCount: res?.data.response_data.product_list.length, isLoader: false })
            }
            else {
                setInputLoader(false);
                setUserInput({
                    loading: false
                })
            }
        })
    }

    useEffect(() => {
        productList()
    }, [props.addProducts])

    const selectProducts = (id) => {
        const isSelected = multiSelectCategories.includes(id);
        if (isSelected) {
            setCategoriesCount(categoriesCount - 1);
            setMultiSelectCategories((prevSelected) =>
                prevSelected.filter((selected) => selected !== id)
            );
        } else {
            setCategoriesCount(categoriesCount + 1);
            setMultiSelectCategories((prevSelected) => [...prevSelected, id]);
        }
    };

    const selectSubmit = () => {
        let req = { "auction_id": props.auctionId, "product_ids": multiSelectCategories };
        apiService.addProductToAuction(req, token).then(res => {
            if (res?.data?.status_code == 1) {
                props.addProducts();
                props.onHide();
            } else {
                props.onHide()
            }
        })
    }

    return (
        <div>
            <Modal
                {...props}
                className='cstm_modal_product_list'
                aria-labelledby="contained-modal-title-center"
                centered
            >
                <Modal.Body>
                    <div className='close_icon _add_product' onClick={props.onHide}><span><IoCloseCircle /></span></div>
                    <div className='product_container' ref={containerReff}>
                        <div className={`${userInput.list >= 8 ? 'justify-content-between' : 'justify-content-start'} wrp_product_list justify-content-start pt-4`}>
                            {userInput.isLoader == true ?
                                <AddProductListSkeleton />
                                :
                                <>
                                    {userInput.list.map((item) =>
                                    (
                                        <div key={item.id} id={item.id}
                                            className={`product_box ${multiSelectCategories.includes(item.id) ? "active" : ""}`}
                                            onClick={() => selectProducts(item.id)}>
                                            {multiSelectCategories.includes(item.id) && <span class="tick"></span>}
                                            <div className={`_image ${multiSelectCategories.includes(item.id) ? "" : ""}`}>
                                                {item.image ? <img id={item.id} src={item.image} alt="product" /> : <img id={item.id} alt="product" src={ProductImage} />}
                                            </div>
                                            <div className='_text'>
                                                <h6 className='mb-1'>{item.name}</h6>
                                                <p className='_size'>Size - {item.size}</p>
                                                <p className='_price '><del className='me-3'>₹{item.original_mrp}</del><span>₹{item.auction_start_price}</span></p>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </>
                            }
                        </div>
                    </div>
                    <div className="text-center">
                        <br />
                        <Button
                            name="Add"
                            onClick={multiSelectCategories.length > 0 ? selectSubmit : null}
                            disabled={multiSelectCategories.length === 0}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddProductListModal
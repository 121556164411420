import React, { useState, useEffect, useRef } from 'react';
import '../StyleComponent/SlotMachine.css';
import craker from '../assets/craker.json';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: craker,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const SlotAnimation = ({ names, winnerName, delay }) => {
  const [isRolling, setIsRolling] = useState(true);
  const [winner, setWinner] = useState(null);
  const [items, setItems] = useState([])

  useEffect(() => {
    let timeout;
    if (isRolling) {
      timeout = setTimeout(() => {
        setIsRolling(false);
        setWinner(winnerName);
      }, 7000); // Automatically stop after 5 seconds
    }
    return () => clearTimeout(timeout);
  }, [isRolling, names]);

  useEffect(() => {
    if (names != undefined) {
      const items = [];
      for (let i = 0; i < 100; i++) {
        items.push(names[(i) % names?.length]);
      }
      setItems(items)
    }

  }, [isRolling, names])

  return (
    <div className="rolling-names-container">
      <div className={`names-wrapper ${isRolling ? 'rolling' : ''}`}>
        {!isRolling && winner ?
          <div className="winner name center">
            {winner}
          </div>
          :
          <>
            {items != undefined && items?.length > 0 ?
              <>
                {items?.map((name, index) => (
                  <div key={index} className={`name ${index === 2 ? 'center' : ''}`}>
                    {name}
                  </div>
                ))}
              </>
              : ''
            }
          </>
        }
        {!isRolling && winner && <div className=" _creaker">
          <Lottie options={defaultOptions}
            height={100}
            width={200} />
        </div>}

      </div>
    </div>
  );
};

export default SlotAnimation;





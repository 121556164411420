import React, { useState, useReducer, useEffect } from 'react';
import { apiService } from '../Services/ApiServices';
import { useLocation } from 'react-router';
import AddProductListLiveModal from './AddProductListLiveModal';
import socket from '../Socket/io';
import useWindowSize from './useWindowSize';

function ProductList(props) {
  const { state } = useLocation();
  const [modalShow, setModalShow] = useState(false);
  let auctionId = state;
  const token = localStorage.getItem('token');
  const [unSoldProductList, setUnSoldProductList] = useState();
  const [hideProductList, setHideProductList] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [active, setActive] = useState("productList")
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: {},
      isResponse: '',
      search: '',
      searchType: '',
      inactive: '',
      bP: '',
      totalCount: '',
      activePage: 1,
      dir: '',
      sortValue: '',
      type: '',
      soldProduct: {}

    }
  );
  const { width } = useWindowSize();
  const displayStyle = props.displayWeb ? { display: 'block' } : {};
  const showStyle = { display: `${props.show ? 'block' : 'none'}` };
  const combinedStyle = width > 991 ? displayStyle : showStyle;

  const getAuction = () => {
    setUserInput({ isLoader: true })
    try {
      if (state) {
        let auction_id = state.id;
        apiService.getAuction(auction_id, token).then(res => {
          if (res?.data?.status_code == 1) {
          }
          else {
          }
        })
      }
    } catch (e) {
      console.error('e', e);
    }

  }

  useEffect(() => {
    if (state && state.id) {
    }
  }, [state]);

  useEffect(() => {
    try {
      const handleProductList = async () => {
        socket.on('auction_product_list', (data) => {
          if (data && data.response_data && data?.response_data?.auction_details) {
            const productData = data.response_data.auction_details;
            setUserInput({
              list: productData.unsold_products,
              soldProduct: productData.sold_products,
              isLoader: false,
              is_product: productData.is_product,
            });
            setUnSoldProductList(data?.response_data?.auction_details?.unsold_product_count);
            props.getProductCountData(data?.response_data?.auction_details?.unsold_product_count)
          } else {
            setUserInput({ isLoader: false });
          }
        })
      };
      handleProductList()
      return () => {
        socket.off('auction_product_list', handleProductList);
      };

    } catch (error) {
      console.log("error__________", error)
    }
  }, []);


  const handleRadioChange = (productId) => {
    localStorage.setItem("selectedProduct_id", productId)
    setSelectedProduct(productId);
    props.checkRadioButtonSelected(true, productId);
    if (width < 991) props.showProductList(false)
  };

  const handleTabActive = (item) => {
    setActive(item);
  };

  useEffect(() => {
    if (props.is_auction && props.is_giveaway)
      setSelectedProduct(null);
  }, [props.is_auction, props.is_giveaway])

  const hideSection = () => {
    props.showProductList(false)
  }

  return (
    <>
      <div className={`${props.className} _product_list_section mt-3 `}
        style={combinedStyle}
      >
        {props.show ? <span className='_cross_icon' onClick={hideSection}>X</span> : ''}
        <div className='product_list_tab'>
          <div onClick={() => { handleTabActive("productList"); }} className={`_tab ${active === "productList" ? 'active' : ''}`}>
            <h4>Product List [ {userInput?.list?.length} ]
              {
                (userInput?.is_product && active === "productList") ?
                  <span className="add_product_lis_btn ms-3" onClick={() => setModalShow(true)} >+</span>
                  :
                  <span className="add_product_lis_btn lower_case  ms-3 disabled" disabled="true" style={{ opacity: "0.5", cursor: "not-allowed" }}>+</span>
              }
            </h4>
          </div>
          <div className={`_tab  ${active === "soldProductList" ? 'active' : ''}`} onClick={() => { handleTabActive("soldProductList"); }}>
            <h4>Product Sold List [ {userInput?.soldProduct?.length} ]</h4>
          </div>
        </div>
        {active === "productList" ?
          <div className='_list_section'>
            <div className='_list _head'>
              <div className='_first'>
                <h6>S.No</h6>
              </div>
              <div className='_last'>
                <h6>Product Name</h6>
              </div>
            </div>

            {
              userInput.isLoader == true ?
                <></>
                :
                <>
                  {userInput?.list?.map((item, i) => {
                    return (<div className='_list'>
                      <div className='_first'>
                        <h6>{(i + 1)}</h6>
                      </div>
                      <div className='_last'>
                        {
                          (!item.sold_out_status || (item.sold_out_status == 0)) ?
                            <></>
                            :
                            <>
                              {(item.sold_out_status == 1)
                                ?
                                <span className='sold_product'>Giveaway</span>
                                :
                                <span className='sold_product'>Sold(Auction)</span>
                              }
                            </>
                        }
                        <h6>{item.name}</h6>
                      </div>
                      <div className='_laast'>
                        <div className={`radio_btn_parent`}>
                          <input
                            type="radio"
                            name="productSelect"
                            className='radio_btn'
                            checked={selectedProduct === item.id}
                            onChange={() => handleRadioChange(item.id)}
                            disabled={(selectedProduct !== null && selectedProduct !== item.id)}
                          />
                        </div>
                      </div>
                    </div>)
                  })}
                </>
            }
          </div>
          :
          <div className='_list_section'>
            <div className='_list _head'>
              <div className='_first'>
                <h6>S.No</h6>
              </div>
              <div className='_last'>
                <h6>Product Name</h6>
              </div>
            </div>

            {
              userInput.isLoader == true ?
                <></>
                :
                <>
                  {userInput?.soldProduct?.map((item, i) => {
                    return (<div className='_list'>
                      <div className='_first'>
                        <h6>{(i + 1)}</h6>
                      </div>
                      <div className='_last'>
                        {
                          (!item.sold_out_status || (item.sold_out_status == 0)) ?
                            <></>
                            :
                            <>
                              {(item.sold_out_status == 1)
                                ?
                                <span className='sold_product'>Giveaway</span>
                                :
                                <span className='sold_product'>Sold(Auction)</span>
                              }
                            </>
                        }
                        <h6>{item.name}</h6>
                      </div>
                      <div className='_laast'>
                      </div>
                    </div>)
                  })}
                </>
            }
          </div>
        }
        {modalShow && <AddProductListLiveModal
          show={modalShow} onHide={() => setModalShow(false)}
          backdrop="static"
          keyboard={false}
          auctionId={auctionId?.id}
          addProducts={getAuction}
          getProductCountData={props.getProductCountData}
        />}
      </div>
    </>
  )
}

export default ProductList
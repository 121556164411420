import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FilterIcon } from '../Svg';
import Button from '../CommonComponent/Button';

function FilterBox(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState(props.getCategory);
  const [duration, setDuration] = useState(props.getDuration);
  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };
  const onChangeCategory = (e) => {
    const { value } = e.target;
    if (category.includes(parseInt(value))) {
      setCategory([]);
    } else {
      // Otherwise, select the clicked option and deselect all others
      setCategory([parseInt(value)]);
    }
  }
  const onChangeDuration = (e) => {
    const { value, checked } = e.target;
    const intValue = parseInt(value, 10);
    if (duration.includes(parseInt(value))) {
      setDuration([]);
    } else {
      // Otherwise, select the clicked option and deselect all others
      setDuration([parseInt(value)]);
    }
  }

  const submitButton = () => {
    props.orderListFilter(category, duration);
    setIsOpen(false);
    setCategory([]);
    setDuration([]);
  }

  useEffect(() => {
    setCategory(props.getCategory);
    setDuration(props.getDuration)
  }, [props.getCategory, props.getDuration])

  return (
    <div className={`${isOpen ? '_filter_box_opened' : ''} _filter_box`}>
      <Dropdown data-bs-theme="" onToggle={handleToggle} show={isOpen}>
        <Dropdown.Toggle id="dropdown-button-dark-example1" variant="" >
          <span></span><FilterIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <button type="button" aria-expanded="true" className="dropdown-togglee" onClick={() => setIsOpen(false)} >
            <span>Select Categories</span><FilterIcon />
          </button>
          <div className='_scroll_part'>
            {props?.menuitem1?.map((item, index) => {
              return (
                <div className="dropdown-item menuitem1" >
                  <div className={`${props.type == 'radio' ? 'radio_btn_parent' : 'form-group'}`}>
                    <input type={props.type} id={item} name={item}
                      className='radio_btn'
                      value={index}
                      checked={category?.includes(index)}
                      onChange={onChangeCategory}
                    />
                    <label htmlFor="current">{item}</label>
                  </div>
                </div>
              )
            })}
            {props?.hr &&
              <hr className='mt-0 mb-0' />
            }
            {props?.menuitem2?.map((item, index) => {
              return (
                <div className="dropdown-item menuitem2" >
                  <div className={`${props.type == 'radio' ? 'radio_btn_parent' : 'form-group'}`}>

                    <input type={props.type} id={item} name={item}
                      className='radio_btn'
                      value={5 + index}
                      checked={duration?.includes(5 + index)}
                      onChange={onChangeDuration}
                    />
                    <label htmlFor="current">{item}</label>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="dropdown-item">
            <Button name="Apply" className="btn btn-sm lower_case" onClick={submitButton} />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default FilterBox
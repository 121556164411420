import React, { useEffect, useReducer, useState } from 'react';
import '../../StyleComponent/order.scss';
import OrdersrderCard from './OrdersrderCard';
import { apiService } from '../../Services/ApiServices';
import { OrderListSkeleton } from '../../CommonComponent/Skeleton';
import FilterBoxOrder from '../../CommonComponent/FilterBoxOrder';
import BlankPage from '../../CommonComponent/BlankPage'
import { notify } from '../../CommonComponent/Toaster';
import { useLogout } from '../../CommonComponent/commonFunction';
import BaseLoader from '../../CommonComponent/BaseLoader';

function Orders() {
  const logout = useLogout();
  const [active, setActive] = useState("OnTheWay");
  const token = localStorage.getItem('token');
  const [getCategory, setGetCategory] = useState([]);
  const [getDuration, setGetDuration] = useState([]);
  const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      isLoader: true,
      list: [],
      newList: [],
      onTheWayList: [],
      deliveredList: [],
      filteredList: [],
      totalCount: ''

    }
  );
  const [isFetched, setIsFetched] = useState(false); // Flag for tracking
  const [isFilterOpen, setIsFilterOpen] = useState(false); // Track filter dropdown state

  const handleTabActive = (item) => {
    setActive(item);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen); // Toggle filter state
    setActive()
  };

  const orderList = () => {
    const req = {}
    setUserInput({ isLoader: true });
    apiService.orderList(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setIsFetched(true)
        setUserInput({
          list: res.data?.response_data.order_list,
          newList: res.data?.response_data.order_list.filter((item) => item.order_status === "Order placed"),
          onTheWayList: res.data?.response_data.order_list.filter((item) => item.order_status == "Out for delivery"),
          deliveredList: res.data?.response_data.order_list.filter((item) => item.order_status == "Delivered"),
          totalCount: res.data?.response_data.order_list.length,
          isLoader: false
        })
      }
      // Out for delivery ,Delivered,In progress, Shipment
      else {
        if (res?.data?.status_code != 1) {
          notify("err", res?.data?.error?.message?.error);
          if (res?.data?.status_code == 2)
            logout();
          if (res?.data?.status_code == 5)
            logout();
          if (res?.data?.status_code == 4)
            logout();
        }
      }
    })
  }

  const orderListFilter = (category, duration) => {
    const req = { order_status: category, time_duration: duration };
    setGetCategory(category);
    setGetDuration(duration);
    setUserInput({ isLoader: true });
    apiService.orderListFilter(req, token).then(res => {
      if (res?.data?.status_code == 1) {
        setUserInput({ list: res.data?.response_data.order_list, isLoader: false });
      }
      else {
        setUserInput({ loading: false });
      }
    })
  }

  useEffect(() => {
    if (!isFetched) {
      orderList();
    }
  }, [isFetched])

  return (
    <>
      {
        (userInput.isLoader == true && userInput.totalCount == 0) ? <BaseLoader />
          :
          <div className='dashboard_inner position_relative'>
            <div className='_order_container'>
              <div className='_top_bar mt-3 mb-5'>
                <div className='end_bar w-100'>
                  <div className='order_tab justify-content-end' >
                    <h4 style={{ cursor: "auto" }} className={`_tab active ${active === "AllOrders" ? 'active' : ''}`} onClick={() => { handleTabActive("AllOrders"); }}>ALL ORDERS</h4>
                  </div>
                  <FilterBoxOrder
                    isOpen={isFilterOpen}
                    toggleFilter={toggleFilter}
                    orderList={orderList}
                    header={"Filter Orders"}
                    type={"radio"}
                    subheader={"Status"}
                    hr={true}
                    menuitem1={menuitem1}
                    menuitem2={menuitem2}
                    orderListFilter={orderListFilter}
                    getCategory={getCategory}
                    getDuration={getDuration}
                    handleTabActive={handleTabActive}
                  />
                  {/* } */}
                </div>
              </div>
              <div className='order_container'>
                <h5>{
                  getCategory.flat() == 0 ? "All Orders"
                    : getCategory.flat() == 1 ? "All Placed Orders"
                      : getCategory.flat() == 2 ? "All In Progress Orders"
                        : getCategory.flat() == 3 ? "All Shipped Order"
                          : getCategory.flat() == 4 ? "All On The Way Orders"
                            : getCategory.flat() == 5 ? "All Delivered Orders"
                              : getCategory.flat() == 6 ? "All Cancelled Orders"
                                : ""

                }</h5>
                {userInput.isLoader ?
                  <div className='ordercard_container'>
                    <OrderListSkeleton />
                  </div>
                  :
                  <>
                    {userInput.list.length <= 0 ?
                      <BlankPage
                        text1={"You haven't received any orders yet."}
                        // text2={"You haven't received any orders yet."}
                        btnName={"Add Product"}
                        hideButton="true"
                        link="/" />
                      :
                      <div className='ordercard_container'>
                        <OrdersrderCard data={userInput.list} cancelBtn={true} orderList={orderList} />
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Orders
const menuitem2 = [
  "Anytime",
  "Last 30 days",
  "Last 6 months",
  "Last year"
]
const menuitem1 = [
  "All",
  "Order Placed",
  "In Progress",
  "Shipment",
  "On The Way",
  "Delivered",
  "Cancelled"
]
import React, { useState, useEffect, useReducer } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from '../../CommonComponent/Button';
import OtpInput from "react18-input-otp";
import { apiService } from '../../Services/ApiServices';
import moment from 'moment';
import InlineError from '../../CommonComponent/inlineError';

function VerifyMobileNoModal(props) {
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(60);
    let phone = localStorage.getItem('phone_number');
    let country_code = localStorage.getItem('country_code')
    const handleChange = (enteredOtp) => {
        setError({ errorMsg: '' });
        setOtp(enteredOtp);
    };

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            errorMsg: ''
        }
    );

    const verifyOtp = () => {
        let req = {
            otp_number: parseInt(otp),
            phone_number: parseInt(phone),
            country_code: "+" + country_code,
            validate_phone_number: true,

        }
        apiService.verifyOtp(req).then(result => {
            if (result.data?.status_code === 1) {
                localStorage.setItem('isVarified', true);
                props.onHide();
            } else {
                localStorage.setItem('isVarified', false);
                setError({ errorMsg: result?.data?.error.message.error });
            }
        })
    }

    const checkPhoneVerify = async () => {
        setSeconds(60);
        setOtp('');
        setError({ errorMsg: '' });
        try {
            const req = {
                phone_number: phone,
                country_code: `+${country_code}`,
                is_for_forgot_password: 0
            };
    
            const result = await apiService.sendOtp(req);
            if (result.data?.status_code === 0) {
                // Handle specific error or status code 0
                setError({ errorMsg: 'Failed to send OTP. Please try again.' });
                return;
            }
            const { number_verified } = result.data?.response_data || {};
            if (number_verified === false) {
                // Redirect or show modal for OTP verification
                // setModalShow(true);
                // window.location.href = "/verify_otp_signup";
            } else if (number_verified === true) {
                setError({ errorMsg: '' });
            } else {
                console.warn("Unexpected response: ", result.data);
            }
        } catch (error) {
            console.error('Error in checkPhoneVerify:', error);
            setError({ errorMsg: 'An unexpected error occurred. Please try again.' });
        }
    };
    
    const cancelModel = () => {
        props.onHide();
        setSeconds(60)
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            if (seconds > 0) {
                // setSeconds(seconds - 1);
            } else {
                // setSeconds(0);
                setOtp('');
                setError({ errorMsg: '' });
            }
        }, 1000);
        return () => {
            // clearTimeout(timer);
        };
    }, [seconds]);

    useEffect(() => {
        var interval = setInterval(
            () => {
                setSeconds((prevstate) => (prevstate != 0 ? prevstate - 1 : prevstate))
            },
            1000
        );
        return () => {
            setOtp('');
            setError({ errorMsg: '' });
            clearInterval(interval);
        };
    }, []);

    return (
        <Modal
            {...props}
            size="lg"
            className='verify_num_modal'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className=''>
                    <h1 className='vrfy_num'>Verify your mobile number</h1>
                    <p className='code_snd'> <span className='avenir_medium'>A 4-digit code has been sent to your mobile number</span> </p>

                    <div className='otp_wrp mt-4'>

                        <OtpInput
                            className="opt_inp"
                            value={otp}
                            isInputNum={true}
                            numInputs={4}
                            onChange={(e) => handleChange(e)}
                            separator={<span></span>}
                        />
                    </div>
                    <p className='timer'> {moment.utc(seconds * 1000).format('mm:ss')}</p>
                    <p className="dont_rec_code  avenir_medium">
                       Didn't receive OTP?&nbsp;
                        {
                            (seconds < 1) ?
                                <span className="register" onClick={checkPhoneVerify}>RESEND OTP</span>
                                :
                                <span className="register  dont_rec_code">RESEND OTP</span>
                        }
                    </p>
                    <InlineError errorMessage={error.errorMsg} />
                    <div className='mt-4'>
                        {
                            (otp.length == 4) ?
                                <Button name={"Verify"} onClick={() => verifyOtp()} />
                                :
                                <Button name={"Verify"} disabled="true" />
                        }

                    </div>
                    <div className='mt-2'>
                        <p><span className=' cursor_pointer avenir_black' onClick={cancelModel}>Cancel</span></p>
                    </div>


                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VerifyMobileNoModal

import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBackIos } from 'react-icons/md'

function AboutUs() {
    const navigate = useNavigate();
    const goBack = () => { navigate(-1); };

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='privacy_policy_parent '>
                <div className='header'>
                        <span onClick={goBack}><MdArrowBackIos /></span>
                        <Link to="/">  <img src='https://d2rqcufsarnep3.cloudfront.net/temp/images/header-logo_VBshfDA.svg' style={{ height: "46px" }} /></Link>
                </div>
                <div className='privacy_policy '>
                        <h4>About Us:</h4>
                                <p>At Bidsboxx, we offer a diverse selection of products through our exciting live sales events. Our
                                        platform features a wide variety of categories to cater to every interest and need, including
                                        <b>Trading Card Games, Sports Cards, Toys & Hobbies</b> related material, <b>Comics & Anime</b>, and
                                        <b>Electronics.</b> We also have collectible <b>Coins & Money, Knives & Hunting Gear, Estate Sales
                                                & Storage Units</b>, and <b>Sports Memorabilia.</b> For fashion enthusiasts, we offer <b>Men's Fashion,
                                                        Sneakers & Streetwear, Women's Fashion</b> wear, and <b>Bags & Accessories</b>. Our collection
                                        extends to <b>Beauty Products, Jewelry, Music, Video Games, Antiques and Vintage Decor,
                                                Home & Garden Items, Rocks & Crystals</b>, and <b>Baby & Kid's</b> products. Additionally, we
                                        feature <b>Arts & Handmade</b> items. With such a broad range of categories, there's something for
                                        everyone at Bidsboxx!</p>
                                <hr />

                                <h4>How the Bidsboxx Bidding System
                                        Works:</h4>
                                <h6>For Sellers:</h6>
                                <p>1. <b>Host Live Sales:</b> Sellers can host live sales events where they showcase their products
                                        in real-time. This is similar to a physical market experience, allowing buyers to see
                                        products and interact with the seller.</p>
                                <p>2. <b>Product Presentation:</b> During the live sale, sellers provide detailed information and
                                        demonstrate the features of their products. This helps buyers make informed decisions.</p>
                                <h6>For Buyers:</h6>
                                <p>1. <b>Add Funds to Your Wallet:</b> To participate in a live sale, you need to add funds to your
                                        Bidsboxx wallet. You can do this using UPI, credit/debit cards, or net banking. Be aware
                                        that third-party charges may apply during the transaction.</p>
                                <p>2. <b>Join Live Sales:</b> Browse ongoing live sales and review the product details shared by the
                                        seller. When you find an item you like, you can place a bid.</p>
                                <p>3. <b>Place Your Bid:</b> Ensure you have enough balance in your wallet to cover your bid. You
                                        can only bid up to the amount available in your wallet. If your balance is insufficient, you
                                        won’t be able to place a bid until you add more funds.</p>
                                <p>4. <b>Winning the Bid:</b> If you place the highest bid and win, the amount will be deducted from
                                        your wallet, and you’ll receive instructions on how to complete the purchase.</p>
                                <p>5. <b>Recharging Your Wallet:</b> If needed, you can always recharge your wallet to participate
                                        in more live sales and place additional bids.</p>
                                <hr />

                                <h4>How to Bid on Products:</h4>

                                <p><b>1. Adding Funds to Your Wallet:</b></p>

                                <p>To participate in live sales and place bids on Bidsboxx, you need to have sufficient funds in your wallet. Here’s how you can add money to your wallet:
                                </p>
                                <p><b>● Payment Methods:</b> You can add funds to your Bidsboxx wallet using a variety of payment methods, including UPI (Unified Payments Interface), credit/debit cards, or net banking. Choose the method that’s most convenient for you.
                                </p><p><b>● Easy Process:</b> The process is simple and user-friendly. Select your preferred payment method, enter the amount you wish to add, and follow the prompts to complete the transaction.
                                </p><p><b>● Third-Party Charges:</b> Be aware that during the process of adding funds, third-party charges may be applied by the payment service providers. These charges are outside of Bidsboxx’s control and are part of the payment processing fees imposed by banks or payment gateways.
                                </p><p><b>● Confirmation:</b> Once the transaction is completed, the funds will be added to your Bidsboxx wallet, and you’ll be able to use them to place bids in live sales events.
                                </p>

                                <p><b>2. Placing a Bid:</b></p>

                                <p>Once you’ve added funds to your wallet, follow these steps to place a bid:</p>

                                <p><b>● Browse Live Sales:</b>  Explore the ongoing live sales events to find products you’re interested in. You can see the products being showcased and reviewed in real-time.
                                </p><p><b>● Review Product Information:</b>  Check the details and features of each product as presented by the seller. This information will help you make an informed decision.
                                </p><p><b>● Place Your Bid:</b>  When you find a product you like, you can place a bid directly. Ensure that the bid amount is within your budget and that you have enough funds in your wallet to cover the bid.
                                </p><p><b>● Check Your Balance:</b>  If you don’t have sufficient balance, you won’t be able to place a bid. If needed, you can add more funds to your wallet and try bidding again.
                                </p>
                </div>
        </div>
)
}

export default AboutUs
import React from 'react';
import QrSection from './QrSection';
import bagimage from '../../src/assets/landing/bag_image.png';
import savingAnimation from '../../src/assets/landing/savingAnimation.json';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: savingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function WhatProductSection() {
  return (
    <div className='_what_product'>
      <div className='_left'>
        <div className='_img'>
          <div className='sving_animation'>
            <Lottie options={defaultOptions}
              height={150}
              width={150}
            />
          </div>
          <img src={bagimage} alt="image" className='img-fluid' />
        </div>
      </div>
      <div className='_right'>
        <div className='_text'>
          <h1 className='_light_heading'>Find Products </h1>
          <h1 className='_dark_heading'>YOU WANT</h1>
          <p>Engage in the excitement of real-time bidding at our live auctions. Bid, win, and feel the excitement unfold in real-time!</p>
          <QrSection color="black" heading="DOWNLOAD APP NOW" />
        </div>
      </div>
    </div>
  )
}

export default WhatProductSection
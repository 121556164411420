
import React from 'react';
import { DeleteIcon } from '../../Svg';
import { BankLogoImage } from '../../CommonComponent/ImageConstants';
function AccountCard(props) {
  return (
    <div className={`_card ${props?.className}`}>
        <div className='_head'>
           <div className='_logo'>
              <img src={BankLogoImage} className="me-2" alt="logo"/>
              <span>HDFC BANK</span>
           </div>
           <div className='_right'>
            {props.default !== 'no' ?
             <button  className="btn btn-danger btn-sm me-3">Default</button>:''
            }
             <span><DeleteIcon /></span>
           </div>
        </div>
        <div className='_body'>
            <div className='top'>
                <div className='_left my-2'> <p className='_light'>Account Number</p> <p>52XXXXXXXX4278</p></div>
                <div className='_right my-2'> <p className='_light'>IFSC Code</p> <p>HDFC0000435</p></div>
            </div>
            <div className='_bottom'>
            <p className='_light'>Branch Name</p><p>NOIDA SECTOR SIXTY THREE</p>
            </div>
       
       
       
        </div>
    </div>
  )
}

export default AccountCard
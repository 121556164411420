import React, { useReducer, useState } from 'react'
import Input from '../../CommonComponent/Input';
import Lottie from 'react-lottie';
import Animation from '../../Utils/Animation.json'
import Button from '../../CommonComponent/Button';
import {  useNavigate } from 'react-router';
import { apiService } from '../../Services/ApiServices';
import { connect } from "react-redux";
import InlineError from "../../CommonComponent/inlineError";
import { Link } from 'react-router-dom';
import Logo from '../../CommonComponent/Logo';

function ResetPassword() {

    const [countryCode, setCountryCode] = useState(91);
    const [mobile, setMobile] = useState("");
    const navigate = useNavigate()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Animation,
        renderer: 'svg'
    }

    const [error, setError] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            phone: '', countryCode: '', errorMsg: ''
        }
    );
    const handleValidatePhone = () => {
        let validate = true;
        setError({});
        if ((mobile == undefined) || (mobile == '')) {
            setError({ mobile: 'Phone is require.' });
            validate = false
        }
        if ((mobile.length < 10) || (mobile.length >= 11)) {
            setError({ mobile: 'Phone must contain only 10 characters' });
            validate = false
        }
        return validate;
    }
    const sendOtp = () => {
        
        try {
            if (handleValidatePhone() == true) {
                let req = {
                    phone_number: mobile,
                    country_code: "+" + countryCode,
                    is_for_forgot_password: 1
                }
                apiService.sendOtp(req).then(result => {
                    if (result.data?.status_code == 0) {
                        setError({ mobile: result?.data?.error.message.error });
                    } else {
                        localStorage.setItem('phone_number', mobile);
                        localStorage.setItem('country_code', countryCode);
                        navigate('/verify_otp')
                    }
                })
            }
        } catch (e) {
            console.error('e', e);
        }
    }

    const handleInput = (event) => {
        const mobile=event.target.value
        const sanitizedValue = event.target.value.replace(/\D/g, ''); // Remove non-digits
        if ((mobile.length < 10) || (mobile.length >= 11)) {
            setError({ mobile: 'Phone must contain only 10 characters' });
        }
        else{setError({mobile:''})}
        setMobile(sanitizedValue);
    };

    return (
        <div>

            <div className="auth_main_section">
                <div className='left_side'>
                    <div className='logo_section'>
                        <Link to="/"> <Logo /></Link>

                    </div>
                    <div>
                        <div>
                            <Lottie
                                options={defaultOptions}
                                height={160}
                                width={160}
                            />
                        </div>
                        <img src='assets/sign-in-girl.svg' alt='women' className='sign_in_girl' />
                    </div>

                </div>
                <div className='right_side'>
                    <div className='reset_section'>
                        <div className='mob_signup_logo'>
                            <img src='assets/sinupMobLogo.svg' />
                        </div>
                        <h1 className='reset_text avenir_black'>Reset Password</h1>
                        <p className='welcome_txt'><span className='avenir_medium'> Please enter your registered mobile number</span></p>
                        <div className={error.errorMsg ? "mb-0 pb-0 wrap_option  position_relative" : "wrap_option  position_relative mb-0"}>
                            <span className='county_code'>🇮🇳 +91</span>
                            <Input
                                className="number_inpt reset_number_inpt "
                                maxLength={10}
                                type="text"
                                placeholder="Enter your mobile number"
                                value={mobile}
                                onChange={handleInput}
                            />
                        </div>
                        <div className='left_align_error'>
                            <InlineError errorMessage={error.mobile} />
                        </div>
                        <div className='mt-5 pt-5'>
                            {
                                (mobile.length == 10)?
                                <Button name={"Send OTP"} onClick={() => sendOtp()} />
                                :
                                <Button name={"Send OTP"} disabled="true" />
                            }
                           
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

// export default ResetPassword

function mapStateToProps(state) {
    const { props } = state;
    return {
        props
    }
}

export default connect(mapStateToProps)(ResetPassword);


import React, { useReducer, useState } from 'react';
import { FaAngleRight, FaChevronLeft } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import { Order } from '../../Svg';
import order from '../../assets/order.png';
import Button from '../../CommonComponent/Button';
import { Modal } from 'react-bootstrap';
import Input from '../../CommonComponent/Input';
import OrderStatus from './OrderStatus';
import moment from 'moment';
import { apiService } from '../../Services/ApiServices';
import { OrderStatusSkeleton } from '../../CommonComponent/Skeleton';
import { useNavigate } from 'react-router';
import { notify } from '../../CommonComponent/Toaster';

function OrdersrderCard(props) {
    const [show, setShow] = useState(false);
    const [orderStatusModal, setOrderStatusModal] = useState(false)
    const token = localStorage.getItem('token');
    const [inputLoader, setInputLoader] = useState(false);
    const navigate = useNavigate();
    const [userInput, setUserInput] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            isLoader: true,
            list: {}
        }
    );

    const handleClose = (text) => {
        if (text == 'cancel-order') {
            setShow(false);
        }
        else if (text == 'order-status') {
            setOrderStatusModal(false);
        }
    }

    const handleShow = (text, id) => {
        if (text == 'cancel-order') {
            setShow(true);
        }
        else if (text == 'order-status') {
            setOrderStatusModal(true)
            orderDetail(id)
        }
    }

    const orderDetail = (id) => {
        let req = { id: id };
        setInputLoader(true)
        setUserInput({ isLoader: true })
        apiService.orderDetail(req, token).then(res => {
            if (res?.data?.status_code == 1) {
                setInputLoader(false);
                setUserInput({ list: res?.data?.response_data.order_detail, isLoader: false })
            }
            else {
                setInputLoader(false);
            }
        })
    }

    const getLabelDetail = (orderNo) => {
        let req = { order_no: orderNo };
        setInputLoader(true)
        setUserInput({ isLoader: true })
        apiService.getLabelDetail(req, token).then(res => {
            if (res?.data?.status_code == 1) {
                props?.orderList()
            }
            else {
                notify("err", res?.data?.error?.message?.error);
            }
        })
    }

    const handlePage = (id) => {
        orderDetail(id);
        setTimeout(() => {
            navigate(`/order-detail/${id}`, { state: { list: userInput.list } })
        }, 500)
    }

    const getLabelDownload = (label_image) => {
        if (label_image) {
            const link = document.createElement('a');
            link.href = label_image;
            // Generate a timestamp
            const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
            // Set the download attribute with a custom filename
            link.download = `bidsboxx_${timestamp}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            notify("err", "Label image not available");
        }
    };

    return (
        <>
            {props.data.map(item => {
                const { order_status, is_order_return, return_order_status, order_date, image, description, id, size, product_name, label_image } = item
                return (
                    <div className='_order'>
                        <div className='_head'>
                            <div className='icon'><Order /></div>
                            <div className='text'>
                                <div className=''>
                                    {is_order_return ?
                                        <h5 className='mb-0'>Return {return_order_status}</h5>
                                        : <h5 className='mb-0'>{order_status}</h5>
                                    }
                                    <p className='mb-0'>On {moment(order_date).format('ddd, DD MMM')}</p>
                                </div>
                                {order_status === "new order" &&
                                    <div className='_btn'>
                                        <Button name="Cancel Order" className="lower_case" onClick={() => handleShow('order-status')} />
                                    </div>
                                }
                                <span
                                    className="_get_label d-flex"
                                    onClick={() => label_image ? getLabelDownload(label_image) : getLabelDetail(id)}
                                >
                                    {label_image ? "Download Label" : "Get Label"}
                                </span>
                            </div>
                        </div>
                        <div className='_body'>
                            <div className='_image'>
                                <img src={image ? image : order} alt="order" />
                            </div>
                            <div className='text'>
                                <h6>{product_name}</h6>
                                <p>{description}</p>
                            </div>
                            <span className='_right_arrow_icon' onClick={() => handlePage(id)}><FaAngleRight /></span>
                        </div>
                    </div>
                )
            })}
            <Modal show={show} onHide={() => handleClose('cancel-order')} centered className='_cancel_order_modal' size="md" >
                <Modal.Body>
                    <div className='p-5 _cancel_order'>
                        <div className='close_icon' onClick={() => handleClose('cancel-order')}><span><IoCloseCircle /></span></div>
                        <h4 className='mb-5'>Reason for Cancel Order</h4>
                        <Input type="text" />

                        <div className='text-center mt-5'>
                            <Button name="Cancel Order" className="btn  lower_case common_button " />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal animation={false} backdropClassName='___hide_modal_mobile '
                show={orderStatusModal} onHide={() => handleClose('order-status')}
                centered
                className='___hide_mobile_modals _order_delivered_popup' size="lg">
                <Modal.Body>
                    <div className='p-4 _order_dlivered'>
                        <div className='close_icon' onClick={() => handleClose('order-status')}><span><IoCloseCircle /></span></div>
                        <h4 className='mb-4'><span className='back_icon show_mobile' onClick={() => handleClose('order-status')}><FaChevronLeft /></span>Order Delivered</h4>
                        {userInput.isLoader ? <OrderStatusSkeleton /> :
                            <OrderStatus list={userInput.list} />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrdersrderCard
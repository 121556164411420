import React, { useRef } from 'react';
import { LiveSectionSkeleton } from '../../CommonComponent/Skeleton';

function LiveSection(props) {
console.log("LiveSectionLiveSectionLiveSectionLiveSectionLiveSection",props.LiveSectionData[0].length)
    return (
        <>
            {
                props.LiveSectionData[0]?.length >= 1 ?
                    <>
                        {
                            props.LiveSectionData[0]?.map((item) => {
                                return (
                                    <div className='_live_card'>
                                     {   console.log("imgggggggggggggggggggggggg",item)}
                                        <img src={`${item?.img}`} className="_baner_img" alt="banner" />
                                        <div className='_bottom'>
                                            <div className='_left'>
                                                <p>{item?.bottom?.p}</p>
                                                <h3>{item?.bottom?.h}</h3>
                                            </div>
                                            <div className='_right'>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                    : <LiveSectionSkeleton />
            }
        </>
    )
}

export default LiveSection
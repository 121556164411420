import React from 'react'

function BaseLoader() {
    return (
        <>
            <div className="loader_body">
                <div className="row cf">
                    <div className="three col">
                        <div className="loader" id="loader-1"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BaseLoader
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../src/assets/landing/logo.svg';
import social1 from '../../src/assets/landing/social1.svg';
import social2 from '../../src/assets/landing/social2.svg';
import social3 from '../../src/assets/landing/social3.svg';
import social4 from '../../src/assets/landing/social4.svg';

function LandingFooter() {
  return (
    <div className='_landing_footer'>
      <div className="footer_parent">
        <div className="footer_left_parent ">
          <Link to='/'>
            <img src={logo} style={{ height: "62px" }} />
          </Link>
        </div>
        <div className="footer_right_parent">
          {footerMenu?.map((item => {
            return (
              <div className="footer-menu">
                <h6>{item.heading}</h6>
                <ul>
                  {item.menu.map(menus => {
                    return (
                      <li> <Link to={menus?.link}>{menus?.name}</Link> </li>
                    )
                  })}
                </ul>
              </div>
            )
          }))}
          {footerMenu1?.map((item => {
            return (

              <div className="footer-menu">
                <h6>{item.heading}</h6>
                <ul>
                  {item.menu.map(menus => {
                    return (
                      <li > <Link className='d-flex'target='_blank' to={menus.link}><img src={menus.icon} className='me-2' alt="image" />{menus.name}</Link> </li>
                    )
                  })}
                </ul>
              </div>
            )
          }))}
        </div>
      </div>
      <div className='bottom'>
        <p>© Bidsboxx.com. All rights reserved. &nbsp;| <Link to="/terms-conditions" >Terms</Link> | <Link to="/privacy-policy">Privacy</Link> </p>
      </div>
    </div>
  )
}

export default LandingFooter
const footerMenu = [
  {
    heading: 'Bidsboxx',
    icon: false,
    menu: [
      {
        name: 'Blog',
        link: '/'
      },
      {
        name: 'Download',
        link: 'https://bidsboxx-dev.s3.amazonaws.com/bidsboxx.apk'
      },
      {
        name: 'About Us',
        link: '/about-us'
      },
      {
        name: 'FAQ',
        link: '/'
      },
      {
        name: 'Bidsboxx Affiliates',
        link: '/'
      }
    ]
  },
  {
    heading: 'Get Help',
    icon: false,
    menu: [
      // {
      //   name:'Order Status',
      //   link:'/'
      // },
      {
        name: 'Shipping & Delivery',
        link: '/shipping-delivery'
      },
      {
        name: 'Returns',
        link: '/returns-policy'
      },
      // {
      //   name:'Payment Options',
      //   link:'/'
      // },
      // {
      //   name:'Contact Us',
      //   link:'/'
      // },
    ]
  },
]
const footerMenu1 = [
  {
    heading: 'Social Links',
    menu: [
      {
        name: 'Instagram',
        icon: social1,
        link:"https://www.instagram.com/sellonbidsboxx?igsh=ZjB6eDRscjU3OWhu"
      },
      {
        name: 'Facebook',
        icon: social2,
        link:"https://www.facebook.com/share/2dKC4x3psfyLLojJ/?mibextid=qi2Omg"
      },
      // {
      //   name: 'Linkedin',
      //   icon: social3
      // },
      // {
      //   name: 'Twitter',
      //   icon: social4
      // },
    ]
  }
]
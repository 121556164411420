import io from 'socket.io-client';
import siteSetting from "../Component/Config/env/Index";
const accessToken = localStorage.getItem('token');
const webSocketUrl = siteSetting.api.SOCKET_PATH;

let socket;

if (accessToken) {
    socket = io(webSocketUrl, {
        query: { token: accessToken },
        transports: ['websocket'],
        auth: {
            token: accessToken
        }
    });

    socket.on('connect', (data) => {
        console.log('Connected to socket.io server', data);
    });

    socket.on('error', (data) => {
        console.log('error to socket.io server', data);
    });

}

// socket.on('disconnect', () => {
//     console.log('Disconnected from socket.io server');
// });


// export function useSocketEvent(event, handler) {
//     useEffect(() => {
//         if (socket) {
//             socket.on(event, handler);
//             return () => {
//                 socket.off(event, handler);
//             };
//         }
//     }, [event, handler]);
// }

export default socket
import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

export const AddproductImg = () => (
    <div className='auction_store_img justify-content-center mb-0' style={{ textAlign: "center" }}>
        <Skeleton width={'162px'} height={'162px'} className='selected_img' />
    </div>
)

export const AuctionSkeleton = () => (
    <div className='auction_box mt-4'>
        <div className='user_img'>
            <Skeleton width={'162px'} height={'162px'} borderRadius={10} />
        </div>
        <div className='auction_width'>
            <div className='auction_width display_text mb-2'>
                <span className='size_text avenir_heavy'><Skeleton width={'250px'} height={'20px'} /></span>
            </div>
            <div className='display_text'>
                <div >
                    <div className='item_txt '>
                        <span> <Skeleton width={'120px'} height={'20px'} /> </span>
                    </div>
                    <div className='item_txt'>
                        <span> <Skeleton width={'120px'} height={'20px'} /> </span>
                    </div>

                    <span className='apparel_txt avenir_heavy' ><Skeleton width={'120px'} height={'20px'} /> </span>
                </div>

                <div>
                    <div className='item_txt'>
                        <Skeleton width={'120px'} height={'20px'} />
                    </div>
                    <div className='item_txt'>
                        <Skeleton width={'120px'} height={'20px'} />
                    </div>
                    <div className='item_txt'>
                        <Skeleton width={'120px'} height={'20px'} />
                    </div>
                </div>
            </div>
            <div className='display_text btn_line mt-4'>
                <div className="mt-3 d-flex">
                    <Skeleton width={'100px'} height={'40px'} borderRadius={10} />
                    <Skeleton width={'100px'} height={'40px'} borderRadius={10} />
                </div>
            </div>
        </div>
    </div>
)
export const AddProductListSkeleton = () => (
    <div className={`product_box `} >
        <div className={`_image`}>
            {/* <img id={item.id} src={item.image}  alt="product" /> */}
            <Skeleton width={'100%'} height={'100%'} />
        </div>
        <div className='_text'>
            <h6 className='mb-1'><Skeleton /></h6>
            <p className='_size'> <Skeleton /></p>
            <p className='_price '><Skeleton /></p>
        </div>
    </div>

)
export const ProductSkeleton = () => (
    <div className="product_container">
        <div className={`product_box `} >
            <div className={`_image`}>
                {/* <img id={item.id} src={item.image}  alt="product" /> */}
                <Skeleton width={'100%'} height={'100%'} />
            </div>
            <div className='_text mt-3'>
                <h6 className='mb-1'><Skeleton width={'150px'} height={'10px'} /></h6>
                <p className='_size'> <Skeleton width={'150px'} height={'10px'} /></p>
                <p className='_price '><Skeleton width={'150px'} height={'10px'} /></p>
            </div>
        </div>
    </div>
)

export const ProductListSkeleton = () => (
    <div className='list_data'>
        <div className='list_item avenir_medium'>
            <ul >
                <li className=' ' ><Skeleton width={'50px'} height={'30px'} /></li>
                <li className=' '><Skeleton width={'50px'} height={'30px'} /></li>
                <li className="d-flex">
                    <span className="nxt_btn me-2" ><Skeleton width={'50px'} height={'30px'} /></span>
                    <span className="nxt_btn" ><Skeleton width={'50px'} height={'30px'} /></span>
                </li>
                <li>
                    <Skeleton width={'50px'} height={'30px'} />
                </li>
            </ul>
        </div>
    </div>
)

export const OrderListSkeleton = () => (
    <div className='_order'>
        <div className='_head'>
            <div className='icon'><Skeleton width={'50px'} height={'50px'} circle={true} /></div>
            <div className='text'>
                <div className=''>
                    <h5 className='mb-0'><Skeleton width={'100px'} height={'20px'} /></h5>
                    <p className='mb-0'><Skeleton width={'100px'} height={'20px'} /></p>
                </div>
            </div>
        </div>
        <div className='_body'>
            <div className='_image'>
                <Skeleton width={'104px'} height={'98'} borderRadius={10} />
            </div>
            <div className='text'>
                <h6><Skeleton width={'100px'} height={'20px'} /></h6>
                <p><Skeleton width={'100px'} height={'20px'} /></p>
            </div>
        </div>
    </div>
)

export const OrderStatusSkeleton = () => (
    <div className="_order_dlivered_modal">
        <div className="_order_dlivered">
            <div className="_order_details">
                <div className="_order">
                    <h5 className='mt-3'><Skeleton width={'100px'} height={'20px'} /></h5>
                    <h5 className='mt-3'><Skeleton width={'100px'} height={'20px'} /></h5>
                    <div className="_card">
                        <div className="_img"><Skeleton width={'112px'} height={'112px'} /></div>
                        <div className="_details">
                            <h5>Order Amount : <span><Skeleton width={'100px'} height={'20px'} /></span></h5>
                            <h5>Order Number : <span><Skeleton width={'100px'} height={'20px'} /></span></h5>
                            <h5>Tracking ID : <span><Skeleton width={'100px'} height={'20px'} /></span></h5>
                        </div>
                    </div>
                    <div className='_address'>
                        <h6 className='mt-4'>Delivery Address</h6>
                        <p><Skeleton width={'200px'} height={'20px'} /></p>
                        <p><Skeleton width={'200px'} height={'20px'} /></p>
                    </div>
                </div>
                <div className='_order_status'>
                    <div className="_status">
                        <div className="delivery-status">
                            <div className="step">
                                <div className="circle success">
                                    <Skeleton width={'30px'} height={'30px'} circle={true} />
                                </div>
                                <div className="content"><Skeleton width={'100px'} height={'20px'} /></div>
                            </div>
                            <div className="step">
                                <div className="circle success">
                                    <Skeleton width={'30px'} height={'30px'} circle={true} />
                                </div>
                                <div className="content"><Skeleton width={'100px'} height={'20px'} /></div>
                            </div>
                            <div className="step">
                                <div className="circle success">
                                    <Skeleton width={'30px'} height={'30px'} circle={true} />
                                </div>
                                <div className="content"><Skeleton width={'100px'} height={'20px'} /></div>
                            </div>
                            <div className="step">
                                <div className="circle success">
                                    <Skeleton width={'30px'} height={'30px'} circle={true} />
                                </div>
                                <div className="content"><Skeleton width={'100px'} height={'20px'} /></div>
                            </div>
                            <div className="step">
                                <div className="circle success">
                                    <Skeleton width={'30px'} height={'30px'} circle={true} />
                                </div>
                                <div className="content"><Skeleton width={'100px'} height={'20px'} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></div></div>
)

export const VideoSkeleton = () => (
    <p><Skeleton width={'100px'} height={'20px'} /></p>

)

export const GiveawaySkeleton = () => (
    <div className='description'>
        <h6>Product on  : <span><Skeleton width={'100px'} height={'20px'} /></span></h6>
        <p><Skeleton width={'300px'} height={'20px'} /></p>
    </div>
)

export const LiveSectionSkeleton = () => (
    <Skeleton width={'546px'} height={'317px'} />
)

import React, { useState, useEffect } from 'react';
import img from '../assets/user-profile.svg';
import socket from '../Socket/io';
import { FaUsers } from 'react-icons/fa';

function Viewer() {
  const [viewerList, setViewerList] = useState([]);
  const [viewer, setviewer] = useState(false);
  useEffect(() => {
    try {
      socket.on('user_joined', (data) => {
        setViewerList(data);
      });
    } catch (e) {
      console.error('e', e);
    }

  }, []);
  const showViewer = () => {
    setviewer(!viewer)
  }

  return (
    <>
      <div className=' _viewer_togle_btn' onClick={showViewer} style={{ cursor: 'pointer' }}>
        <span className='show_only_mobile _mobile_online_btn'>
          &nbsp;{viewerList?.viwer_count}
          <span className={`_dot ${viewerList.length == 0 ? '_red' : ''}`}></span>
          <span className='_icon me-1 ms-1'><FaUsers /></span>
        </span>
        <div className='_online'>
          <span className='show_only_desktop'>
            <span className={`_dot ${viewerList.length == 0 ? '_red' : ''}`}></span>
            <div className='_heading ' onClick={showViewer}>
              <h4>Viewers</h4>
              <div className='_online'><span className={`_dot ${viewerList.length == 0 ? '_red' : ''}`}></span>&nbsp;{viewerList?.viwer_count}</div>
            </div>
          </span>
        </div>
      </div>
      {viewer ?
        <div className={`_viewer_section ${viewer ? 'show_mobile' : 'show_mobile'}`} onClick={showViewer}>
          <div className='_heading'>
            <h4>Viewers</h4>
            <div className='_online'><span className={`_dot ${viewerList.length == 0 ? '_red' : ''}`}></span>&nbsp;{viewerList?.viwer_count}</div>
          </div>
          <div className='_viewer_list'>
            {viewerList?.viwer_list?.map(item =>
            (
              <div className='_viewer'>
                <div className='img'>
                  <img src={item?.image ? item?.image : img} />
                </div>
                <p>{item?.name}</p>
              </div>
            )
            )}
          </div>
        </div> : ''
      }
    </>
  )
}

export default Viewer;
